import React from "react";
import { styled } from "@mui/material/styles";
import SelectTestHeader from "../../testrun/select-tests/SelectTestHeader";
import SuiteTreeView from "src/app/main/testcase/sidebar/SuiteTreeView";
import FuseLoading from "@fuse/core/FuseLoading";
import FusePageSimple from "@fuse/core/FusePageSimple";
import { useSelector } from "react-redux";
import { selectTestRunSuites } from "../../store/testRunProcessSlice";
import { Outlet, useParams } from "react-router-dom";
import { Typography } from "@mui/material";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    overFlow: "auto",
  },
}));

const TestRunResults = (props) => {
  const { loading } = props;
  const { projectKey, testRunKey } = useParams();
  return (
    <Root
      header={undefined}
      content={<Outlet />}
      leftSidebarOpen={true}
      leftsidebarwidth={500}
      leftSidebarOnClose={() => {
        setLeftSidebarOpen(false);
      }}
      leftSidebarContent={
        loading ? (
          <FuseLoading />
        ) : (
          <SuiteTreeView
            droppable={false}
            actionButton={false}
            urlPath={`/project/${projectKey}/testruns/${testRunKey}`}
          />
        )
      }
      // rightSidebarContent={<Typography>Lorem Ipsum</Typography>}
      // rightSidebarWidth={500}
      // rightSidebarOpen={true}
    />
  );
};

export default TestRunResults;
