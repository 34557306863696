import _ from '@lodash';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Popover from '@mui/material/Popover';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { Error } from '@mui/icons-material';


function IteratorMenu({ iterator, onChange, selectedLabels, onClick, selectedSplitters = [] }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const { icon, id, labels, name, disabled } = iterator;


    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
        onClick()
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }


    function handleIteratorLabel(labelName, splitterGroup) {
        const mappedArray = { name: splitterGroup.name, labels: [labelName], id: id }
        const existingIndex = selectedLabels.findIndex(item => item.id === id);
        if (existingIndex === -1) {
            selectedLabels = _.xor(selectedLabels, [mappedArray]);
        } else {
            selectedLabels.map((item) => {
                if (item.id == id) {
                    let labels = item.labels;
                    if (labels.includes(labelName)) {
                        const index = item.labels.indexOf(labelName)
                        labels = labels.splice(index, 1);
                    }
                    else {
                        item.labels.push(labelName)
                    }
                    return { ...item, labels }
                }
            })
        }
        selectedLabels = selectedLabels.filter(item => item.labels.length > 0);
        onChange(selectedLabels, splitterGroup)
    }

    return (
        <div>
            <IconButton disabled={disabled} className="w-32 h-32 mx-4 p-0" onClick={handleMenuClick} size="large">
                <FuseSvgIcon color={disabled ? 'disabled' : "action"} size={20}>{icon ? `${icon}` : 'material-solid:label'}</FuseSvgIcon>
            </IconButton>

            <Popover
                hideBackdrop
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className="pointer-events-none max-h-200"
                classes={{
                    paper: 'pointer-events-auto py-8 prevent-add-close',
                }}
            >
                <ClickAwayListener onClickAway={handleMenuClose}>
                    <List className="p-0">
                        {
                            labels.map((label) => (
                                <ListItem key={label} button onClick={() => handleIteratorLabel(label, iterator)}>
                                    <IconButton disabled>
                                        <FuseSvgIcon className="list-item-icon" size={20} color="action">
                                            {_.find(selectedLabels, ['id', id])?.labels.includes(label)
                                                ? 'heroicons-outline:check-circle'
                                                : 'heroicons-outline:minus-circle'}
                                        </FuseSvgIcon>
                                    </IconButton>

                                    <ListItemText className="truncate px-8" primary={label} disableTypography />
                                </ListItem>
                            ))
                        }

                    </List>
                </ClickAwayListener>
            </Popover>
        </div>
    );
}

export default IteratorMenu;
