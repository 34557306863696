import React from 'react'
import PropTypes from 'prop-types'
import FuseSvgIcon from '@fuse/core/FuseSvgIcon'
import { Tooltip } from '@mui/material';

function PriorityIcon({ icon, color = "gray", title = "" }) {
    const iconColor = () => {

        return {
            className: `text-${color}-800`,
        };
    };
    return (
        <Tooltip title={title}>
            <FuseSvgIcon className={`list-item-icon mr-10 ${iconColor().className}`} size={20}>
                {icon}
            </FuseSvgIcon>
        </Tooltip>

    )
}


export default PriorityIcon
