import React, { useState } from "react";
import { Autocomplete, Card, TextField, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectTestRunStatus } from "../store/testRunSlice";
import WYSIWYGEditor from "app/shared-components/WYSIWYGEditor";
import _ from "@lodash";

const BasicInformation = () => {
  const testRunStatus = useSelector(selectTestRunStatus);
  const methods = useFormContext();

  const {
    formState: { errors },
    control,
    setValue,
    getValues,
  } = methods;

  useEffect(() => {
    const testrun_status_id = getValues("testrun_status_id");
    let runStates = _.find(testRunStatus, ["name", "New"]);
    if (testrun_status_id) {
      runStates = _.find(testRunStatus, ["id", testrun_status_id]);
    }
    setValue("testrun_status", runStates);
  }, [testRunStatus]);

  return (
    <Card component={motion.div}>
      <div className="px-32 py-24">
        <div className="flex w-full mb-20 justify-between">
          <Typography className="text-2xl font-semibold leading-tight">
            Basic Information
          </Typography>
        </div>

        <form noValidate className="flex flex-col gap-20 w-full">
          <Controller
            name="name"
            className=" mb-10"
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                sx={{ width: 600 }}
                error={!!errors.name}
                size="small"
                value={value}
                required
                helperText={errors?.name?.message}
                label="Test Run Name"
              />
            )}
          />
          <Controller
            name="testrun_status"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                size="small"
                options={testRunStatus}
                disablePortal
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  onChange(value);
                }}
                getOptionLabel={(item) => (item.name ? item.name : "")}
                value={value}
                sx={{ width: 600 }}
                id="combo-box-demo"
                renderInput={(params) => (
                  <TextField
                    sx={{ width: 600 }}
                    error={!!errors.testrun_status}
                    required
                    helperText={errors?.testrun_status?.message}
                    {...params}
                    label="Test Run State"
                  />
                )}
              />
            )}
          />
          <Controller
            className="mt-8 mb-16"
            render={({ field }) => (
              <WYSIWYGEditor
                className="w-[600px]"
                height="h-288"
                disabled="false"
                defaultValue={getValues("notes") || ""}
                {...field}
              />
            )}
            name="notes"
            control={control}
          />
        </form>
      </div>
    </Card>
  );
};

export default BasicInformation;
