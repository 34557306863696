import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Dialog,
  Button,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  openAssignTestModal,
  selectAssignTestModalState,
} from "../../store/testRunSlice";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import FusePageSimple from "@fuse/core/FusePageSimple";
import SelectTestContent from "./SelectTestContent";
import SuiteTreeView from "src/app/main/testcase/sidebar/SuiteTreeView";
import SelectTestHeader from "./SelectTestHeader";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import { getSuites } from "src/app/main/testcase/store/suitesSlice";
import { useLocalStorage } from "src/app/utils/customHook";
import { useFormContext } from "react-hook-form";
import {
  selectedTestIds,
  updatedSelectedTestIds,
} from "src/app/main/testcase/store/testsSlice";
import FuseLoading from "@fuse/core/FuseLoading";
import SelectTestFilterContent from "./SelectTestFilterContent";
import { setCurrentSuite } from "src/app/main/testcase/store/suiteSlice";
import { clearFilters } from "app/store/filterSlice";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    "& .MuiDialog-paper": {
      "& .FusePageSimple-root": {
        minHeight: 300,
      },
      overflow: "hidden",
    },
    alignItems: "normal",
    // height: 700,
  },
}));

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    overFlow: "auto",
  },
  // "& .FusePageSimple-leftSidebar": {
  //   width: 300,
  // },
}));

const SelectTestDialog = () => {
  const openedDialog = useSelector(selectAssignTestModalState);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const selectedTestCasesIds = useSelector(selectedTestIds);
  const methods = useFormContext();
  const { setValue, getValues } = methods;
  const [selectedProject, setSelectedProject] =
    useLocalStorage("selected_project");
  const [originalCases, setOriginalCases] = useState(getValues("cases") ?? []);

  useEffect(() => {
    dispatch(setCurrentSuite({ id: "all", name: "All Test cases" }));
    dispatch(getSuites({ projectID: selectedProject.id, isTree: false })).then(
      () => setLoading(false)
    );
  }, []);

  const assignTests = () => {
    setValue("cases", selectedTestCasesIds);
    closeDialog();
  };

  const onCancel = () => {
    dispatch(updatedSelectedTestIds(originalCases));
    setValue("cases", originalCases);
    closeDialog();
  };

  const closeDialog = () => {
    dispatch(openAssignTestModal(false));
    dispatch(clearFilters());
  };

  return (
    <CustomDialog
      fullWidth={true}
      maxWidth="xl"
      classes={{
        paper: "w-full my-60 top-0 ",
      }}
      open={openedDialog}
    >
      <AppBar
        className="sticky top-0 z-[999]"
        color="secondary"
        position="static"
        elevation={0}
      >
        <Toolbar className="px-36 flex w-full justify-between">
          <Typography variant="subtitle1" color="inherit">
            Select Test cases
          </Typography>
          <IconButton onClick={closeDialog}>
            <CloseIcon className="text-white" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Root
        header={<SelectTestHeader />}
        content={<SelectTestContent />}
        leftSidebarOpen={true}
        leftsidebarwidth={500}
        leftSidebarOnClose={() => {
          setLeftSidebarOpen(false);
        }}
        leftSidebarContent={
          loading ? (
            <FuseLoading />
          ) : (
            <SuiteTreeView
              droppable={false}
              actionButton={false}
              isLink={false}
            />
          )
        }
        rightSidebarContent={<SelectTestFilterContent />}
        rightSidebarOpen={true}
        rightSidebarWidth={300}
      />
      <div className=" border-t-1 flex flex-wrap px-36 py-20 gap-4 z-[999] bg-gray-50  dark:bg-[#111827]  ">
        <Button onClick={onCancel} className="ml-auto">
          Cancel
        </Button>
        <LoadingButton
          onClick={assignTests}
          type="button"
          color="secondary"
          variant="contained"
        >
          Assign Test Cases
        </LoadingButton>
      </div>
    </CustomDialog>
  );
};

export default SelectTestDialog;
