import { Checkbox } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { addTestCaseId, removeTestCaseId } from "../store/testsSlice";

export default function TestCaseCheckbox({
  testCaseId,
  splitters = [],
  checked = false,
}) {
  const dispatch = useDispatch();

  const handleChange = (event) => {
    if (event.target.checked) {
      dispatch(addTestCaseId({ testCaseId, splitters }));
    } else {
      dispatch(removeTestCaseId(testCaseId));
    }
  };

  return (
    <Checkbox checked={checked} onChange={handleChange} size="small"></Checkbox>
  );
}
