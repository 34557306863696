
import { Filter } from '@mui/icons-material';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';



const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        queryParams: {},
        urlParams: ""
    },
    reducers: {
        updateFilterParams: (state, action) => {
            state.queryParams = action.payload
        },
        clearFilters: (state, action) => {
            state.queryParams = {};
            state.urlParams = "";
        }
    }
});

// selectUrlParams
export const { updateFilterParams, clearFilters } = filterSlice.actions;
export const selectFilterParams = ({ filter }) => filter.queryParams;
export default filterSlice.reducer;