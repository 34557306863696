
import { Button, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import AiIcon from './AiIcon';

const CustomButton = styled(Button)(({ theme }) => ({
    padding: '0px',
    backgroundPosition: 'center',
    minWidth: "30px",
    width: "10px",// Adjust the width to your needs
    borderRadius: '10px',  // Adjust border radius
    '&:hover': {
        filter: 'brightness(85%)', // Slightly darken on hover
    },
}));

const AIButton = (props) => {

    const { onClick, variant = "default", size = 32 } = props
    return (
        <Tooltip title="Test Cases Assistant">
            <CustomButton onClick={onClick}>
                <AiIcon size={size} variant={variant} />
            </CustomButton>

        </Tooltip>


    )
}

export default AIButton