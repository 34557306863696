

class TamboraUtils {

    static includesKeyValue(array, key, value) {
        for (const obj of array) {
            if (obj[key] === value) {
                return true;
            }
        }
        return false;
    }

    static parseBoolean(value) {

        if (typeof value === 'boolean') {
            // If it's already a boolean, return it
            return value;
        } else if (typeof value === 'string') {
            // If it's a string, check if it's "true" (case insensitive) or "false" and return accordingly
            return value.toLowerCase() === 'true';
        } else {
            // Otherwise, return false
            return false;
        }
    }


    static parseJsonToHtml(data) {
        let htmlOutput = '';
        for (const [key, value] of Object.entries(data)) {
            if (key !== "scenario") { // Skip the scenario key if needed
                htmlOutput += `<p>${key.charAt(0).toUpperCase() + key.slice(1)} ${value}</p>`;
            }
        }
        return htmlOutput;
    }

    static parseTextToHtml(data) {
        const lines = data.split('.')
        let htmlOutput = '';
        for (const line of lines) {
            htmlOutput += `<p>${line}</p>`
        }
        return htmlOutput;

    }



}

export default TamboraUtils;