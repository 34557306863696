import React from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectIntegrations } from "../store/jiraSlice";
import { JiraCard } from "../widgets/JiraCard";

const ConnectionsTab = () => {
  const integrations = useSelector(selectIntegrations);

  return (
    <motion.div className="items-center flex flex-wrap w-full min-w-0">
      {integrations.map((item) => (
        <JiraCard integration={item} key={item.id}></JiraCard>
      ))}
    </motion.div>
  );
};

export default ConnectionsTab;
