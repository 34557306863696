import React from "react";
import { motion } from "framer-motion";
import MappingProjectTable from "./MappingProjectTable";
import { Card, Typography } from "@mui/material";

const MappedProjectsCard = (props) => {
  return (
    <Card
      className="  px-32 min-h-[200px]"
      component={motion.div}
      variants={props.item}
    >
      <Typography className="text-2xl pt-20 font-semibold mb-20 leading-tight">
        Mapped Projects
      </Typography>
      {/* <div className="mt-20"> */}
      <MappingProjectTable />
      {/* </div> */}
    </Card>
  );
};

export default MappedProjectsCard;
