import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwtService from 'src/app/auth/services/jwtService';

export const getTestRuns = createAsyncThunk(
    'testRunsApp/getTestRuns',
    async ({ projectKey, pagination }, { dispatch }) => {
        const response = await jwtService.GET(`/project/${projectKey}/testrun`, dispatch, pagination)
        return response;
    }
)
const testRunsSlice = createSlice({
    name: 'testRunsApp',
    initialState: {
        testRuns: [],
        runPagination: {}
    },
    reducers: {
        refreshTestRunList: (state, action) => {
            const index = state.testRuns.findIndex(obj => obj.id == action.payload.id);
            if (index !== -1) {
                state.testRuns[index] = action.payload;
            } else
                state.testRuns.unshift(action.payload)
        },
    },
    extraReducers: {
        [getTestRuns.fulfilled]: (state, action) => {
            state.testRuns = action.payload.result
            state.runPagination = action.payload.paginate
        }
    },
});


export const selectTestRuns = ({ testRunApp }) => testRunApp.testRunsSlice.testRuns;
export const selectRunPagination = ({ testRunApp }) => testRunApp.testRunsSlice.runPagination;
export const { refreshTestRunList

} = testRunsSlice.actions;



export default testRunsSlice.reducer;