
class ColorConverter {

        hexColors = {
                'caribbean-green': '#0bd8a2',
                'dark-green': '#00343d',
                'opaque-green': '#26666b',
                'grey': '#08d8a1',
                'gray': '#9E9E9E',
                'amber': '#FFC107',
                'orange': '#FF9800',
                'deep-orange': '#FF5722',
                'blue': '#2196F3',
                'red': '#F44336',
                'green': '#4CAF50',
                'yellow': '#FFEB3B',
                'teal': '#009688',
                'deep-purple': '#673AB7',
                'purple': '#9C27B0',
                'blue-gray': '#607D8B'
        }


        getHexColors = (letterColors) => {
                const hexList = letterColors.map((color) => {
                        if (color == null) return '#9E9E9E';
                        return this.hexColors[color.toLowerCase()];

                });
                return hexList;
        }

}

const instance = new ColorConverter();
export default instance;



