import Paper from '@mui/material/Paper';
import { lighten, useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { memo, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import colorConverter from '../../../../../utils/colorConverter'


function TestCaseStatusWidget() {
    const theme = useTheme();
    const [awaitRender, setAwaitRender] = useState(true);
    const [tabValue, setTabValue] = useState(0);
    const { testCasesMetrics } = useSelector(status => status.dashboardApp.dashboard);
    const { series = [], labels = [], colors = [] } = testCasesMetrics ? testCasesMetrics : [];
    const passedIndex = labels.indexOf('Passed');
    const failedIndex = labels.indexOf('Failed');

    const hexColors = colorConverter.getHexColors(colors);
    const chartOptions = {
        chart: {
            animations: {
                speed: 400,
                animateGradually: {
                    enabled: false,
                },
            },
            fontFamily: 'inherit',
            foreColor: 'inherit',
            height: '100%',
            type: 'pie',
            // sparkline: {
            //     enabled: true,
            // },
        },
        colors: hexColors,
        labels,
        plotOptions: {
            pie: {
                customScale: 0.9,
                expandOnClick: true,
                PeriodicWave: {
                    size: '70%',
                },
            },
        },
        stroke: {
            colors: [theme.palette.background.paper],
        },
        series: series,
        states: {
            hover: {
                filter: {
                    type: 'none',
                },
            },
            active: {
                filter: {
                    type: 'none',
                },
            },
        },
        tooltip: {
            enabled: true,
            fillSeriesColor: false,
            theme: 'dark',
            custom: ({ seriesIndex, w }) =>
                `<div class="flex items-center h-32 min-h-32 max-h-23 px-12">
                <div class="w-12 h-12 rounded-full" style="background-color: ${w.config.colors[seriesIndex]};"></div>
                <div class="ml-8 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
                <div class="ml-8 text-md font-bold leading-none">${w.config.series[seriesIndex]}</div>
            </div>`,
        },
    };


    useEffect(() => {
        setAwaitRender(false);
    }, []);

    if (awaitRender) {
        return null;
    }

    return (
        <Paper className="flex flex-col flex-auto p-24 shadow rounded-2xl overflow-hidden">
            <div className="flex flex-col sm:flex-row items-start justify-between">
                <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
                    Test Summary
                </Typography>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 grid-flow-row gap-24 w-full mt-32 sm:mt-16">
                <div className="flex flex-col flex-auto">
                    <Typography className="font-medium" color="text.secondary">
                        Percentage
                    </Typography>
                    <div className="flex flex-col flex-auto">
                        <ReactApexChart
                            className="flex flex-auto items-center justify-center w-full h-full"
                            options={chartOptions}
                            series={chartOptions.series}
                            type={chartOptions.chart.type}
                            height={chartOptions.chart.height}
                        />
                    </div>
                    {/* <div >
                        <div className="-my-12 flex-auto  grid grid-cols-4 gap-y-16 mt-10">
                            {percentages.map((dataset, i) => (
                                <div key={i}>
                                    <div className="flex  items-center">
                                        <Box
                                            className="flex-0 w-8 h-8 rounded-full"
                                            sx={{ backgroundColor: chartOptions.colors[i] }}
                                        />
                                        <Typography className="ml-12 truncate">{labels[i]}</Typography>
                                        <Typography className="text-right ml-20" color="text.secondary">
                                            {dataset}%
                                        </Typography>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div> */}
                </div>
                <div className="flex flex-col">
                    <Typography className="font-medium" color="text.secondary">
                        Total by status
                    </Typography>
                    <div className="flex-auto grid grid-cols-4 gap-16 mt-24">
                        <div className="col-span-2 flex flex-col items-center justify-center py-32 px-4 rounded-2xl bg-green-50 text-green-800">
                            <Typography className="text-5xl sm:text-7xl font-semibold leading-none tracking-tight">
                                {series ? series[passedIndex] : 0}
                            </Typography>
                            <Typography className="mt-4 text-sm sm:text-lg font-medium">Passed</Typography>
                        </div>
                        <div className="col-span-2 flex flex-col items-center justify-center py-32 px-4 rounded-2xl bg-red-50 text-red-800">
                            <Typography className="text-5xl sm:text-7xl font-semibold leading-none tracking-tight">
                                {series ? series[failedIndex] : 0}
                            </Typography>
                            <Typography className="mt-4 text-sm sm:text-lg font-medium">Failed</Typography>
                        </div>
                        {
                            series &&
                            series.map((item, index) => {
                                return index != failedIndex && index != passedIndex ?
                                    <Box key={index}
                                        sx={{
                                            backgroundColor: (_theme) =>
                                                _theme.palette.mode === 'light'
                                                    ? lighten(theme.palette.background.default, 0.4)
                                                    : lighten(theme.palette.background.default, 0.02),

                                        }}
                                        className={`col-span-2  text-${colors[index]}-800  dark:text-blue-gray-100 sm:col-span-1 flex flex-col items-center justify-center py-32 px-4 rounded-2xl`}
                                    >
                                        <Typography className="text-5xl font-semibold leading-none tracking-tight">
                                            {series[index]}
                                        </Typography>
                                        <Typography className="mt-4 text-sm font-medium text-center">{labels[index]}</Typography>
                                    </Box>
                                    : undefined
                            }

                            )
                        }

                    </div>
                </div>
            </div>
        </Paper >
    );
}

export default memo(TestCaseStatusWidget);
