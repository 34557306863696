import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { darken, lighten } from "@mui/material/styles";
import TableSortLabel from "@mui/material/TableSortLabel";

const rows = [
  {
    id: "email",
    align: "left",
    disablePadding: false,
    label: "Email",
    sort: true,
  },
  {
    id: "first_name",
    align: "left",
    disablePadding: false,
    label: "Name",
    sort: true,
  },
  {
    id: "job_title",
    align: "left",
    disablePadding: false,
    label: "Job title",
    sort: true,
  },
  {
    id: "role",
    align: "left",
    disablePadding: false,
    label: "Role",
    sort: true,
  },
  {
    id: "is_active",
    align: "left",
    disablePadding: false,
    label: "Active",
    sort: true,
  },
  // {
  //   id: "",
  //   align: "center",
  //   disablePadding: false,
  //   label: "",
  //   sort: false,
  // },
];

function UsersTableHead(props) {
  const { selectedOrderIds } = props;
  //   const numSelected = selectedOrderIds.length;

  const [selectedOrdersMenu, setSelectedOrdersMenu] = useState(null);

  const dispatch = useDispatch();

  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property);
  };

  function openSelectedOrdersMenu(event) {
    setSelectedOrdersMenu(event.currentTarget);
  }

  function closeSelectedOrdersMenu() {
    setSelectedOrdersMenu(null);
  }
    
  return (
    <TableHead> 
      <TableRow className="h-48 sm:h-64 ">
        {rows.map((row) => {
          return (
            <TableCell
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey
                    : lighten(theme.palette.background.paper, 0.02),
              }}
              className="p-4 md:p-16"
              key={row.id}
              align={row.align}
              padding={row.disablePadding ? "none" : "normal"}
              sortDirection={
                props.order.id === row.id ? props.order.direction : false
              }
            >
              {row.sort && (
                <TableSortLabel
                  active={props.order.id === row.id}
                  direction={props.order.direction}
                  onClick={createSortHandler(row.id)}
                  className="font-semibold"
                >
                  {row.label}
                </TableSortLabel>
              )}
            </TableCell>
          );
        }, this)}
      </TableRow>
    </TableHead>
  );
}

export default UsersTableHead;
