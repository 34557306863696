import Typography from "@mui/material/Typography";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { openTestDialog, setTestsLoading } from "../store/testsSlice";
import { useParams } from "react-router-dom";
import { TestCaseDialog } from "./TestCaseDialog";
import { selectCurrentSuite } from "../store/suiteSlice";
import { useSelector } from "react-redux";
import { authRoles } from "src/app/auth";
import useRoleBasedAccess from "src/app/utils/customHook";
import { useState } from "react";
import CopyLink from "./CopyLink";
import FilterDrawer from "app/shared-components/filter-drawer/FilterDrawer";
import { selectSplitters } from "../store/testSlice";
import TestCaseSubHeader from "./TestCaseSubHeader";
import { selectFilterParams, updateFilterParams } from "app/store/filterSlice";
import AppliedFilter from "./AppliedFilter";
import AIChatPanelToggleButton from "app/shared-components/ai-chatpanel/AIChatPanelToggleButton";
import SearchInputText from "app/shared-components/search-input-text/SearchInputText";
import { motion } from "framer-motion";

export const TestCaseHeader = (props) => {
  const routeParams = useParams();
  const { projectId, suiteId } = routeParams;
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const { hasRequiredRole } = useRoleBasedAccess(authRoles.external_tester, []);
  const splitters = useSelector(selectSplitters);
  const filterParams = useSelector(selectFilterParams);
  const suite = useSelector(selectCurrentSuite);
  const handleOnChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value == "" && "search" in filterParams) {
      let filters = { ...filterParams };
      delete filters["search"];
      dispatch(setTestsLoading(true));
      dispatch(updateFilterParams(filters));
    }
  };

  const handleKeyPress = (event) => {
    if (event.code == "Enter" && searchTerm != "") {
      const filters = { ...filterParams, search: searchTerm };
      dispatch(setTestsLoading(true));
      dispatch(updateFilterParams(filters));
    }
  };



  return (
    <div className="flex flex-col">
      <div className="flex w-full border-b-1 px-32">
        {/* Title nd staft */}
        <div className="flex flex-col  w-full sm:pt-10  sm:pb-20 ">
          <div className="flex items-center w-full mt-8 -mx-10 ">
            <Typography
              component="h2"
              className="flex-1 text-3xl md:text-3xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate mx-10"
            >
              {suite?.name} <CopyLink url={window.location.href} />
            </Typography>
          </div>

          {/* APPLIED FILTERS COMPENENT */}
          <AppliedFilter filterParams={filterParams} />
        </div>
        {/* BOTTONS */}
        <div className="flex w-full items-center gap-20 justify-end">
          {suite?.id != "all" && <AIChatPanelToggleButton />}

          <FilterDrawer splitters={splitters} />

          <SearchInputText
            value={searchTerm}
            onKeyDown={handleKeyPress}
            onChange={handleOnChange}
            motion={motion}
            placeholder="Search Test case"
          />

          {hasRequiredRole && !filterParams?.all_suites && (
            <Button
              className=" min-w-120"
              variant="contained"
              color="secondary"
              // className="w-5/6"
              onClick={() =>
                dispatch(openTestDialog({ testId: null, open: true }))
              }
              startIcon={<FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon>}
            >
              Add test
            </Button>
          )}
          <TestCaseDialog projectId={projectId} />
        </div>
      </div>
      {hasRequiredRole && <TestCaseSubHeader />}
    </div>
  );
};

export default TestCaseHeader;
