
import { Tooltip } from '@mui/material';
import { useState } from 'react';
import IteratorMenu from './IteratorMenu';
import _ from 'lodash';

export const IteratorMenuList = ({ iteratorList, testForm, onChange, onClick }) => {

  const { splitters = [] } = testForm;
  const setValue = (labels, id) => {

    onChange(labels, id);
  }



  return (
    <>
      {
        iteratorList.map((iterator) => (
          <Tooltip arrow key={iterator.id} title={iterator.name} placement="top">
            <div>
              <IteratorMenu onClick={onClick} key={iterator.id} selectedLabels={splitters} onChange={(labels, id) => setValue(labels, id)} iterator={iterator} ></IteratorMenu>
            </div>
          </Tooltip>
        ))
      }
    </>
  )
}