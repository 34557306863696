import { Divider, InputAdornment, TextField, Typography } from "@mui/material";
import AccordionDetails from "app/shared-components/accordion/AccordionDetails";
import AccordionPanel from "app/shared-components/accordion/AccordionPanel";
import AccordionSummary from "app/shared-components/accordion/AccordionSummary";
import { useState } from "react";
import { Controller } from "react-hook-form";

const MoreFields = (props) => {
  const { control, formState } = props;
  const { errors } = formState;
  const [expanded, setExpanded] = useState("");
  const [value, setValue] = useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <AccordionPanel
        expanded={expanded === "moreFields"}
        onChange={handleChange("moreFields")}
      >
        <AccordionSummary>
          <Typography className="w-full text-grey-700 text-15 ">
            More Fields
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Controller
            name="time_taken"
            control={control}
            render={({ field }) => (
              <TextField
                value={value}
                onChange={(event) => {
                  const val = event.target.value;
                  if (val === "" || /^\d+$/.test(val)) {
                    setValue("time_taken", val);
                  }
                }}
                {...field}
                className="mt-8 mb-16"
                label="Time Spent"
                size="small"
                error={!!errors.time_taken}
                helperText={errors?.time_taken?.message}
                id="time_taken"
                type="number"
                InputProps={{
                  step: 1,
                  endAdornment: (
                    <InputAdornment position="end">Minutes</InputAdornment>
                  ),
                }}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </AccordionDetails>
      </AccordionPanel>
    </div>
  );
};

export default MoreFields;
