import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Card,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import { Controller, useFormContext } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { MATERIALICONS } from "./material-icons";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { selectSplitters } from "src/app/main/testcase/store/testSlice";
import { useSelector } from "react-redux";
import {
  createSplitter,
  refreshSplitters,
  updateSplitter,
} from "../../store/splitterSlice";

export const AddSplitterCard = () => {
  const iconType = "material-solid";
  const [loading, setLoading] = useState(false);
  const [icon, setIcon] = useState(`${iconType}:label`);
  const materialIcons = MATERIALICONS;
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const {
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    control,
  } = useFormContext({ mode: "onChange" });
  const splitterId = watch("id");
  const iconSelected = watch("icon");

  useEffect(() => {
    setIcon(`${iconType}:${iconSelected}`);
  }, [iconSelected]);

  const saveSplitter = (value) => {
    setLoading(true);
    const labels =
      typeof value?.labels === "string" ? value.labels.split(",") : [];
    const icon = value?.icon
      ? `${iconType}:${value?.icon}`
      : `${iconType}:label`;
    const data = {
      ...value,
      labels,
      icon,
      project_id: projectId,
    };
    splitterId ? update(data) : create(data);
  };

  const create = (data) => {
    dispatch(createSplitter(data)).then((res) => {
      if (res.payload?.status == "success") {
        setLoading(false);
        reset();
        setIcon(`${iconType}:label`);
        dispatch(refreshSplitters(res.payload.data));
      }
    });
  };

  const update = (data) => {
    data.id = splitterId;
    dispatch(updateSplitter(data)).then((res) => {
      if (res.payload?.status == "success") {
        setLoading(false);
        reset({ name: "" });
        setIcon(`${iconType}:label`);
        dispatch(refreshSplitters(res.payload.data));
      }
    });
  };

  return (
    <Card component={motion.div}>
      <div className="px-32 py-24">
        <div className="flex w-full mb-20 justify-between">
          <Typography className="text-2xl font-semibold leading-tight">
            Add Splitter
          </Typography>
          {splitterId && (
            <Button
              size="small"
              onClick={() => {
                reset({ name: "" });
              }}
              color="secondary"
            >
              Clear form
            </Button>
          )}
        </div>

        <form
          noValidate
          className="flex flex-col gap-20 w-full"
          onSubmit={handleSubmit(saveSplitter)}
        >
          <Controller
            name="name"
            defaultValue=""
            className=" mb-10"
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                sx={{ width: 300 }}
                error={!!errors.name}
                size="small"
                value={value}
                required
                helperText={errors?.name?.message}
                label="Splitter Name"
              />
            )}
          />

          <Controller
            name="labels"
            className="mb-10"
            defaultValue=""
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                placeholder="e.g Option 1,Option 2,Option 3"
                sx={{ width: 300 }}
                error={!!errors.labels}
                size="small"
                value={value}
                required
                helperText={
                  errors?.labels?.message
                    ? errors?.labels?.message
                    : "Enter values seperate by comma"
                }
                label="Splitter Options"
              />
            )}
          />

          {/* ICON */}
          <div className="flex w-full items-center gap-10">
            <Controller
              name="icon"
              defaultValue="label"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  size="small"
                  options={materialIcons}
                  disablePortal
                  onChange={(event, value) => {
                    setIcon(`${iconType}:${value}`);
                    onChange(value);
                  }}
                  value={value}
                  className=""
                  id="combo-box-demo"
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      <FuseSvgIcon
                        className="text-48 mr-10"
                        //   size={form.size}
                        color="action"
                      >
                        {`${iconType}:${option}`}
                      </FuseSvgIcon>
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField sx={{ width: 267 }} {...params} label="Icon" />
                  )}
                />
              )}
            />
            <FuseSvgIcon
              className="text-48"
              //   size={form.size}
              color="action"
            >
              {icon}
            </FuseSvgIcon>
          </div>

          <LoadingButton
            loading={loading}
            variant="contained"
            type="submit"
            color="secondary"
          >
            Save Splitter
          </LoadingButton>
        </form>
      </div>
    </Card>
  );
};
