import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { styled } from "@mui/material/styles";

import FusePageSimple from '@fuse/core/FusePageSimple';
import { SuiteNavigation } from './sidebar/SuiteNavigation';
import { useThemeMediaQuery } from '@fuse/hooks';
import TestCaseHeaderView from './TestCaseHeaderView';
import TestCaseContentView from './TestCaseContentView';

import {
  getIterators,
  getAutomationStatus,
  getPriorityType,
  getTestcaseTypes,
  getTestTags,
} from './store/testSlice';
import { clearFilters } from 'app/store/filterSlice';
import { getSuite, setCurrentSuite } from './store/suiteSlice';
import { getTestStatus } from './store/testsSlice';
import { useLocalStorage } from 'src/app/utils/customHook';

const TestCaseView = () => {
  const routeParams = useParams();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const { suiteSidebarStatus } = useSelector((state) => state.suitesApp.suite);
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const { suiteId, testId } = routeParams;
  const dispatch = useDispatch();
  const [selectedProject, _] = useLocalStorage('selected_project');

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    if (suiteId && suiteId != 'all') {
      dispatch(getSuite(suiteId)).then((res) => {
        dispatch(setCurrentSuite(res.payload));
      });
    } else {
      dispatch(setCurrentSuite({ id: 'all', name: 'All Test cases' }));
    }

    dispatch(getIterators(selectedProject.id));
    dispatch(getTestStatus());
    dispatch(getAutomationStatus());
    dispatch(getPriorityType());
    dispatch(getTestcaseTypes());
    dispatch(getTestTags(selectedProject.id));

    return () => {
      dispatch(clearFilters());
    };
  }, []);

  return (
    <Root
      className='bg-transparent'
      header={<TestCaseHeaderView />}
      content={
        <TestCaseContentView />
      }
      leftSidebarOpen={suiteSidebarStatus}
      leftsidebarwidth={500}
      leftSidebarOnClose={() => {
        setLeftSidebarOpen(false);
      }}
      leftSidebarContent={
        <>
          <SuiteNavigation />
        </>
      }
      scroll='content'
    ></Root>
  );
};

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
  }
}));

export default TestCaseView;
