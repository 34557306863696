import { Button, TextField, Typography } from "@mui/material";
import TamboraTreeSelect from "app/shared-components/TamboraTreeSelect";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectSuites } from "../../store/suiteSlice";
import {
  cloneTestCases,
  closeTestDialog,
  selectedTestIds,
  updatedSelectedTestIds,
} from "../../store/testsSlice";
import { LoadingButton } from "@mui/lab";
import FuseUtils from "@fuse/utils/FuseUtils";
import { useDispatch } from "react-redux";
import { showMessage } from "app/shared-components/ShowMessage";

const CopyTestsForm = () => {
  const suites = useSelector(selectSuites);
  const [suite, setSuite] = useState();
  const [loading, setLoading] = useState(false);
  const selectedTestsIds = useSelector(selectedTestIds);
  const dispatch = useDispatch();

  const cloneTests = () => {
    setLoading(true);
    const payload = {
      suite_id: suite.id,
      cases: selectedTestsIds,
    };
    dispatch(cloneTestCases(payload)).then((res) => {
      setLoading(false);
      if (res.payload.status == "success") {
        dispatch(updatedSelectedTestIds([]));
        dispatch(closeTestDialog());
        dispatch(
          showMessage({
            message: res.payload.message,
            variant: "success",
          })
        );
      }
    });
  };

  return (
    <div className=" h-full">
      <div className="flex flex-col p-36 gap-20 h-full">
        <Typography className="mx-5 mt-5 leading-6" color="text.secondary">
          You have selected{" "}
          <span className=" font-bold">{selectedTestsIds.length}</span> test
          cases to clone. Please choose the target suite where you want the
          cloned test cases to be placed.
        </Typography>

        <TamboraTreeSelect
          label="Destination Suite"
          options={suites}
          value={suite}
          onChange={(event, value) => {
            setSuite(value);
          }}
          renderInput={(params) => <TextField {...params} label="Suite" />}
        ></TamboraTreeSelect>
      </div>
      {/* Foot */}
      <div className="flex flex-wrap px-36 py-20 gap-4 z-[999] bg-gray-50 dark:bg-[#111827] sticky bottom-0">
        <Button
          disabled={loading}
          className="ml-auto"
          onClick={() => dispatch(closeTestDialog())}
        >
          Cancel
        </Button>
        <LoadingButton
          disabled={!suite || FuseUtils.isObjectEmpty(suite)}
          type="submit"
          color="secondary"
          loading={loading}
          variant="contained"
          onClick={cloneTests}
        >
          Clone All
        </LoadingButton>
      </div>
    </div>
  );
};

export default CopyTestsForm;
