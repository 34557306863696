import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Button, Skeleton, Typography } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTestRun, selectCurrentTestRun } from "../store/testRunSlice";
import { useEffect } from "react";
import { dateFormatter } from "src/app/utils/Formatter";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import useRoleBasedAccess from "src/app/utils/customHook";
import { authRoles } from "src/app/auth";
import FuseUtils from "@fuse/utils/FuseUtils";
import { StatusBadge } from "app/shared-components/StatusBadge";
import CopyLink from "../../testcase/header/CopyLink";

const SkeletonLoading = () => {
  return (
    <>
      <Skeleton animation="wave" className="w-lg h-52 " />
      <div className="flex gap-10">
        <Skeleton animation="wave" className="w-200  h-32 " />
        <Skeleton animation="wave" className="w-200  h-32 " />
      </div>
    </>
  );
};

const Header = ({ testRun }) => {
  return (
    <div className=" flex flex-col  mx-16">
      <Typography className="flex-1 text-4xl truncate font-semibold tracking-tight leading-7 md:leading-snug ">
        {testRun?.name}{" "}
        <CopyLink
          url={window.location.href.replace(/(suites\/).*/, "summary")}
        />
      </Typography>

      <div className="flex items-end justify-start gap-24">
        <StatusBadge key="21" status={testRun?.testrun_status}></StatusBadge>

        <div className="flex gap-5">
          <FuseSvgIcon className="opacity-40">heroicons-solid:user</FuseSvgIcon>
          <Typography className="leading-6 truncate" color="text.secondary">
            {testRun?.created_by?.first_name} {testRun?.created_by?.last_name}
          </Typography>
        </div>

        <div className="flex gap-5">
          <FuseSvgIcon className="opacity-40">
            heroicons-solid:clock
          </FuseSvgIcon>
          <Typography className="leading-6 truncate" color="text.secondary">
            {dateFormatter(testRun?.created_at)}
          </Typography>
        </div>

        <div className="flex gap-5">
          <FuseSvgIcon className="opacity-40">
            heroicons-solid:beaker
          </FuseSvgIcon>
          <Typography className="leading-6 truncate" color="text.secondary">
            {testRun?.total_testcases}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const TestRunProcessHeader = (props) => {
  const { selectedTab, onClickTab } = props;
  const { projectKey, testRunKey } = useParams();
  const testRun = useSelector(selectCurrentTestRun);
  const dispatch = useDispatch();
  const { hasRequiredRole } = useRoleBasedAccess(authRoles.external_tester, []);

  useEffect(() => {
    if (isEmpty(testRun)) {
      dispatch(getTestRun(testRunKey));
    }
  }, []);

  return (
    <div className="flex flex-col w-full px-24  ">
      <motion.div
        initial={{ x: 20, opacity: 0, marginTop: 20 }}
        animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
      >
        <Typography
          className="flex items-center sm:mb-12"
          component={Link}
          role="button"
          to={`/project/${projectKey}/testruns`}
          color="secondary"
        >
          <FuseSvgIcon size={20}>heroicons-outline:arrow-sm-left</FuseSvgIcon>
          <span className="flex mx-4 font-medium ">Test Runs</span>
        </Typography>
      </motion.div>
      <div className="flex flex-col w-full sm:flex-row sm:items-center min-w-0 mb-20 ">
        {/* <div className="flex truncate text-ellipsis items-center min-w-0"> */}
        <motion.div
          className="truncate"
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.3 } }}
        >
          {FuseUtils.isObjectEmpty(testRun) ? (
            <SkeletonLoading />
          ) : (
            <Header testRun={testRun} />
          )}
        </motion.div>
        {/* </div> */}
      </div>

      <div className="flex items-center gap-10">
        {hasRequiredRole && (
          <Button
            // onClick={handleOpenProjectMenu}
            className="flex items-center border border-solid border-b-0 rounded-t-xl rounded-b-0 h-40 px-16 text-13 sm:text-16"
            variant="default"
            onClick={() => onClickTab("suites")}
            sx={{
              backgroundColor: (theme) =>
                selectedTab == "suites"
                  ? theme.palette.background.default
                  : theme.palette.background.paper,
              borderColor: (theme) => theme.palette.divider,
            }}
          >
            Results
          </Button>
        )}
        <Button
          onClick={() => onClickTab("summary")}
          className="flex items-center border border-solid border-b-0 rounded-t-xl rounded-b-0 h-40 px-16 text-13 sm:text-16"
          variant="default"
          sx={{
            backgroundColor: (theme) =>
              selectedTab == "summary"
                ? theme.palette.background.default
                : theme.palette.background.paper,
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          Summary
        </Button>
      </div>
    </div>
  );
};

export default TestRunProcessHeader;
