import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import ListAltIcon from "@mui/icons-material/ListAlt";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { ArchiveOutlined } from "@mui/icons-material";
import InventoryIcon from "@mui/icons-material/Inventory";
export const TypeIcon = (props) => {
  const { isModule, isAllTests } = props;

  if (isAllTests) return <InventoryIcon />;
  else if (isModule) {
    return <FolderIcon />;
  } else return <TextSnippetIcon />;
};
