import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import { openAssignTestModal } from "../store/testRunSlice";
import { useDispatch } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import { use } from "i18next";
import { boolean } from "yup";
import {
  selectedTestIds,
  updatedSelectedTestIds,
} from "../../testcase/store/testsSlice";
import { useSelector } from "react-redux";
import { selectSelectedContactId } from "app/theme-layouts/shared-components/chatPanel/store/contactsSlice";
import TamboraUtils from "src/app/utils/TamboraUtils";

const splitterCount = (array) => {
  const sum = array.reduce((acc, testCase) => {
    // If the splitters array is empty, count as 1
    const multiplier =
      testCase.splitters.length === 0
        ? 1
        : testCase.splitters.reduce((acc, splitter) => {
            // Multiply the length of labels
            return acc * splitter.labels.length;
          }, 1); // Initialize with 1 to handle empty splitters array

    // Add the multiplier to the accumulator
    return acc + multiplier;
  }, 0); // Initialize accumulator with 0

  return sum;
};

const AssignTests = () => {
  const dispatch = useDispatch();
  const { watch, control } = useFormContext();
  const assignedTests = useSelector(selectedTestIds);
  const [allTest, setAllTests] = useState(
    TamboraUtils.parseBoolean(watch("selected_all_tests"))
  );

  useEffect(() => {
    setAllTests(TamboraUtils.parseBoolean(watch("selected_all_tests")));
  }, []);

  useEffect(() => {
    setAllTests(TamboraUtils.parseBoolean(watch("selected_all_tests")));
  }, [watch("selected_all_tests")]);

  return (
    <Card className=" h-208 w-384" component={motion.div}>
      <div className="px-32 py-24">
        <div className="flex w-full mb-20 justify-between">
          <Typography className="text-2xl font-semibold leading-tight">
            Assign Test cases
          </Typography>
        </div>
        <form noValidate>
          <Controller
            name="selected_all_tests"
            className="mt-8"
            control={control}
            defaultValue="false"
            render={({ field }) => (
              <RadioGroup {...field} aria-label="gender" name="gender1">
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Select all Test cases"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Select Test cases to include:"
                />
              </RadioGroup>
            )}
          />

          <div className="flex  gap-10 items-center">
            <Button
              className="whitespace-nowrap ml-36 mx-4"
              variant="contained"
              disabled={allTest}
              color="secondary"
              size="small"
              onClick={() => dispatch(openAssignTestModal(true))}
            >
              Select Test cases
            </Button>
            {!allTest && (
              <Typography>{`${splitterCount(
                assignedTests
              )} test(s) selected`}</Typography>
            )}
          </div>
        </form>
      </div>
    </Card>
  );
};

export default AssignTests;
