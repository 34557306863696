import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import PendingIcon from "@mui/icons-material/Pending";
import { useState } from "react";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { TypeFormatFlags } from "typescript";
import { deleteSuite, openSuiteDialog } from "../store/suiteSlice";
import { useDispatch } from "react-redux";
import {
  openDialog,
  closeDialog,
  setDialogLoading,
} from "app/store/fuse/dialogSlice";
import ConfirmDialog from "app/shared-components/ConfirmDialog";
import SystemDialog from "app/shared-components/SystemDialog";
import { getSuites } from "../store/suitesSlice";
import { showMessage } from "app/shared-components/ShowMessage";
import { Navigate, useNavigate } from "react-router-dom";

export const TreeItemMenu = ({ onMenuClose, isModule, projectId, suite }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  dispatch(setDialogLoading(false));
  const navigate = useNavigate();

  const options = [
    {
      id: "edit",
      label: "Edit",
      icon: "material-solid:edit",
      action: "edit",
      display: true,
    },
    {
      id: "delete",
      label: "Delete",
      icon: "material-solid:delete",
      action: "delete",
      display: true,
    },
    {
      id: "divider0",
      label: "Divider",
      display: true,
      display: isModule,
    },
    {
      id: "subModule",
      label: "Add sub-module",
      icon: "material-solid:folder",
      action: "addSubModule",
      display: isModule,
    },
    {
      id: "addSuite",
      label: "Add Suite",
      icon: "material-solid:text_snippet",
      action: "addSuite",
      display: isModule,
    },
    {
      id: "divider1",
      label: "Divider",
      display: true,
      display: !isModule,
    },
    {
      id: "import",
      label: "Import Test cases",
      icon: "material-solid:cloud_upload",
      action: "import",
      display: !isModule,
    },
    // {
    //   label: "Copy suite's link",
    //   icon: "material-solid:link",
    //   action: "copyLink",
    //   display: !isModule,
    // },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onDeleteSuite = () => {
    dispatch(setDialogLoading(true));
    dispatch(deleteSuite(suite.id)).then((res) => {
      if (res.payload.status == "error") {
        dispatch(setDialogLoading(false));
        return dispatch(
          openDialog({
            children: <SystemDialog message={res.payload.message} />,
          })
        );
      }
      dispatch(getSuites({ projectID: projectId, isTree: false }));
      dispatch(closeDialog());
      dispatch(
        showMessage({
          message: res.payload.message, //text or html
          variant: "success", //success error info warning null
        })
      );
    });
  };

  const showConfirmationDelete = () => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Delete Confirmation"
            message={`Are you sure you want to delete this ${
              isModule ? "Module" : "Suite"
            }? All the data within this will be lost. This action cannot be undone.`}
            confirmButton={true}
            confirmButtonLabel="Delete"
            confirmAction={onDeleteSuite}
            confirmButtonColor="bg-red-700"
          />
        ),
      })
    );
  };

  const handleClose = (action) => {
    switch (action) {
      case "edit":
        dispatch(
          openSuiteDialog({
            projectId,
            open: true,
            isModule,
            suiteId: suite.id,
          })
        );
        break;
      case "delete":
        showConfirmationDelete();
        break;
      case "addSuite":
        dispatch(
          openSuiteDialog({
            projectId,
            open: true,
            isModule: false,
            parentId: suite.id,
            suiteId: suite.id,
            isNew: true,
          })
        );
        break;
      case "addSubModule":
        dispatch(
          openSuiteDialog({
            projectId,
            open: true,
            isModule: true,
            parentId: suite.id,
            suiteId: suite.id,
            isNew: true,
          })
        );
        break;
      case "import":
        navigate(`/tests/project/${projectId}/import?suite_id=${suite.id}`);
        break;
      default:
        break;
    }
    setAnchorEl(null);
    onMenuClose();
  };

  return (
    <>
      <IconButton onClick={handleClick} className="ml-10" size="small">
        <PendingIcon fontSize="small" />
      </IconButton>
      <Menu
        autoFocus={false}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        variant={"menu"}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {options.map((option) => (
          <div key={option.id}>
            {option.display && (
              <>
                {option.label == "Divider" ? (
                  <Divider key={option.id} />
                ) : (
                  <MenuItem
                    onClick={() => handleClose(option.action)}
                    hidden={true}
                  >
                    <ListItemIcon>
                      <FuseSvgIcon>{option.icon}</FuseSvgIcon>
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                      {option.label}
                    </Typography>
                  </MenuItem>
                )}
              </>
            )}
          </div>
        ))}
      </Menu>
    </>
  );
};
