
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';
import axios from 'axios';

export const getIntegrations = createAsyncThunk(
    'jiraApp/getIntegrations',
    async () => {
        const response = await axios
            .get(`${jwtServiceConfig.organization}/integration`)

        return response.data
    }
)

export const getMappedProjects = createAsyncThunk(
    'jiraApp/getMappedProjects',
    async () => {
        const response = await axios
            .get(`${jwtServiceConfig.integration}/jira/project-mappings`)

        return response
    }
)

export const addMappingProject = createAsyncThunk(
    'jiraApp/addMappingProject',
    async (data, { rejectWithValue }) => {
        try {
            const response = await new Promise((resolve, reject) => {
                axios
                    .post(`${jwtServiceConfig.integration}/jira/project-mapping`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
            return response;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const deleteMappingProject = createAsyncThunk(
    'jiraApp/deleteMappingProject',
    async (project_mapping_id) => {
        const response = await axios
            .delete(`${jwtServiceConfig.integration}/jira/project-mapping/${project_mapping_id}`)
        return response.data
    }
)

export const getJiraProjects = createAsyncThunk(
    'jiraApp/getJiraProjects',
    async (integration_id) => {
        const response = await axios
            .get(`${jwtServiceConfig.integration}/jira/${integration_id}/projects`)

        return response.data
    }
)

const jiraSlice = createSlice({
    name: 'jiraApp',
    initialState: {
        integrations: [],
        jiraProjects: [],
        mappedProjects: []
    },
    reducers: {
    },
    extraReducers: {
        [getIntegrations.fulfilled]: (state, action) => { state.integrations = action.payload.data },
        [getJiraProjects.fulfilled]: (state, action) => { state.jiraProjects = action.payload.data },
        [getMappedProjects.fulfilled]: (state, action) => { state.mappedProjects = action.payload.data },

    },
});

export const selectIntegrations = ({ jiraApp }) => jiraApp.jira.integrations;
export const selectJiraProjects = ({ jiraApp }) => jiraApp.jira.jiraProjects;
export const selectMappedProjects = ({ jiraApp }) => jiraApp.jira.mappedProjects;
export default jiraSlice.reducer;