import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createTestRun, updateTestRun } from "../store/testRunSlice";
import { showMessage } from "app/shared-components/ShowMessage";
import { LoadingButton } from "@mui/lab";

const TestRunHeader = () => {
  const methods = useFormContext();
  const { trigger, getValues } = methods;
  const dispatch = useDispatch();
  const { projectKey } = useParams();
  const navigate = useNavigate();
  const { testRunKey } = useParams();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const isClone = location.pathname.includes("clone");

  const save = async () => {
    const isValid = await trigger();
    const values = getValues();

    let data = {
      ...values,
      testrun_status_id: values.testrun_status.id,
      project_key: projectKey,
      notes: values.notes?.htmlValue ? values.notes.htmlValue : values.notes,
    };
    if (isValid) {
      setLoading(true);
      let response = testRunKey ? update(data) : create(data);
      response.then((res) => {
        if (res.payload.status == "success") {
          const testRun = res.payload.data;
          navigate(`/project/${projectKey}/testruns`);
          dispatch(
            showMessage({
              message: res.payload.message,
              variant: "success",
            })
          );
        }
        setLoading(false);
      });
    }
  };

  const create = async (data) => {
    return dispatch(createTestRun(data));
  };

  const update = async (data) => {
    return dispatch(updateTestRun(data));
  };

  return (
    <div className="  flex flex-col sm:flex-row flex-1 w-full items-center justify-between  py-20 px-24 md:px-32">
      <div className="flex flex-col items-center sm:items-start w-full  ">
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
        >
          <Typography
            className="flex items-center sm:mb-12"
            component={Link}
            role="button"
            to={`/project/${projectKey}/testruns`}
            color="secondary"
          >
            <FuseSvgIcon size={20}>heroicons-outline:arrow-sm-left</FuseSvgIcon>
            <span className="flex mx-4 font-medium ">Test Runs</span>
          </Typography>
        </motion.div>
        <div className="flex items-center max-w-full">
          <motion.div
            className="flex flex-col items-center sm:items-start min-w-0 mx-8 sm:mx-16 "
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.3 } }}
          >
            <Typography className="text-2xl md:text-4xl font-extrabold">
              {testRunKey ? "Edit" : isClone ? "Clone" : "New"} Test Run
            </Typography>
            {/* <Typography variant="caption" className="font-medium  ">
              User Detail
            </Typography> */}
          </motion.div>
        </div>
      </div>
      <motion.div
        className="flex"
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
      >
        <LoadingButton
          loading={loading}
          className="whitespace-nowrap mx-4"
          variant="contained"
          color="secondary"
          onClick={save}
        >
          Save
        </LoadingButton>
      </motion.div>
    </div>
  );
};

export default TestRunHeader;
