import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';
import axios from 'axios';

export const getMembers = createAsyncThunk('projectSettings/team/getMembers', async (projectId, { rejectWithValue }) => {
    try {
        const response = await new Promise((resolve, reject) => {
            axios
                .get(`${jwtServiceConfig.projects}/${projectId}/members`)
                .then((response) => {
                    resolve(response.data.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
        return response;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }

});

export const deleteMember = createAsyncThunk('projectSettings/team/deleteMember', async (memberId, { rejectWithValue }) => {
    try {
        const response = await new Promise((resolve, reject) => {
            axios
                .delete(`${jwtServiceConfig.projects}/members/${memberId}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
        return response;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }

});


export const addMembers = createAsyncThunk('projectSettings/team/addMembers', async (data, { rejectWithValue }) => {
    try {
        const response = await new Promise((resolve, reject) => {
            axios
                .post(`${jwtServiceConfig.projects}/members`, data)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
        return response;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }

});

const teamSlice = createSlice({
    name: 'projectSettingsApp',
    initialState: {
        members: []
    },
    reducers: {},
    extraReducers: {
        [getMembers.fulfilled]: (state, action) => {
            state.members = action.payload;
        },
        [addMembers.fulfilled]: (state, action) => {
            state.member = action.payload;
        },
        [deleteMember.fulfilled]: (state, action) => {
            state.member = action.payload;
        },

    },
});

export const selectMembers = ({ projectSettingsApp }) => projectSettingsApp.team.members;
export default teamSlice.reducer;