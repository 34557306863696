import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Delete, PhotoCamera } from "@mui/icons-material";
import { Avatar, Box, Button, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: theme.spacing(2),
  },
}));

const AvatarUploader = (props) => {
  const { src, onChange, variant = "circular", defaultImage = "" } = props;
  const classes = useStyles();
  const [avatar, setAvatar] = useState();

  const parseUrlToFile = async (imageUrl) => {
    const imageData = await fetch(imageUrl);
    const blob = await imageData.blob();
    const file = new File([blob], `avatar.${blob.type.split("/")[1]}`, {
      type: blob.type,
    });
    return file;
  };

  useEffect(() => {
    const avatarUrl = src == undefined ? defaultImage : src;
    if (!(avatarUrl instanceof File)) {
      if (avatarUrl == defaultImage) {
        setAvatar(defaultImage);
        onChange(defaultImage);
      } else {
        parseUrlToFile(avatarUrl).then((file) => {
          const reader = new FileReader();
          reader.onload = () => {
            setAvatar(reader.result);
            onChange(file);
          };
          reader.readAsDataURL(file);
        });
      }
    }
  }, [src]);

  useEffect(() => {
    return () => {
      setAvatar("");
      onChange("");
    };
  }, []);

  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setAvatar(reader.result);
        onChange(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveAvatar = () => {
    setAvatar(defaultImage);
    onChange(defaultImage);
  };

  return (
    <Box
      sx={{
        borderWidth: 4,
        borderStyle: "solid",
        borderColor: "background.paper",
      }}
      className="relative flex items-end justify-center  w-160 h-160 rounded-12 overflow-hidden"
    >
      <div className="absolute inset-0  z-10" />
      <div className="absolute bg-black bg-opacity-70 w-full flex items-end justify-center z-20">
        <div className="">
          <label htmlFor="avatar-upload" className="flex p-8 cursor-pointer">
            <input
              accept="image/*"
              className={classes.input}
              id="avatar-upload"
              type="file"
              onChange={handleAvatarChange}
            />
            <FuseSvgIcon className="text-white">
              heroicons-outline:camera
            </FuseSvgIcon>
          </label>
        </div>
        <div>
          <IconButton onClick={handleRemoveAvatar}>
            <FuseSvgIcon className="text-white">
              heroicons-solid:trash
            </FuseSvgIcon>
          </IconButton>
        </div>
      </div>

      <Avatar
        sx={{
          backgroundColor: "background.default",
          color: "text.secondary",
        }}
        alt="Avatar"
        variant={"square"}
        src={avatar}
        className="object-cover w-full h-full text-64 font-bold"
      />
    </Box>
  );
};

export default AvatarUploader;
