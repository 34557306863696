import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Avatar, SvgIcon, Tab, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { Component, useEffect, useState } from "react";
import withReducer from "app/store/withReducer";
import reducer from "./store";
import { styled } from "@mui/material/styles";
import FusePageSimple from "@fuse/core/FusePageSimple";
import JiraIntegrationHeader from "./JiraIntegrationHeader";
import ConnectionsTab from "./tabs/ConnectionsTab";
import JiraIcon from "./widgets/JiraIcon";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { useDispatch } from "react-redux";
import { getIntegrations, getMappedProjects } from "./store/jiraSlice";
import { getProjects } from "src/app/main/projects/store/projectsSlice";
import ProjectMappingTab from "./tabs/project-mapping-tab/ProjectMappingTab";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
  },
  "& .FusePageSimple-toolbar": {},
  "& .FusePageSimple-content": {},
  "& .FusePageSimple-sidebarHeader": {},
  "& .FusePageSimple-sidebarContent": {},
}));

const JiraItengrationApp = () => {
  const [value, setValue] = useState("0");
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getIntegrations());
    dispatch(getProjects());
    dispatch(getMappedProjects());
  }, []);

  return (
    <Root
      header={<JiraIntegrationHeader />}
      content={
        <Box sx={{ display: "flex" }} className="h-full w-full">
          <TabContext value={value}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              orientation="vertical"
              variant="scrollable"
              textColor="secondary"
              indicatorColor="secondary"
              className=" w-136 min-w-136  "
              scrollButtons={false}
              sx={{ borderRight: 1, borderBottom: 1, borderColor: "divider" }}
            >
              <Tab
                icon={<JiraIcon sx={{ fontSize: 45 }} />}
                value="0"
                label="Jira Accounts"
                className="w-full my-20 h-70 text-14"
              ></Tab>

              <Tab
                icon={<AccountTreeIcon fontSize="large" />}
                value="1"
                className="w-full my-20 min-h-70 h-70 text-14"
                label="Project Mapping"
              />
            </TabList>
            <TabPanel className="w-full" value="0">
              <ConnectionsTab />
            </TabPanel>
            <TabPanel className="w-full" value="1">
              <ProjectMappingTab />
            </TabPanel>
          </TabContext>
        </Box>
      }
    />
  );
};
export default withReducer("jiraApp", reducer)(JiraItengrationApp);
