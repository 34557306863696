import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import jwtService from 'src/app/auth/services/jwtService';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';


export const createSplitter = createAsyncThunk(
    'splitterApp/createSplitter',
    async (data, { dispatch }) => {
        const response = await jwtService.POST(`/splitter`, data, dispatch)
        return response;
    }
)

export const updateSplitter = createAsyncThunk(
    'splitterApp/updateSplitter',
    async (data, { dispatch }) => {
        const response = await jwtService.PATCH(`/splitter/${data.id}`, data, dispatch)
        return response;
    }
)

export const getSplitters = createAsyncThunk(
    'splitterApp/getSplitters',
    async (projectId, { dispatch }) => {
        const response = await jwtService.GET(`${jwtServiceConfig.projects}/${projectId}/splitter`, dispatch);
        return response;
    }
)

export const destroySplitter = createAsyncThunk(
    'splitterApp/deleteSplitter',
    async (splitterId, { dispatch }) => {
        const response = await jwtService.DELETE(`/splitter/${splitterId}`, dispatch);
        return response;
    }
)


const splitterSlice = createSlice({
    name: 'splitterApp',
    initialState: {
        splitters: []
    },
    reducers: {
        refreshSplitters: (state, action) => {
            if (action.payload?.destroy) {
                _.remove(state.splitters, (item) => item.id == action.payload.id)
            } else {
                const index = state.splitters.findIndex(obj => obj.id == action.payload.id);
                if (index !== -1) {
                    state.splitters[index] = action.payload;
                } else {
                    state.splitters.unshift(action.payload)
                }
            }
        },
    },
    extraReducers: {
        [getSplitters.fulfilled]: (state, action) => {
            state.splitters = action.payload.result
        },
    },
});

export const selectSplitters = ({ projectSettingsApp }) => projectSettingsApp.splitter.splitters;
export const {
    refreshSplitters,
} = splitterSlice.actions;
export default splitterSlice.reducer;