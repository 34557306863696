import { useEffect, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import {
  Dialog,
  Toolbar,
  AppBar,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { closeTestDialog, openTestDialog } from "../store/testsSlice";
import { selectedDialogTest } from "../store/testsSlice";
import { TestForm } from "./test-form/TestForm";
import { TestFormView } from "./test-form/form-preview/TestFormView";
import CloseIcon from "@mui/icons-material/Close";
import CopyLink from "./CopyLink";
import BulkEditTestForm from "./test-form/BulkEditTestForm";
import { resetForm } from "../store/testSlice";
import { styled } from "@mui/material/styles";
import CopyTestsForm from "./test-form/CopyTestsForm";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    alignItems: "normal",
  },
}));

export const TestCaseDialog = ({ projectId = "" }) => {
  const { isOpenTestDialog, isClone, viewMode, test } = useSelector(
    (state) => state.suitesApp.tests
  );


  const dispatch = useDispatch();
  const routeParams = useParams();
  const { suiteId } = routeParams;
  const [searchParams] = useSearchParams();
  const dialogIsOpen = searchParams.get("open");
  const testId = searchParams.get("testId");

  useEffect(() => {
    if (testId && dialogIsOpen) {
      dispatch(openTestDialog({ testId, open: dialogIsOpen, viewMode: true }));
    }
  }, [testId, dialogIsOpen]);

  const onCloseDialog = () => {
    dispatch(resetForm());
    dispatch(closeTestDialog());
  };

  const setTitle = () => {
    const isBulkEdit = viewMode === "bulk";
    const isViewMode = viewMode === "view";
    const isCloneMode = viewMode === "clone";

    if (isBulkEdit) {
      return "Edit Test case";
    } else if (isCloneMode) {
      return "Clone Test cases";
    } else if (isViewMode || isClone || test?.id) {
      return (
        <>
          {isClone ? "Clone Test case" : test?.code}
          <CopyLink testId={test?.id} dialogIsOpen={true} />
        </>
      );
    } else {
      return "New Test cases";
    }
  };

  const selectMode = () => {
    switch (viewMode) {
      case "bulk":
        return <BulkEditTestForm />;
      case "clone":
        return <CopyTestsForm />;
      case "view":
        return <TestFormView test={test}></TestFormView>;
      default:
        return (
          <TestForm
            projectId={projectId}
            suiteId={suiteId}
            isClone={isClone}
            test={test}
          />
        );
    }
  };

  return (
    <div>
      <CustomDialog
        fullWidth={true}
        maxWidth={viewMode ? "md" : "lg"}
        classes={{
          paper: "w-full my-60 top-0 ",
        }}
        open={isOpenTestDialog}
      >
        <AppBar
          className="sticky top-0 z-[999]"
          color="secondary"
          position="static"
          elevation={0}
        >
          <Toolbar className="px-36 flex w-full justify-between">
            <Typography variant="subtitle1" color="inherit">
              {setTitle()}
              {/* {dialogTitle} <CopyLink testId={test?.id} dialogIsOpen={true} /> */}
            </Typography>
            <IconButton onClick={onCloseDialog}>
              <CloseIcon className="text-white" />
            </IconButton>
          </Toolbar>
        </AppBar>

        {selectMode()}
      </CustomDialog>
    </div>
  );
};
