import FusePageCarded from "@fuse/core/FusePageCarded";
import { FormProvider, useForm } from "react-hook-form";
import UserHeader from "./UserHeader";
import reducer from "../store";
import withReducer from "app/store/withReducer";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { Tab, Tabs } from "@mui/material";
import BasicInfoTab from "./tabs/BasicInfoTab";
import { useState } from "react";
import { useDeepCompareEffect } from "@fuse/hooks";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUser, selectUser } from "../store/userSlice";
import { useSelector } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";

const schema = yup.object().shape({
  first_name: yup.string().required("You must enter a First name"),
  last_name: yup.string().required("You must enter a Last name"),
  email: yup.string().required("You must enter a Email"),
  job_title: yup.object().nullable().required("You must select a Job title"),
  role: yup.object().nullable().required("You must select a System role"),
});

const User = (props) => {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [tabValue, setTabValue] = useState(0);
  const routeParams = useParams();
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const methods = useForm({
    mode: "onChange",
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const { reset, watch, control, onChange, formState, getValues, setValue } =
    methods;
  const form = watch();
  const { userId } = routeParams;

  useEffect(() => {
    if (userId === "new") {
      setLoading(false);
    } else {
      dispatch(getUser(userId)).then((response) => {
        const data = response.payload;
        reset(data);
        setLoading(false);
      });
    }
  }, [userId]);

  function handleTabChange(event, value) {
    setTabValue(value);
  }

  return (
    <>
      {loading ? (
        <FuseLoading />
      ) : (
        <FormProvider {...methods}>
          <FusePageCarded
            header={<UserHeader userId={userId} />}
            content={
              <div className="">
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                  classes={{ root: "w-full h-64 border-b-1" }}
                >
                  <Tab className="h-64" label="Basic Info" />
                  {/*{userId && <Tab className="h-64" label="Assigned Projects" />} */}
                </Tabs>
                <div className="p-16 sm:p-24 max-w-3xl">
                  <div className={tabValue !== 0 ? "hidden" : ""}>
                    <BasicInfoTab userId={userId} />
                  </div>
                </div>
                {/* <div className={tabValue !== 1 ? "hidden" : ""}>
             <AssignProjectTab />
           </div> */}
              </div>
            }
            scroll={isMobile ? "normal" : "content"}
          />
        </FormProvider>
      )}
    </>
  );
};

export default withReducer("systemSettingsApp", reducer)(User);
