import { useDispatch } from 'react-redux';
import { changeFuseTheme } from "app/store/fuse/settingsSlice"
import themesConfig from "app/configs/themesConfig"
import { useEffect, useState } from 'react';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useLocalStorage } from 'src/app/utils/customHook';


const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

function Darkbutton() {


  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return (
    <Box>
      <IconButton
        onClick={colorMode.toggleColorMode}
      >
        {theme.palette.mode === 'dark' ? <Brightness4Icon sx={{ fontSize: 30 }} /> : < Brightness7Icon sx={{ fontSize: 30 }} />}
      </IconButton>
    </Box>
  );
}

export default function ToggleColorMode() {
  const dispatch = useDispatch();
  const [mode, setMode] = React.useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    localStorage.setItem('theme', mode);
    if (mode == 'dark') {
      dispatch(changeFuseTheme(themesConfig.defaultDark))
    } else {
      dispatch(changeFuseTheme(themesConfig.default))
    }

  }, [mode])

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Darkbutton />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}



