import { styled } from "@mui/material/styles";
import {
  Badge,
  IconButton,
  ListSubheader,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MuiDrawer from "@mui/material/Drawer";
import FilterListIcon from "@mui/icons-material/FilterList";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
import reducer from "../../store";
import withReducer from "app/store/withReducer";

import { FilterDrawerForm } from "./FilterDrawerForm";
import FilterDrawerButton from "./FilterDrawerButton";

const CustomDrawer = styled(MuiDrawer)(({ theme }) => ({
  "& .MuiDrawer-paperAnchorRight": {
    height: "calc(100% - 100px)",
    width: 300,
    marginTop: 70,
    marginRight: 20,
    borderRadius: 15,
  },
  "& .MuiSelect-select": {
    justifyContent: "left",
    alignItems: "left",
  },
}));

function FilterDrawer({ splitters }) {
  const [open, setOpen] = useState(false);
  const { queryParams } = useSelector((state) => state.filter);

  const onClose = (value = true) => {
    setOpen(value);
  };

  return (
    <>
      <FilterDrawerButton onClick={onClose} />

      <CustomDrawer
        anchor="right"
        open={open}
        sx={{
          height: "10%",
        }}
      >
        <div className="flex w-full px-20 pt-20">
          <Typography
            color="text.secondary"
            className=" grow text-3xl sm:text-3xl font-bold tracking-tigh mb-10"
          >
            Filters
          </Typography>
          <IconButton
            className="justify-items-end items-start"
            onClick={() => setOpen(false)}
            aria-label="delete"
          >
            <CancelIcon />
          </IconButton>
        </div>
        {open && (
          <FilterDrawerForm
            splitters={splitters}
            onClose={() => onClose(false)}
          />
        )}
      </CustomDrawer>
    </>
  );
}

export default withReducer("filterApp", reducer)(FilterDrawer);
