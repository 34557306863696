import React from 'react'
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Button, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { openTestDialog, deleteTest, getTestsBySuiteId } from '../store/testsSlice';
import { useDispatch } from 'react-redux';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { DialogContentText, DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useParams } from 'react-router-dom';
import { getSuiteMetrics } from '../store/suiteSlice';
import { showMessage } from 'app/shared-components/ShowMessage';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useSimplePagination from 'src/app/utils/hooks/paginationHook';
import { selectFilterParams } from 'app/store/filterSlice';
import { useSelector } from 'react-redux';


export const TestMenuOption = (props) => {
    const routeParams = useParams();
    const { test } = props;
    const { suiteId } = routeParams;
    const dispatch = useDispatch();
    const [pagination, setPagination] = useSimplePagination()
    const filters = useSelector(selectFilterParams);


    const onDeleteTest = () => {
        dispatch(deleteTest(test.id)).then((res) => {
            if (res.payload?.status == "success") {
                dispatch(getTestsBySuiteId({ suiteId, filters, pagination }));
                // dispatch(getSuiteMetrics(suiteId));
                dispatch(closeDialog());
                dispatch(
                    showMessage({
                        message: res.payload.message,//text or html
                        variant: 'success'//success error info warning null
                    }));
            }
        })
    };

    const handleDelete = () => {
        dispatch(openDialog({
            children: (
                <>
                    <DialogTitle className='flex flex-row items-center' id="alert-dialog-title">

                        <FuseSvgIcon size={40} className=" text-orange-400 mr-20">feather:alert-triangle</FuseSvgIcon>
                        Are you sure?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" >
                            Do you really want delete this test? this process cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="flex flex-auto text-right px-20 py-20 bg-gray-50 dark:bg-[#111827]">
                        <Button onClick={() => dispatch(closeDialog())} >
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={onDeleteTest} color="error">
                            Delete
                        </Button>
                    </DialogActions>
                </>
            )
        }))
    }


    return (
        <div className="flex  items-center grow justify-end gap-5">
            <Tooltip title="Edit">
                <IconButton onClick={() => dispatch(openTestDialog({ test, open: true }))} aria-label="edit">
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Clone">
                <IconButton onClick={() => dispatch(openTestDialog({ test, open: true, isClone: true }))} aria-label="clone">
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
                <IconButton onClick={handleDelete} aria-label="delete">
                    <DeleteIcon />
                </IconButton>

            </Tooltip>
        </div>
    )
}

