import FusePageSimple from "@fuse/core/FusePageSimple";
import { Typography } from "@mui/material";
import React from "react";
import ImportHeader from "./ImportHeader";
import { styled } from "@mui/material/styles";
import ImportContent from "./ImportContent";
import { use } from "i18next";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSuites } from "../store/suitesSlice";
import { useParams } from "react-router-dom";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
  },
  "& .FusePageSimple-toolbar": {},
  "& .FusePageSimple-content": {},
  "& .FusePageSimple-sidebarHeader": {},
  "& .FusePageSimple-sidebarContent": {},
}));

export default function ImportLayout() {
  const dispatch = useDispatch();
  const params = useParams();

  // useEffect(() => {
  //   dispatch(getSuites({ projectID: params.projectId, isTree: true }));
  // }, []);

  return (
    <Root
      header={<ImportHeader></ImportHeader>}
      content={<ImportContent></ImportContent>}
    ></Root>
  );
}
