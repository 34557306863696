const orderSuitesAsTree = (objects) => {
    // Create a map to store objects by their id for quick access
    const objectMap = {};
    objects.forEach(obj => {
        objectMap[obj.id] = obj;
    });

    // Initialize the result array
    const result = [];

    // Function to recursively build the tree
    function buildTree(parentId, parentNode) {
        const children = objects.filter(obj => obj.parent === parentId);


        if (children.length === 0) {
            return;
        }

        if (!parentNode.data.children) {
            // Ensure that parentNode.data is extensible
            parentNode.data = { ...parentNode.data, children: [] };
        }


        children.forEach(child => {
            parentNode = { ...parentNode.data, children: child.data };
            buildTree(child.id, child);
        });
    }

    // Find the root objects (objects with parent === 0 or null)
    const rootObjects = objects.filter(obj => obj.parent === 0 || obj.parent === null);

    // Build the tree for each root object
    rootObjects.forEach(rootObject => {
        result.push(rootObject);
        buildTree(rootObject.id, rootObject);
    });

    return result;
}
export default orderSuitesAsTree;