import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwtService from 'src/app/auth/services/jwtService';


export const getTestRunSuites = createAsyncThunk(
    'testRunApp/getTestRunSuites',
    async (testRunKey, { dispatch }) => {
        const response = await jwtService.GET(`/testrun/${testRunKey}/suites`, dispatch)
        return response;
    }
)

export const getTestRunTestsBySuite = createAsyncThunk(
    'testRunApp/getTestRunTestsBySuite',
    async ({ testRunKey, suiteId, pagination }, { dispatch }) => {
        const response = await jwtService.GET(`/testrun/${testRunKey}/suites/${suiteId}/testcases`, dispatch, pagination)
        return response;
    }
)

export const getTestRunTests = createAsyncThunk(
    'testRunApp/getTestRunTests',
    async ({ testRunKey, pagination }, { dispatch }) => {
        const response = await jwtService.GET(`/testrun/${testRunKey}/testcases`, dispatch, pagination)
        return response;
    }
)

export const getTestRunStatusStats = createAsyncThunk(
    'testRunApp/getTestRunStatusStats',
    async (testRunKey, { dispatch }) => {
        const response = await jwtService.GET(`/testrun/${testRunKey}/summary/stats`, dispatch)
        return response;
    }
)


export const getExecutedTestCases = createAsyncThunk(
    'testRunApp/getExecutedTestCases',
    async ({ testRunKey, pagination }, { dispatch }) => {
        const response = await jwtService.GET(`/testrun/${testRunKey}/summary/testcases`, dispatch, { ...pagination, size: 10 })
        return response;
    }
)

export const updateTestExecutionStatus = createAsyncThunk(
    'testRunApp/updateTestExecutionStatus',
    async ({ testExecutionId, statusId }, { dispatch }) => {
        const response = await jwtService.POST(`/testrun/testexecution/${testExecutionId}/update-status/${statusId}`, dispatch)
        return response;
    }
)

const initialState = {
    testRunSuites: [],
    testRunTests: [],
    testRunTestPaginate: {},
    executedTestCases: [],
    testRunStatusStats: {},
    executedTestPaginate: {}
}

const testRunProcessSlice = createSlice({
    name: 'testRunsApp',
    initialState,
    reducers: {
        resetRunProcess: (state, action) => {
            state.testRunStatusStats = {}

        },
    },
    extraReducers: {
        [getTestRunSuites.fulfilled]: (state, action) => {
            state.testRunSuites = action.payload.data
        },
        [getTestRunTestsBySuite.fulfilled]: (state, action) => {
            state.testRunTests = action.payload.result
            state.testRunTestPaginate = action.payload.paginate
        },
        [getTestRunStatusStats.fulfilled]: (state, action) => {
            state.testRunStatusStats = action.payload.data
        },
        [getExecutedTestCases.fulfilled]: (state, action) => {
            state.executedTestCases = action.payload.result
            state.executedTestPaginate = action.payload.paginate
        },
    },
});


export const selectTestRunSuites = ({ testRunApp }) => testRunApp.testRunProcessSlice.testRunSuites;
export const selectTestRunTests = ({ testRunApp }) => testRunApp.testRunProcessSlice.testRunTests;
export const selectTestRunStatusStats = ({ testRunApp }) => testRunApp.testRunProcessSlice.testRunStatusStats;
export const selectExecutedTestCases = ({ testRunApp }) => testRunApp.testRunProcessSlice.executedTestCases;
export const selectExecutedTestPaginate = ({ testRunApp }) => testRunApp.testRunProcessSlice.executedTestPaginate;
export const selectTestRunTestPaginate = ({ testRunApp }) => testRunApp.testRunProcessSlice.testRunTestPaginate;
export const { resetRunProcess } = testRunProcessSlice.actions;
export default testRunProcessSlice.reducer;