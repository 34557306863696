import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
import authRoles from '../../app/auth/authRoles';
i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  {
    id: 'metrics-component',
    title: 'Metrics',
    translate: '',
    type: 'item',
    icon: 'material-solid:area_chart',
    url: 'dashboard',
    auth: [...authRoles.admin],
  },
  {
    id: 'projects-list-component',
    title: 'Projects',
    translate: '',
    type: 'item',
    icon: 'heroicons-outline:clipboard',
    url: 'projects',
    end: true
  },
  // {
  //   id: 'general_group',
  //   title: 'General',
  //   subtitle: 'Reports, Metrics, Overview',
  //   type: 'group',
  //   auth: [...authRoles.admin],
  //   children: [{
  //     id: 'metrics-component',
  //     title: 'Metrics',
  //     translate: '',
  //     type: 'item',
  //     icon: 'material-solid:area_chart',
  //     url: 'dashboard',
  //   },
  //   {
  //     id: 'projects-list-component',
  //     title: 'Projects',
  //     translate: '',
  //     type: 'item',
  //     icon: 'heroicons-outline:clipboard',
  //     url: 'projects',
  //     end: true
  //   },]
  // },
  {
    id: 'project_group',
    title: 'Project',
    subtitle: 'Select a project to enable these options',
    type: 'group',
    icon: 'heroicons-outline:home',
    children: [
      {
        id: 'dashboard-item',
        title: 'Dashboard',
        translate: '',
        disabled: true,
        active: false,
        type: 'item',
        icon: 'heroicons-outline:chart-pie',
        url: '/projects/:projectid/dashboard',
      },
      {
        id: 'document-item',
        title: 'Documents',
        disabled: true,
        type: 'item',
        icon: 'heroicons-outline:document-text',
        url: '/projects/:projectKey/documents',
        active: false
      },
      {
        id: 'tests-item',
        title: 'Test cases',
        disabled: true,
        type: 'item',
        icon: 'heroicons-outline:beaker',
        url: '/tests/project/:projectid/suites/all',
        active: false
      },
      {
        id: 'tests-run-item',
        title: 'Test runs',
        disabled: true,
        type: 'item',
        icon: 'heroicons-outline:trending-up',
        url: '/project/:projectKey/testruns',
        active: false
      },
      {
        id: 'bug',
        title: 'Bugs',
        disabled: true,
        type: 'item',
        icon: 'material-outline:bug_report',
        url: '/bugs/',
        active: false
      },
      {
        id: 'project-setting',
        title: 'Settings',
        disabled: true,
        type: 'item',
        icon: 'heroicons-outline:cog',
        url: '/projects/:projectId/settings/',
        active: false
      }
    ]
  },
  {
    id: 'system_setting',
    title: 'System Setting',
    subtitle: '',
    visible: false,
    auth: [...authRoles.tester],
    type: 'group',
    icon: 'heroicons-outline:home',
    children: [
      {
        id: 'users-item',
        title: 'Users',
        disabled: false,
        type: 'item',
        icon: 'heroicons-outline:user-group',
        url: '/system-settings/users',
        active: false
      },
      {
        id: 'integration-item',
        title: 'Jira Integration',
        auth: ['Organization Admin'],
        disabled: false,
        type: 'item',
        icon: 'heroicons-outline:puzzle',
        url: '/system-settings/integrations/jira',
        active: false
      }
    ]
  }
];

export default navigationConfig;
