import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    pagination: { page: 1, size: 50 },
    count: 0
}

const paginationSlice = createSlice({
    name: "simplePagination",
    initialState,
    reducers: {
        setSimplePagination: (state, action) => {
            state.pagination = { ...action.payload, size: 50 };
        },
        setPageCount: (state, action) => {
            state.count = action.payload;
        }
    }
});

export const { setSimplePagination, setPageCount } = paginationSlice.actions
export const selectSimplePagination = ({ pagination }) => pagination.pagination;
export const selectCount = ({ pagination }) => pagination.count;
export default paginationSlice.reducer;
