import Box from '@mui/system/Box';
import { StatusBadge } from 'app/shared-components/StatusBadge';
import { Avatar, Button, Skeleton, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getProjectStatus,
  getProject,
  selectProject,
} from '../store/projectSlice';
import { useSelector } from 'react-redux';
import SystemDialog from 'app/shared-components/SystemDialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/shared-components/ShowMessage';
import { format } from 'date-fns';

const ProjectForm = (props) => {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const data = useSelector(selectProject);
  const navigate = useNavigate();

  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getProjectStatus());
    dispatch(getProject(routeParams.id)).then(({ payload }) => {
      setLoading(false);
      if (payload) {
        setProjectData({
          name: payload.name,
          abbreviation: payload.abbreviation,
          status: payload.status.name,
          startedAt: payload.startDate,
          description: payload.description || '',
        });
      }
    });
  }, [loading, routeParams.id]);

  return (
    <>
      <Box
        className="relative w-full h-128 sm:h-128 px-32 sm:px-48"
        sx={{
          backgroundColor: 'background.default',
        }}
      ></Box>
      <div className="relative flex flex-col flex-auto items-center px-24 sm:px-48">
        <div className="w-full">
          <div className="flex flex-auto items-end -mt-80 mb-[6rem]">
            <Box
              sx={{
                borderWidth: 4,
                borderStyle: 'solid',
                borderColor: 'background.paper',
              }}
              className="relative flex items-center justify-center  w-160 h-160 rounded-full overflow-hidden"
            >
              <Avatar
                sx={{
                  backgroundColor: 'background.default',
                  color: 'text.secondary',
                }}
                className="object-cover w-full h-full text-64 font-bold"
                src="/assets/images/logo/tambora_logo.png"
              />
            </Box>
          </div>

          <div className="mb-[3.5rem] px-[1rem]">
            <Typography className="text-2xl font-semibold leading-tight mb-4">
              Project Name
            </Typography>
            <Typography className="text-secondary text-xl mb-1">
              {loading ? (
                <Skeleton variant="rounded" width={210} />
              ) : (
                data?.name
              )}
            </Typography>
          </div>

          <div className="mb-[3.5rem] px-[1rem]">
            <Typography className="text-2xl font-semibold leading-tight mb-4">
              Abbreviation
            </Typography>
            <Typography className="text-secondary text-xl mb-1">
              {loading ? (
                <Skeleton variant="rounded" width={210} />
              ) : (
                data?.abbreviation
              )}
            </Typography>
          </div>

          <div className="mb-[3.5rem] px-[1rem]">
            <Typography className="text-2xl font-semibold leading-tight mb-4">
              Status
            </Typography>
            <Typography className="text-secondary text-xl mb-1">
              {loading ? (
                <Skeleton variant="rounded" width={210} height={20} />
              ) : (
                <StatusBadge
                  key="{item.status}"
                  status={data?.status}
                ></StatusBadge>
              )}
            </Typography>
          </div>

          <div className="mb-[3.5rem] px-[1rem]">
            <Typography className="text-2xl font-semibold leading-tight mb-4">
              Started at
            </Typography>
            <Typography className="text-secondary text-xl mb-1">
              {loading ? (
                <Skeleton variant="rounded" width={210} height={20} />
              ) : (
                format(new Date(data?.start_date), 'MMMM dd, yyyy')
              )}
            </Typography>
          </div>

          <div className="mb-[2rem] px-[1rem]">
            <Typography className="text-2xl font-semibold leading-tight mb-4">
              About
            </Typography>
            <Typography className="text-secondary text-xl mb-1">
              {loading ? (
                <Skeleton variant="rounded" width={310} height={60} />
              ) : (
                data?.description || 'Not description'
              )}
            </Typography>
          </div>
        </div>
      </div>

      <Box
        className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t"
        sx={{ backgroundColor: 'background.default' }}
      >
        <Button className="ml-auto" component={NavLinkAdapter} to={-1}>
          Cancel
        </Button>
        <Button
          className="ml-8"
          variant="contained"
          color="secondary"
          component={NavLinkAdapter}
          to={`edit`}
        >
          Edit
        </Button>
      </Box>
    </>
  );
};

export default ProjectForm;
