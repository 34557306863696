
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwtService from 'src/app/auth/services/jwtService';

export const createDocument = createAsyncThunk(
    'documentApp/createDocument',
    async (data, { dispatch }) => {
        const response = await jwtService.POST(`/document`, data, dispatch)
        return response;
    }
)

export const updateDocument = createAsyncThunk(
    'documentApp/getDocument',
    async (data, { dispatch }) => {
        const response = await jwtService.PUT(`document/${data.id}`, data, dispatch)
        return response;
    }
)

export const getDocuments = createAsyncThunk(
    'documentApp/getDocuments',
    async (projectKey, { dispatch }) => {
        const response = await jwtService.GET(`project/${projectKey}/documents`, dispatch)
        return response;
    }
)

export const getDocument = createAsyncThunk(
    'documentApp/getDocuments',
    async (projectKey, { dispatch }) => {
        const response = await jwtService.GET(`project/${projectKey}/documents`, dispatch)
        return response;
    }
)

const documentsSlice = createSlice({
    name: 'documentApp',
    initialState: {
        documents: [],
        document: {}
    },
    reducers: {
        setCurrentDocument: (state, action) => {
            state.document = action.payload;
        },
        clearDocument: (state, action) => {
            state.document = {};
        },
    },
    extraReducers: {
        [getDocuments.fulfilled]: (state, action) => {
            state.documents = action.payload.data
        }
    },
});

export const selectDocuments = ({ documentApp }) => documentApp.documentsSlice.documents;
export const selectDocument = ({ documentApp }) => documentApp.documentsSlice.document;
export const {
    setCurrentDocument,
    clearDocument } = documentsSlice.actions;
export default documentsSlice.reducer;