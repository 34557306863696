import React from "react";
import Box from "@mui/system/Box";
import { Controller, useFormContext } from "react-hook-form";
import AvatarUploader from "app/shared-components/avatar-uploader/AvatarUploader";
import { Autocomplete, Card, TextField } from "@mui/material";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectProjectStatus } from "../store/projectSlice";

const ProjectContent = () => {
  const methods = useFormContext();
  const status = useSelector(selectProjectStatus) || [];

  const {
    formState: { errors },
    control,
  } = methods;

  return (
    <motion.div
      className="mx-32 my-24 w-1/2"
      initial={{ x: 20, opacity: 0 }}
      animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
    >
      <Card>
        <Box
          className="relative w-full h-128 sm:h-128 opacity-20 px-32 sm:px-48"
          sx={{
            backgroundColor: "background.primary",
            opacity: 0.5,
          }}
        ></Box>
        <div className="relative flex gap-20 flex-col flex-auto items-center py-32 px-24 ">
          <div className="w-full">
            <div className="flex flex-auto items-end -mt-120">
              <Controller
                control={control}
                name="logo"
                render={({ field }) => (
                  <AvatarUploader
                    defaultImage="./assets/images/logo/tambora-empty-logo.png"
                    variant="rounded"
                    src={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </div>

          <Controller
            control={control}
            name="name"
            defaultValue={""}
            render={({ field }) => (
              <TextField
                size="small"
                className="mt-32"
                {...field}
                label="Name"
                placeholder="Name"
                id="name"
                error={!!errors.name}
                helperText={errors?.name?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />

          <Controller
            control={control}
            name="abbreviation"
            defaultValue={""}
            render={({ field }) => (
              <TextField
                size="small"
                {...field}
                label="Abbreviation"
                placeholder="Abbreviation"
                id="abbreviation"
                error={!!errors.abbreviation}
                helperText={errors?.abbreviation?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />

          <Controller
            control={control}
            name="status"
            defaultValue={""}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                size="small"
                data-test="combo-box-demo"
                disablePortal
                className=" w-full"
                options={status}
                onChange={(event, values) => onChange(values)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                value={value || null}
                id="combo-box-demo"
                renderInput={(params) => (
                  <TextField
                    onChange={onChange}
                    {...params}
                    label="Status"
                    error={!!errors.status}
                    required
                    helperText={errors?.status?.message}
                  />
                )}
              />
            )}
          />

          <Controller
            control={control}
            name="description"
            defaultValue={""}
            render={({ field }) => (
              <TextField
                size="small"
                {...field}
                label="About"
                placeholder="About"
                id="description"
                error={!!errors.notes}
                helperText={errors?.notes?.message}
                variant="outlined"
                fullWidth
                multiline
                minRows={8}
                maxRows={10}
              />
            )}
          />
        </div>
      </Card>
    </motion.div>
  );
};

export default ProjectContent;
