import { Button, Paper, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useForm, useFormContext } from "react-hook-form";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { useTheme } from "@mui/material/styles";
import { createUser, updateUser } from "../store/userSlice";
import { useDispatch } from "react-redux";
import { showMessage } from "app/shared-components/ShowMessage";
import Dialog from "@mui/material/Dialog";

import { closeDialog, openDialog } from "app/store/fuse/dialogSlice";
import SystemDialog from "app/shared-components/SystemDialog";
import { update } from "lodash";
import { create } from "lodash";
import ConfirmDialog from "app/shared-components/ConfirmDialog";

const UserHeader = (props) => {
  const methods = useFormContext();
  const dispatch = useDispatch();
  const { formState, watch, getValues, trigger } = methods;
  const navigate = useNavigate();
  const firstName = watch("first_name");
  const lastName = watch("last_name");
  const displayName = `${firstName} ${lastName}`;
  const theme = useTheme();
  const { userId } = props;

  const handleSaveProduct = async () => {
    const isValid = await trigger();
    if (isValid) {
      props.userId ? update() : create();
    }
  };

  const update = () => {
    dispatch(updateUser({ userId, userData: getValues() })).then((res) => {
      if (res.payload.status == "success") {
        dispatch(
          showMessage({
            message: "User updated successfully.",
            variant: "success",
          })
        );
        navigate("/system-settings/users");
      } else {
        dispatch(
          openDialog({
            children: (
              <ConfirmDialog
                status="error"
                cancelButtonLabel="Ok"
                message={response.payload.message}
              />
            ),
          })
        );
      }
    });
  };

  const create = () => {
    dispatch(createUser(getValues())).then((res) => {
      if (res.payload.status == "success") {
        dispatch(
          showMessage({
            message: res.payload.message,
            variant: "success",
          })
        );
        navigate("/system-settings/users");
      }
    });
  };

  const handleError = (message) => {
    dispatch(
      openDialog({
        children: <SystemDialog message={message} />,
      })
    );
  };

  return (
    <div className="  flex flex-col sm:flex-row flex-1 w-full items-center justify-between space-y-8 sm:space-y-0 py-32 px-24 md:px-32">
      <div className="flex flex-col items-center sm:items-start space-y-8 sm:space-y-0 w-full sm:max-w-full min-w-0 ">
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
        >
          <Typography
            className="flex items-center sm:mb-12"
            component={Link}
            role="button"
            to="/system-settings/users"
            color="secondary"
          >
            <FuseSvgIcon size={20}>
              {theme.direction === "ltr"
                ? "heroicons-outline:arrow-sm-left"
                : "heroicons-outline:arrow-sm-right"}
            </FuseSvgIcon>
            <span className="  flex mx-4 font-medium ">Users</span>
          </Typography>
        </motion.div>
        <div className="flex items-center max-w-full">
          <motion.div
            className="flex flex-col items-center sm:items-start min-w-0 mx-8 sm:mx-16 "
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.3 } }}
          >
            <Typography className="text-16 sm:text-20 truncate font-semibold">
              {props.userId ? displayName : "New User"}
            </Typography>
            <Typography variant="caption" className="font-medium  ">
              User Detail
            </Typography>
          </motion.div>
        </div>
      </div>
      <motion.div
        className="flex"
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
      >
        <Button
          className="whitespace-nowrap mx-4"
          variant="contained"
          color="secondary"
          // disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSaveProduct}
        >
          Save
        </Button>
      </motion.div>
    </div>
  );
};

export default UserHeader;
