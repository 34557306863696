import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import jwtService from '../../auth/services/jwtService';
import { Card, Typography } from '@mui/material';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required('You must enter an email'),
  password: yup
    .string()
    .required('Please enter your password.')
    .min(8, 'Password is too short - must be at least 8 chars.'),
});

const defaultValues = {
  email: '',
  password: '',
  remember: true,
};

function SignInPage() {
  const [isLoading, seLoading] = useState(false);
  const [alertMessage, setMessage] = useState({});
  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState

  useEffect(() => {
    function showActiveUserAlert() {
      const queryString = window.location.search
      const params = new URLSearchParams(queryString)
      const isActiveUser = params.get('active_user')
      if (isActiveUser == 'true') {
        setMessage({ type: 'success', message: 'Your account has been successfully activated.', title: 'Success' });
      }
    }

    function showResetPasswordAlert() {
      const queryString = window.location.search
      const params = new URLSearchParams(queryString)
      const isActiveUser = params.get('reset_password')
      if (isActiveUser == 'true') {
        setMessage({ type: 'success', message: 'Your password has been successfully updated.', title: 'Success' });
      }
    }

    showActiveUserAlert()
    showResetPasswordAlert()
  }, []);

  function onSubmit({ email, password }) {
    seLoading(true);
    jwtService
      .signInWithEmailAndPassword(email.toLowerCase(), password)
      .then((user) => {
        seLoading(false);
        // No need to do anything, user data will be set at app/auth/AuthContext
      })
      .catch((error) => {
        seLoading(false);
        setMessage({ type: 'error', message: "Email or Password invalid.", title: 'Error' });
        error.forEach((error) => {
          setError(error.type, {
            type: 'manual',
            message: error.message,
          });
        });
      });
  }

  function renderAlertMessage({ type = 'info', title, message }) {
    return (
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity={type} onClose={() => {
          setMessage({})
          setError('email', { message: '' })
        }}>
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Stack>
    )
  }

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:justify-end w-full sm:w-[380px] md:h-full py-32 px-16 sm:p-48 md:p-64 md:pt-96 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">

        <Card className='absolute top-160 left-[15%] z-9999 py-96  px-36'>
          <div className="w-full mx-auto max-w-320 sm:w-320 sm:mx-0">
            {!isEmpty(alertMessage) && renderAlertMessage(alertMessage)}
            <div className="flex flex-row w-full align-middle vert justify-left">
              <div className="mt-3 ml-4 text-6xl text-dark-green font-extrabold leading-tight tracking-tight">Sign in</div>
            </div>

            <form
              name="loginForm"
              noValidate
              className="flex flex-col justify-center w-full mt-32"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Email"
                    autoFocus
                    type="email"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Password"
                    type="password"
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />

              <div className="flex flex-col items-center justify-center sm:flex-row sm:justify-between">
                {/* <Controller
                  name="remember"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <FormControlLabel
                        label="Remember me"
                        control={<Checkbox size="small" {...field} />}
                      />
                    </FormControl>
                  )}
                /> */}

                <Link className="font-medium text-md" to="/forgot-password">
                  Forgot password?
                </Link>
              </div>

              <LoadingButton
                variant="contained"
                color="secondary"
                className="w-full mt-36 "
                aria-label="Sign in"
                // disabled={_.isEmpty(dirtyFields) || !isValid}
                type="submit"
                size="large"
                loading={isLoading}
              >
                Sign in
              </LoadingButton>
            </form>
          </div>
        </Card>

      </Paper>

      <Box
        className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
        sx={{ backgroundColor: '#00343d' }}
      >
        <svg
          className="absolute inset-0 pointer-events-none"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: 'primary.light' }}
            className="opacity-20"
            fill="none"
            stroke="#0bd8a2"
            strokeWidth="100"
          >

          </Box>
        </svg>
        <Box
          component="svg"
          className="absolute -top-64 -right-64 opacity-20"
          sx={{ color: 'primary.light' }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
        </Box>

        <div className="relative z-10 w-full max-w-4xl">
          <div className="font-bold leading-none text-gray-100 text-7xl">
            <img src="assets/images/logo/tambora_login.png" />
          </div>
          <div className="mt-24 text-lg leading-6 tracking-tight text-gray-400">
          </div>
        </div>
      </Box>



    </div>
  );
}

export default SignInPage;
