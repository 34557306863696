
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwtService from 'src/app/auth/services/jwtService';
import axios from 'axios';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';

export const getIterators = createAsyncThunk(
  'testsApp/getIterators',
  async (projectId) => {
    const response = await jwtService.getProjectIterators(projectId)
    const data = response;
    return {
      iterators: data.result

    };
  }
)

export const getAutomationStatus = createAsyncThunk('testsApp/getAutomationStatus', async () => {
  const response = await jwtService.getTypes('automation_status');
  return response.data;
});

export const getTestResults = createAsyncThunk(
  'testsApp/getTestResults',
  async (testId, { dispatch }) => {
    const response = await jwtService.GET(`testcase/${testId}/results`, dispatch)
    return response;
  }
)

export const getTestById = createAsyncThunk('testsApp/getTestById', async (testId) => {
  return await jwtService.GET(`testcase/${testId}`);
})

export const getTestTags = createAsyncThunk(
  'testsApp/getTags',
  async (projectId, { dispatch }) => {
    const response = await jwtService.GET(`tag?project_id=${projectId}`, dispatch)
    return response.data;
  }
)

export const getPriorityType = createAsyncThunk('testsApp/getPriorityType', async () => {
  const response = await jwtService.getTypes('priority_type');
  return response.data;
});

export const getTestcaseTypes = createAsyncThunk('testsApp/getTestcaseTypes', async () => {
  const response = await jwtService.getTypes('testcases_type');
  return response.data;
});


export const createTest = createAsyncThunk(
  'testsApp/createTest',
  async (testdata, { rejectWithValue }) => {
    try {
      const response = await axios
        .post(`${jwtServiceConfig.tests}`, testdata)
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }

  }
)


export const updateTest = createAsyncThunk(
  'testsApp/updateTest',
  async (testData, { rejectWithValue }) => {
    const { id } = testData
    const response = await jwtService.changeTestData(testData, id)
    const data = response;
    return data;
  }
)

const testSlice = createSlice({
  name: 'testApp',
  initialState: {
    iterators: [],
    automationStatus: [],
    selectedTest: null,
    testResults: [],
    testTags: [],
    testcaseTypes: [],
    priorityTypes: []
  },
  reducers: {
    resetForm: (state, action) => {
      state.testResults = [];
    },
    refreshTestTags: (state, action) => {
      const uniqueSet = new Set([...state.testTags, ...action.payload]);
      state.testTags = Array.from(uniqueSet);
    },
  },
  extraReducers: {
    [getIterators.fulfilled]: (state, action) => {
      state.iterators = mapIterators(action.payload)
    },
    [getAutomationStatus.fulfilled]: (state, action) => {
      state.automationStatus = action.payload
    },
    [getTestById.fulfilled]: (state, action) => {
      state.selectedTest = action.payload
    },
    [getTestResults.fulfilled]: (state, action) => {
      state.testResults = action.payload.data
    },
    [getTestcaseTypes.fulfilled]: (state, action) => {
      state.testcaseTypes = action.payload
    },
    [getPriorityType.fulfilled]: (state, action) => {
      state.priorityTypes = action.payload
    },
    [getTestTags.fulfilled]: (state, action) => {
      state.testTags = action.payload
    },
    [createTest.fulfilled]: (state, action) => { },
    [updateTest.fulfilled]: (state, action) => { },
  }
});

const mapIterators = (data) => {
  const obj = data.iterators.map((item) => {
    return {
      ...item,
      disabled: false
    }
  })
  return obj
}

export const selectAutomationStatus = ({ suitesApp }) => suitesApp.test.automationStatus;
export const selectTestResults = ({ suitesApp }) => suitesApp.test.testResults;

export const getSelectedTest = ({suitesApp}) => suitesApp.test.selectedTest;
export const selectTestCaseTypes = ({ suitesApp }) => suitesApp.test.testcaseTypes;
export const selectPriorityTypes = ({ suitesApp }) => suitesApp.test.priorityTypes;
export const selectSplitters = ({ suitesApp }) => suitesApp.test.iterators;
export const selectTestTags = ({ suitesApp }) => suitesApp.test.testTags;
export const { resetForm, refreshTestTags } = testSlice.actions;
export default testSlice.reducer;