import { Component, forwardRef, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  convertToRaw, EditorState, BlockMapBuilder, ContentState, RichUtils, convertFromHTML, Modifier
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import clsx from 'clsx';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';

const Root = styled('div')({
  '& .rdw-dropdown-selectedtext': {
    color: 'inherit',
  },
  '& .rdw-editor-toolbar': {
    borderWidth: '0 0 1px 0!important',
    margin: '0!important',
  },
  '& .rdw-editor-main': {
    paddingLeft: '8px',
    paddingRigth: '8px',
    maxHeight: `${256}px !important`,

  },
});

class CustomOption extends Component {


  addStar = () => {
    const { editorState, onChange } = this.props;
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const currentStyle = editorState.getCurrentInlineStyle();

    const textToInsert =
      `<p><span style="font-weight:bold">Given </span><span">- &#160; </span></p>
       <p><span style="font-weight:500">When </span><span"> - </span></p>
       <p><span style="font-weight:500">And </span><span"> - </span></p>
       <p><span style="font-weight:500">Then </span><span"> - </span></p>`;

    const htmlContent = convertFromHTML(textToInsert);
    const htmlContentMap = BlockMapBuilder.createFromArray(
      htmlContent.contentBlocks
    );

    const newContentState = Modifier.replaceWithFragment(
      contentState,
      selection,
      htmlContentMap)

    onChange(EditorState.push(editorState, newContentState, 'insert-characters'))
  };


  render() {
    return (
      <div onClick={this.addStar} className="rdw-option-wrapper  font-semibold">BDD</div>
    );
  }
}

class StepsOption extends Component {


  addSteps = () => {
    const { editorState, onChange } = this.props;
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const currentStyle = editorState.getCurrentInlineStyle();
    // const [isBoldSelected, setIsBoldSelected] = useState(false);

    const textToInsert =
      `<p><span style="font-weight:bold">Steps: </span></p>
      <p>
      <ol>
       <li><span style="font-weight:normal"> -</span></li>
       </ol>
      </p>
       `;

    const htmlContent = convertFromHTML(textToInsert);
    const htmlContentMap = BlockMapBuilder.createFromArray(
      htmlContent.contentBlocks
    );

    const newContentState = Modifier.replaceWithFragment(
      contentState,
      selection,
      htmlContentMap)

    onChange(EditorState.push(editorState, newContentState, 'insert-characters'))
  };


  render() {
    return (
      <div onClick={this.addSteps} className="rdw-option-wrapper font-semibold">Steps</div>
    );
  }
}



const WYSIWYGEditor = forwardRef((props, ref) => {

  let { onChange, defaultValue, className, value, placeHolder = "Enter Description" } = props;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isBoldSelected, setIsBoldSelected] = useState(false);
  const [selection, setSelection] = useState(null);

  // const content = ContentState.createFromBlockArray(convertFromHTML(props.defaultValue))
  // const [editorState, setEditorState] = useState(EditorState.createWithContent(content));

  // var contentState = editorState.getCurrentContent();
  // if (!contentState.hasText()) {
  //   if (contentState.getBlockMap().first().getType() !== 'unstyled') {
  //     className += ' RichEditor-hidePlaceholder';
  //   }
  // }
  className += ` ${props.height}`;

  useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        const blocksFromHTML = convertFromHTML(value?.htmlValue || value);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        setEditorState(EditorState.createWithContent(state));
      } else {
        setEditorState(value.editorValue);
      }
    }
  }, [value])


  function onEditorStateChange(_editorState) {
    setEditorState(_editorState);
    return onChange({
      htmlValue: draftToHtml(convertToRaw(_editorState.getCurrentContent())),
      editorValue: _editorState
    })
    return onChange(draftToHtml(convertToRaw(_editorState.getCurrentContent())));
  }

  const handleOnFocusEditor = (event) => {
    // console.log('event.target', event.target)
  }

  return (
    <Root className={clsx('rounded-4  border-1 w-full', className)} ref={ref}>
      <Editor
        placeholder={placeHolder}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}


        toolbarCustomButtons={props.customControls && [<CustomOption />]}
        toolbar={{
          options: ['inline', 'list', 'remove', 'link'],
          inline: {
            options: ['bold', 'italic', 'underline'],
            bold: { className: 'custom-option-class', selected: true }
          },
          list: { inDropdown: false },
          link: {
            popupClassName: "popup-link-editor",
            showOpenOptionOnHover: true,
            defaultTargetOption: "_blank",
            options: ['link', 'unlink']
          }
        }} />
    </Root>
  );
});

export default WYSIWYGEditor;
