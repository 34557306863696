import { createSlice } from '@reduxjs/toolkit';

const dialogSlice = createSlice({
  name: 'dialog',
  initialState: {
    state: false,
    loading: false,
    options: {
    },
  },
  reducers: {
    openDialog: (state, action) => {
      state.state = true;
      state.options = action.payload;
    },
    closeDialog: (state, action) => {
      state.state = false;
    },
    setDialogLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { openDialog, closeDialog, setDialogLoading } = dialogSlice.actions;

export const selectFuseDialogState = ({ fuse }) => fuse.dialog.state;
export const selectDialogLoading = ({ fuse }) => fuse.dialog.loading;


export const selectFuseDialogOptions = ({ fuse }) => fuse.dialog.options;

export default dialogSlice.reducer;
