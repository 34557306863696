import { Skeleton } from "@mui/material";
import React from "react";
import AiIcon from "./AiIcon";

const MessageRowSkeleton = () => {
  return (
    <div className="flex p-10 pb-72 content-start">
      <div>
        <AiIcon size="26" />
      </div>

      <div className="flex flex-col gap-5 w-full px-12">
        <Skeleton animation="wave"></Skeleton>
        <Skeleton className=" w-10/12" animation="wave"></Skeleton>
        <Skeleton className=" w-8/12" animation="wave"></Skeleton>
      </div>
    </div>
  );
};

export default MessageRowSkeleton;
