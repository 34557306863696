import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { memo, useEffect, useState } from "react";
import format from "date-fns/format";
import clsx from "clsx";
import { styled } from "@mui/material/styles";
import { values } from "lodash";
import { StatusBadge } from "app/shared-components/StatusBadge";
import {
  Pagination,
  Skeleton,
  TableFooter,
  TablePagination,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  getExecutedTestCases,
  selectExecutedTestCases,
  selectExecutedTestPaginate,
} from "../../store/testRunProcessSlice";
import FuseUtils from "@fuse/utils/FuseUtils";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useSimplePagination from "src/app/utils/hooks/paginationHook";
import {
  selectSimplePagination,
  setSimplePagination,
} from "app/store/paginationSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    borderBottom: "1px solid #e0e0e0",
    padding: 5,
  },
}));

function ExecutedTestCasesWidget(props) {
  const columns = ["Code", "Summary", "Status"];
  const testCases = useSelector(selectExecutedTestCases);
  const paginate = useSelector(selectExecutedTestPaginate);
  const { loading } = props;
  const dispatch = useDispatch();
  const { testRunKey } = useParams();
  const simplePagination = useSelector(selectSimplePagination);
  const [pagination, setPagination] = useState(simplePagination);

  const onPageChange = (e, page) => {
    setPagination({ page });
  };

  useEffect(() => {
    dispatch(getExecutedTestCases({ testRunKey, pagination }));
  }, [pagination]);

  useEffect(() => {
    return () => {
      setSimplePagination({ page: 1 });
    };
  }, []);

  if (loading)
    return (
      <Paper className="col-span-2 md:grid-cols-5 lg:col-span-6 p-24 shadow rounded-2xl overflow-hidden">
        <Skeleton className=" w-120 h-40 mb-14"></Skeleton>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
          <Skeleton key={item} className="w-full h-40"></Skeleton>
        ))}
      </Paper>
    );

  return (
    <Paper className="col-span-2 md:grid-cols-5 lg:col-span-6 p-24 shadow rounded-2xl overflow-hidden">
      <div className="">
        <Typography className="mr-16 text-lg font-medium tracking-tight leading-6 truncate">
          Test Cases
        </Typography>
      </div>

      <div className="table-responsive mt-14">
        <Table className="w-full min-w-full">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <StyledTableCell key={index}>
                  <Typography
                    color="text.secondary"
                    className="font-semibold pl-0 text-12 whitespace-nowrap"
                  >
                    {column}
                  </Typography>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          {testCases.length == 0 ? (
            <Typography className="mt-10">No Test cases found.</Typography>
          ) : (
            <>
              <TableBody>
                {testCases.map((row, index) => (
                  <TableRow key={index}>
                    {Object.entries(row).map(([key, value]) => {
                      switch (key) {
                        case "code": {
                          return (
                            <StyledTableCell
                              key={key}
                              component="th"
                              scope="row"
                            >
                              <Typography
                                className=" whitespace-nowrap "
                                color="text.secondary"
                              >
                                {value}
                              </Typography>
                            </StyledTableCell>
                          );
                        }
                        case "summary": {
                          return (
                            <StyledTableCell
                              key={key}
                              component="th"
                              scope="row"
                            >
                              <Typography className="">{value}</Typography>
                            </StyledTableCell>
                          );
                        }
                        case "status": {
                          return (
                            <StyledTableCell
                              key={key}
                              // align="right"
                              component="th"
                              scope="row"
                            >
                              <StatusBadge status={value} />
                            </StyledTableCell>
                          );
                        }
                      }
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>

        {testCases.length != 0 && (
          <Pagination
            size="small"
            color="secondary"
            className="mt-20 flex justify-end w-full"
            count={paginate?.total_pages}
            onChange={onPageChange}
            defaultPage={pagination?.current_page}
          />
        )}
      </div>
    </Paper>
  );
}

export default memo(ExecutedTestCasesWidget);
