import {
  Card,
  CardActions,
  CardContent,
  Skeleton,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import Avatar from "@mui/material/Avatar";
import * as React from "react";
import { StatusBadge } from "app/shared-components/StatusBadge";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProject, selectProject } from "../../store/projectSlice";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import FuseLoading from "@fuse/core/FuseLoading";

const BasicInfoTab = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { projectId } = params;
  const data = useSelector(selectProject);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getProject(projectId)).then(() => setLoading(false));
  }, []);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 },
  };

  const [status, setStatus] = useState({ color: "red", name: "Closed" });

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="w-full flex gap-20"
    >
      <Card
        component={motion.div}
        variants={item}
        className="flex-auto w-full mb-32"
      >
        <div className="px-32 pt-24">
          <Typography className="text-2xl font-semibold leading-tight">
            General Information
          </Typography>
        </div>

        <CardContent className="px-32 py-24">
          <div className="mb-24">
            <Typography className="font-semibold mb-4 text-15">
              Project Name
            </Typography>
            {loading ? (
              <Skeleton variant="rounded" width={210} height={20} />
            ) : (
              <Typography>{data?.name}</Typography>
            )}
          </div>

          <div className="mb-24">
            <Typography className="font-semibold mb-4 text-15">
              Abbrevation
            </Typography>
            {loading ? (
              <Skeleton variant="rounded" width={210} height={20} />
            ) : (
              <Typography className=" uppercase">
                {data?.abbreviation}
              </Typography>
            )}
          </div>

          <div className="mb-24">
            <Typography className="font-semibold mb-4 text-15">
              Status
            </Typography>
            {loading ? (
              <Skeleton variant="rounded" width={210} height={20} />
            ) : (
              <StatusBadge
                key="{item.status}"
                status={data?.status}
              ></StatusBadge>
            )}
          </div>
          <div className="mb-24">
            <Typography className="font-semibold mb-4 text-15">
              Started at
            </Typography>
            {loading ? (
              <Skeleton variant="rounded" width={210} height={20} />
            ) : (
              <Typography>
                {format(new Date(data?.start_date), "MMMM dd, yyyy")}
              </Typography>
            )}
          </div>

          <div className="mb-24">
            <Typography className="font-semibold mb-4 text-15">
              About
            </Typography>
            {loading ? (
              <Skeleton variant="rounded" width={310} height={60} />
            ) : (
              <Typography>{data?.description || "Not description"}</Typography>
            )}
          </div>
        </CardContent>
      </Card>

      <div className="flex flex-col md:w-400">
        <Card component={motion.div} variants={item} className="w-full mb-32">
          <div className="flex px-32 pt-24">
            <Typography className="flex flex-1 text-2xl font-semibold leading-tight">
              Logo
            </Typography>
          </div>
          <div className="flex py-20 justify-center  flex-auto items-end">
            <Avatar
              alt="E"
              src={
                data?.logo
                  ? data.logo
                  : `/assets/images/logo/tambora-empty-logo.png`
              }
              className=" w-160  h-160"
            ></Avatar>
          </div>
        </Card>
      </div>
    </motion.div>
  );
};

export default BasicInfoTab;
