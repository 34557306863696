import FusePageSimple from "@fuse/core/FusePageSimple";
import Tab from "@mui/material/Tab";
import withReducer from "app/store/withReducer";
import _ from "@lodash";
import { useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import reducer from "./store";
import TabPanel from "@mui/lab/TabPanel";
import ProjectSettingsHeader from "./ProjectSettingsHeader";
import TeamTab from "./tabs/TeamTab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import GroupOutlined from "@mui/icons-material/GroupOutlined";
import BasicInfoTab from "./tabs/BasicInfoTab";
import { SplitterTab } from "./tabs/SplitterTab/SplitterTab";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
  },
  "& .FusePageSimple-toolbar": {},
  "& .FusePageSimple-content": {},
  "& .FusePageSimple-sidebarHeader": {},
  "& .FusePageSimple-sidebarContent": {},
}));

function ProjectSettingsApp() {
  const [value, setValue] = useState("0");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Root
      header={<ProjectSettingsHeader />}
      content={
        <Box sx={{ display: "flex" }} className="h-full w-full">
          <TabContext value={value}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              orientation="vertical"
              variant="scrollable"
              textColor="secondary"
              indicatorColor="secondary"
              className="w-136 min-w-136"
              scrollButtons={false}
              sx={{ borderRight: 1, borderBottom: 1, borderColor: "divider" }}
            >
              <Tab
                icon={<InfoOutlinedIcon sx={{ fontSize: 45 }} />}
                value="0"
                label="Basic Info"
                className="w-full my-20  h-80 text-18"
              ></Tab>

              <Tab
                icon={<GroupOutlined sx={{ fontSize: 45 }} />}
                value="1"
                className="w-full my-20 h-70 text-14"
                label="Team"
              />
              <Tab
                icon={<AccountTreeOutlinedIcon sx={{ fontSize: 45 }} />}
                value="2"
                className="w-full my-20 h-70 text-14"
                label="Splitters"
              />
            </TabList>
            <TabPanel className="w-full" value="0">
              <BasicInfoTab />
            </TabPanel>
            <TabPanel className="w-full" value="1">
              <TeamTab />
            </TabPanel>
            <TabPanel className="w-full" value="2">
              <SplitterTab />
            </TabPanel>
          </TabContext>
        </Box>
      }
    />
  );
}

export default withReducer("projectSettingsApp", reducer)(ProjectSettingsApp);
