import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { showMessage } from 'app/shared-components/ShowMessage';
import { handleBlobImages } from 'src/app/utils';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';
import jwtService from 'src/app/auth/services/jwtService';
import { openDialog } from 'app/store/fuse/dialogSlice';
import SystemDialog from 'app/shared-components/SystemDialog';
import SystemErrorDialog from 'app/shared-components/SystemErrorDialog';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getProjectStatus = createAsyncThunk(
  'projectsApp/getProjectStatus/',
  async () => {
    const response = await jwtService.getTypes('project_status');
    return response.data;
  }
);

export const getProject = createAsyncThunk(
  'projectsApp/getProject',
  async (projectId, { rejectWithValue, dispatch }) => {
    try {
      const response = await new Promise((resolve, reject) => {
        axios
          .get(`${jwtServiceConfig.projects}/${projectId}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createNewProject = createAsyncThunk(
  'projectsApp/createNewProject',
  async (productData, { rejectWithValue, dispatch }) => {
    const { name, abbreviation, status, description, logo } = productData;

    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const formData = new FormData();

      formData.append('name', name);
      formData.append('abbreviation', abbreviation);
      formData.append('status_id', status.id);
      formData.append('description', description);
      // formData.append('project[tag_list]', tag_list);
      formData.append('logo', logo);
      const response = await new Promise((resolve, reject) => {
        axios
          .post(`/projects`, formData, headers)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });

      return response;
    } catch (error) {
      dispatch(
        openDialog({
          children: (
            <SystemErrorDialog response={error.response}></SystemErrorDialog>
          ),
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const editProject = createAsyncThunk(
  'projectsApp/editProject',
  async (projectData, { dispatch, getState, rejectWithValue }) => {
    const {
      name,
      abbreviation,
      status,
      description,
      logo,
      projectId,
    } = projectData;

    // const imageLoaded = await handleBlobImages(logoUrl, name);

    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const formData = new FormData();
      // const isNewImage = typeof imageLoaded === 'object';

      formData.append('name', name);
      formData.append('abbreviation', abbreviation);
      formData.append('status_id', status.id);
      formData.append('description', description);
      formData.append('members', ''); // Required and not needed for Projects Schema
      formData.append('logo', logo);

      const response = await new Promise((resolve, reject) => {
        axios
          .put(`/projects/${projectId}`, formData, headers)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });

      return response;
    } catch (error) {

      dispatch(
        openDialog({
          children: (
            <SystemErrorDialog response={error.response}></SystemErrorDialog>
          ),
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const selectProjects = (state) => {
  const { projectList, isLoading } = state.projects.project;
  return {
    projectList,
    isLoading,
  };
};

const setLogoPath = (data) => {
  return {
    ...data,
    logo: !isEmpty(data.logo) ? `${baseUrl}${data.logo}` : data.logo,
  };
};

const projectSlice = createSlice({
  name: 'projectApp',
  initialState: {
    isLoading: false,
    data: {},
    projectStatus: []
  },
  reducers: {
    resetProject: () => {
      payload: {
        isLoading: false;
        data: {
        }
      }
    },
    newProject: {
      reducer: (state, action) => action.payload,
      prepare: (event) => ({
        payload: {
          data: {
            name: '',
            abbreviation: '',
            status: '',
            description: '',
            members: '',
            tag_list: [],
            logo: '',
          },
        },
      }),
    },
  },
  extraReducers: {
    [getProject.pending]: (state, action) => {
      return { ...state, isLoading: true };
    },
    [getProjectStatus.fulfilled]: (state, action) => {
      state.projectStatus = action.payload;
    },
    [getProject.fulfilled]: (state, action) => {
      state.project = action.payload;
      // return {
      //   ...state,
      //   data: setLogoPath(action.payload),
      //   isLoading: false
      // }
    },
    [createNewProject.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [editProject.fulfilled]: (state, action) => {
      return {
        ...state,
        data: setLogoPath(action.payload),
        isLoading: false,
      };
    },
  },
});

export const { resetProject, newProject } = projectSlice.actions;

export const selectProject = ({ projectApp }) => projectApp.project.project;
export const selectProjectStatus = ({ projectApp }) => projectApp.project.projectStatus;

export const selectProjectId = ({ projectApp }) => projectApp.project.data.id;

export default projectSlice.reducer;
