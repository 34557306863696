import Chip from '@mui/material/Chip';
import clsx from 'clsx';
import { darken } from '@mui/material/styles';
import { Typography } from '@mui/material';


export const IteratorLabel = ({ label, onDelete }) => {


  return (
    <Chip
      label={label}
      classes={{
        root: clsx('h-20 border-0'),
        label: 'px-12 py-4 text-12 font-medium leading-none',
        deleteIcon: 'w-16'
      }}
      sx={{
        color: 'text.secondary',
        backgroundColor: (theme) => darken(theme.palette.background.default, 0.03),
      }}
      variant="outlined"
      onDelete={onDelete}
    />
  );
}

