import { showMessage as fuseMessage } from "app/store/fuse/messageSlice";

export const showMessage = ({ message, variant = 'success' }) => {
  return (
    fuseMessage({
      message,
      autoHideDuration: 3000,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      variant
    })
  )
}
