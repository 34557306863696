import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Button,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { closeDialog, openDialog } from "app/store/fuse/dialogSlice";
import { useDispatch } from "react-redux";
import { List } from "immutable";
import { selectFuseMessageOptions } from "app/store/fuse/messageSlice";
import { useState } from "react";
import { formatError } from "../utils";
import { useEffect } from "react";
import { Link } from "@mui/icons-material";

const SystemErrorDialog = ({ response }) => {
  const dispatch = useDispatch();
  const statusCode = response.status;
  const [message, setMessage] = useState(response.data.message);
  const [messages, setMessages] = useState(formatError(message));

  useEffect(() => {
    if (statusCode == 500) {
      setMessages([
        "It's not you, it's definitely us this time! 😅 Our tech gremlins are causing some trouble. Please give it another shot later, and if you need a hand, feel free to reach out. We promise we're working on fixing this! 💪🔧",
      ]);
    }
  }, []);

  return (
    <>
      <DialogTitle
        className="flex flex-row items-center"
        id="alert-dialog-title"
      ></DialogTitle>
      <DialogContent>
        <div className="flex gap-20">
          {statusCode == 500 ? (
            <img
              className="rounded-lg"
              width={150}
              src="assets/images/error_500.png"
              alt="error 500"
              loading="lazy"
            />
          ) : (
            <FuseSvgIcon size={40} className="text-orange-400 mr-20">
              feather:alert-triangle
            </FuseSvgIcon>
          )}

          <div className="">
            <Typography variant="h6">System Notification</Typography>
            {messages.map((msg, index) => (
              <Typography
                className="mt-10 text-16"
                color="text.secondary"
                key={index}
              >
                {msg}
              </Typography>
            ))}
          </div>
        </div>
      </DialogContent>

      <DialogActions className="flex flex-auto text-right px-20 py-20 bg-gray-50 dark:bg-[#111827]">
        <Button
          variant="contained"
          onClick={() => dispatch(closeDialog())}
          color="secondary"
        >
          Ok
        </Button>
      </DialogActions>
    </>
  );
};

export default SystemErrorDialog;
