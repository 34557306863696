import {
  Autocomplete,
  Card,
  CardContent,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Controller,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import WYSIWYGEditor from "app/shared-components/WYSIWYGEditor";
import { useDispatch } from "react-redux";
import mockData from "./data.json";
import { useParams } from "react-router-dom";
import { use } from "i18next";
import { useSelector } from "react-redux";
import { selectDocument } from "../store/documentsSlice";
import parse from "html-react-parser";

const EditDocumentContent = (props) => {
  const { viewMode, documentId } = useParams();
  const [editableForm, setEditableForm] = useState(false);
  const conformances = mockData.conformances;
  const methods = useFormContext();
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "guidelines",
  });

  useEffect(() => {
    setEditableForm(viewMode == "new" || viewMode == "edit");
  }, [viewMode]);

  return (
    <div className=" mx-120 my-24 flex w-full gap-20   ">
      <Card className="w-full">
        <CardContent className="flex flex-col m-20">
          <div className="grid grid-cols-4 gap-4 p-4 items-center">
            {/* Report Title */}
            <div className="font-bold text-16">Report Title</div>
            <div className="col-span-3">
              {editableForm ? (
                <Controller
                  name="report_title"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      inputProps={{
                        style: { lineHeight: "1.3" },
                      }}
                      {...field}
                      error={!!errors.report_title}
                      helperText={errors?.report_title?.message}
                      id="report_title"
                      multiline
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              ) : (
                <Input
                  className="text-16"
                  placeholder="Title"
                  value={getValues("report_title")}
                  type="text"
                  disableUnderline
                  fullWidth
                />
              )}
            </div>

            {/* Name of Product/Version */}
            <div className="font-bold text-16"> Name of Product/Version</div>
            <div className="col-span-3">
              {editableForm ? (
                <Controller
                  name="product_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      inputProps={{
                        style: { lineHeight: "1.3" },
                      }}
                      {...field}
                      error={!!errors.product_name}
                      helperText={errors?.product_name?.message}
                      id="product_name"
                      multiline
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              ) : (
                <Input
                  className="text-16"
                  placeholder="Title"
                  value={getValues("product_name")}
                  type="text"
                  disableUnderline
                  fullWidth
                />
              )}
            </div>

            {/* Report Date */}
            <div className="font-bold text-16">Report Date</div>
            <div className="col-span-3">
              {editableForm ? (
                <Controller
                  name="report_date"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      inputProps={{
                        style: { lineHeight: "1.3" },
                      }}
                      {...field}
                      error={!!errors.report_date}
                      helperText={errors?.report_date?.message}
                      id="report_date"
                      multiline
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              ) : (
                <Input
                  className="text-16"
                  placeholder="Title"
                  value={getValues("report_date")}
                  type="text"
                  disableUnderline
                  fullWidth
                />
              )}
            </div>

            {/* Product Description */}
            <div className="font-bold text-16">Product Description</div>
            <div className="col-span-3">
              {editableForm ? (
                <Controller
                  name="product_description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      inputProps={{
                        style: { lineHeight: "1.3" },
                      }}
                      {...field}
                      error={!!errors.product_description}
                      helperText={errors?.product_description?.message}
                      id="product_description"
                      multiline
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              ) : (
                <Input
                  className="text-16"
                  placeholder="Title"
                  multiline
                  value={getValues("product_description")}
                  type="text"
                  disableUnderline
                  fullWidth
                />
              )}
            </div>

            {/* Contact Information */}
            <div className="font-bold text-16">Contact Information</div>
            <div className="col-span-3">
              {editableForm ? (
                <Controller
                  name="contect_info"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      inputProps={{
                        style: { lineHeight: "1.3" },
                      }}
                      {...field}
                      error={!!errors.contect_info}
                      helperText={errors?.contect_info?.message}
                      id="contect_info"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              ) : (
                <Input
                  className="text-16"
                  placeholder="Title"
                  value={getValues("contect_info")}
                  type="text"
                  disableUnderline
                  fullWidth
                />
              )}
            </div>

            {/* Notes */}
            <div className="font-bold text-16">Notes</div>
            <div className="col-span-3">
              {editableForm ? (
                <Controller
                  className="mt-8 mb-16"
                  render={({ field }) => (
                    <WYSIWYGEditor
                      customControls={true}
                      disabled="false"
                      defaultValue={getValues("notes") || ""}
                      {...field}
                    />
                  )}
                  name="notes"
                  control={control}
                />
              ) : (
                <div className="py-10">
                  {getValues("notes")
                    ? parse(getValues("notes"))
                    : getValues("notes")}
                </div>
              )}
            </div>

            <div className="font-bold text-16">Evaluation Methods Used</div>
            <div className="col-span-3">
              {editableForm ? (
                <Controller
                  name="evaluation_method"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      inputProps={{
                        style: { lineHeight: "1.3" },
                      }}
                      {...field}
                      error={!!errors.evaluation_method}
                      helperText={errors?.evaluation_method?.message}
                      id="evaluation_method"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              ) : (
                <Input
                  className="text-16"
                  placeholder="Title"
                  multiline
                  value={getValues("evaluation_method")}
                  type="text"
                  disableUnderline
                  fullWidth
                />
              )}
            </div>

            <div className="font-bold text-16">
              Applicable Standards/Guidelines
            </div>
            <div className="col-span-3">
              {editableForm ? (
                <Controller
                  className="mt-8 mb-16"
                  render={({ field }) => (
                    <WYSIWYGEditor
                      customControls={true}
                      disabled="false"
                      defaultValue={getValues("applicable_standards") || ""}
                      {...field}
                    />
                  )}
                  name="applicable_standards"
                  control={control}
                />
              ) : (
                <div className="py-10">
                  {getValues("applicable_standards")
                    ? parse(getValues("applicable_standards"))
                    : getValues("applicable_standards")}
                </div>
              )}
            </div>

            <div className="font-bold col-span-3 mb-10 text-18">Terms</div>
            <div className="col-span-3 mb-10 text-16">
              The terms used in the Conformance Level information are defined as
              follows:
            </div>
            <div className="col-span-3 mb">
              <ul className="list-disc ml-40">
                <li>
                  <b>Support: </b>The functionality of the product has at least
                  one method that meets the criterion without known defects or
                  meets with equivalent facilitation.
                </li>
                <li>
                  <b>Partially Supports: </b>Some functionality of the product
                  does not meet the criterion.
                </li>
                <li>
                  <b>Does Not Support: </b>The majority of product functionality
                  does not meet the criterion.
                </li>
                <li>
                  <b>Not Applicable </b>The criterion is not relevant to the
                  product.
                </li>
                <li>
                  <b>Not Evaluated: </b> The product has not been evaluated
                  against the criterion. This can only be used in WCAG Level AAA
                  criteria.
                </li>
              </ul>
            </div>
          </div>

          {/* Standard Guilines */}
          <Typography className="my-20 font-medium text-20">
            Standard Guilines
          </Typography>

          <div className="flex w-full">
            <Table className="simple">
              <TableHead className="  bg-opaque-green">
                <TableRow>
                  <TableCell className="text-white font-medium w-auto">
                    Criteria
                  </TableCell>
                  <TableCell className="text-white font-medium">
                    Level
                  </TableCell>
                  <TableCell className="text-white font-medium w-208">
                    Conformance
                  </TableCell>
                  <TableCell className="text-white font-medium w-512">
                    Remarks and Explanations
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {item.guideline} {item.criteria}
                    </TableCell>
                    <TableCell>{item.level}</TableCell>
                    <TableCell>
                      <Controller
                        name={`guidelines.${index}.conformance`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            size="small"
                            options={conformances}
                            onChange={(e, value) => {
                              onChange(value);
                            }}
                            value={value}
                            renderInput={(params) => (
                              <TextField
                                label="Select Conformance"
                                {...params}
                              />
                            )}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell height={"auto"}>
                      <Controller
                        name={`guidelines.${index}.remarks`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            size="small"
                            inputProps={{
                              style: { lineHeight: "1.3" },
                            }}
                            {...field}
                            error={!!errors.remarks}
                            helperText={errors?.remarks?.message}
                            id="remarks"
                            multiline
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          {/* <div className="flex items-center gap-40 w-full">
              <Typography className=" whitespace-nowrap flex-grow text-18 font-medium">
                VPAT Heading Information
              </Typography>
              <Input
                className="text-16"
                placeholder="Title"
                value="Hola como estas"
                type="text"
                disableUnderline
                fullWidth
              />
            </div> */}
        </CardContent>
      </Card>
    </div>
  );
};

export default EditDocumentContent;
