import { combineReducers } from '@reduxjs/toolkit';
import suites from './suitesSlice';
import suite from './suiteSlice';
import tests from './testsSlice';
import test from './testSlice';
import import_test from './importSlice';

const reducer = combineReducers({
    suites,
    suite,
    tests,
    test,
    import_test
});

export default reducer;
