import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import {
  getJobTitles,
  getSystemRoles,
  selectJobTitle,
  selectSystemRoles,
} from "../../store/userSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Divider, Switch, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { selectUser, userSystemRole } from "app/store/userSlice";
import _ from "lodash";
import { ImportsNotUsedAsValues } from "typescript";

const BasicInfoTab = () => {
  const methods = useFormContext();
  const { control, formState, getValues, setValue } = methods;
  const { errors } = formState;
  const dispatch = useDispatch();
  const jobTitles = useSelector(selectJobTitle);
  const systemRoles = useSelector(selectSystemRoles) || [];
  const routeParams = useParams();
  const { userId } = routeParams || "";
  const isEdit = userId ? true : false;
  const loggedUser = useSelector(selectUser);
  const disabledFields = loggedUser.uuid == userId;
  const [roleValue, setRoleValue] = useState(getValues().role);

  useEffect(() => {
    dispatch(getJobTitles());
    dispatch(getSystemRoles());
  }, []);

  function handleOptionLabel(option) {
    return _.find(systemRoles, ["id", option]);
  }

  function setJobTitleValue(option) {
    return _.find(jobTitles, ["id", option]);
  }

  return (
    <div>
      <Controller
        name="first_name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            className="mt-8 mb-16"
            error={!!errors.first_name}
            required
            autoFocus
            helperText={errors?.first_name?.message}
            label="First Name"
            id="first_name"
            variant="outlined"
            fullWidth
          />
        )}
      />

      <Controller
        name="last_name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            className="mt-8 mb-16"
            error={!!errors.last_name}
            required
            helperText={errors?.last_name?.message}
            label="Last Name"
            id="last_name"
            variant="outlined"
            fullWidth
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            className="mt-8 mb-16"
            error={!!errors.email}
            required
            disabled={isEdit}
            helperText={errors?.email?.message}
            label="Email"
            id="email"
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Controller
        data-test="parent-id-controller"
        name="job_title"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            data-test="combo-box-demo"
            options={jobTitles}
            value={value}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, values) => onChange(values)}
            getOptionLabel={(option) => option.name}
            className="mt-8 mb-16"
            id="combo-box-title"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Job Title"
                required
                error={!!errors.job_title}
                helperText={errors?.job_title?.message}
              />
            )}
          />
        )}
      />
      {!disabledFields && (
        <Controller
          name="role"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              data-test="rolle-system"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={systemRoles}
              onChange={(event, value) => {
                onChange(value);
              }}
              getOptionLabel={(item) => item.name}
              value={value}
              className="mt-8 mb-16"
              id="combo-box-role"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="System Role"
                  error={!!errors.role}
                  required
                  helperText={errors?.role?.message}
                />
              )}
            />
          )}
        />
      )}

      {userId && !disabledFields && (
        <>
          <Divider className="pt-10 mb-10" />
          <Controller
            name="is_active"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <div className="flex items-center">
                <Switch
                  // {...field}
                  disabled={disabledFields}
                  checked={value}
                  onChange={(event, value) => onChange(value)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography>Active</Typography>
              </div>
            )}
          />
        </>
      )}
    </div>
  );
};

export default BasicInfoTab;
