import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import { closeDialog } from "app/store/fuse/dialogSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImportErrors from "./ImportErrors";

export default function ImportDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <DialogTitle
        className="flex flex-row items-center "
        id="alert-dialog-title"
      >
        Importing Test cases
      </DialogTitle>
      <DialogContent className="text-center">
        {props.status == "error" && <ImportErrors errors={props.errors} />}

        {props.status == "completed" && (
          <>
            <FuseSvgIcon
              size={100}
              className="w-full text-center text-green-400"
            >
              feather:check-circle
            </FuseSvgIcon>
            <Typography className="text-green-400 text-2xl ">
              Import Completed!
            </Typography>
            <Typography className="text-16" color="text.secondary">
              We have imported the test cases into
              <strong> {props.suite?.name}</strong> suite.
            </Typography>
          </>
        )}

        {props.status == "inProgress" && (
          <>
            <Typography className="text-16" color="text.secondary">
              We are importing the test cases into
              <strong> {props.suite?.name}</strong> suite. Please do not close
              or update the page until the import is complete.
            </Typography>
            <LinearProgress color="info" className="h-20 mt-20 rounded-20" />{" "}
          </>
        )}
      </DialogContent>

      <DialogActions className="flex flex-auto text-right px-20 py-20 bg-gray-50 dark:bg-[#111827]">
        {props.status == "completed" && (
          <>
            <Button
              onClick={() => dispatch(closeDialog())}
              color="secondary"
              variant="outlined"
              className="ml-auto"
            >
              Import Another File
            </Button>
            <Button
              onClick={() => {
                navigate(
                  `/tests/project/${props.projectId}/suite/${props.suite?.id}`
                );
                dispatch(closeDialog());
              }}
              variant="contained"
              color="secondary"
              className="ml-auto"
            >
              Go to Suite
            </Button>
          </>
        )}

        {props.status == "error" && (
          <Button
            onClick={() => dispatch(closeDialog())}
            variant="contained"
            color="secondary"
            className="ml-auto"
          >
            Close
          </Button>
        )}
      </DialogActions>
    </>
  );
}
