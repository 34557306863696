import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import TestRunHeader from "./TestRunHeader";
import FusePageSimple from "@fuse/core/FusePageSimple";
import TestRunContent from "./TestRunContent";
import { useDispatch } from "react-redux";
import FuseUtils from "@fuse/utils/FuseUtils";
import {
  getTestRun,
  getTestRunStatus,
  selectCurrentTestRun,
  selectTestRunStatus,
} from "../store/testRunSlice";
import { FormProvider, useForm } from "react-hook-form";
import reducer from "../store";
import withReducer from "app/store/withReducer";
import { useSelect } from "@mui/base";
import _ from "lodash";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { updatedSelectedTestIds } from "../../testcase/store/testsSlice";
import { useLocation, useParams } from "react-router-dom";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
  },
}));

const schema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .max("50", "This field must be at most 50 characters")
    .required("This field is required"),
  testrun_status: yup.object().nullable().required("This field is required"),
});

const TestRun = () => {
  const dispatch = useDispatch();
  const testRun = useSelector(selectCurrentTestRun);
  const { testRunKey } = useParams();
  const location = useLocation();
  const isClone = location.pathname.includes("clone");

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      testrun_status: {},
      ...testRun,
    },
    resolver: yupResolver(schema),
  });

  const { reset, setValue } = methods;

  useEffect(() => {
    if (testRunKey != undefined && FuseUtils.isObjectEmpty(testRun)) {
      dispatch(getTestRun(testRunKey)).then((res) => {
        reset(res.payload.data);
      });
    }

    dispatch(getTestRunStatus());
    dispatch(
      updatedSelectedTestIds(
        FuseUtils.isObjectEmpty(testRun) ? [] : testRun.cases
      )
    );
  }, []);

  return (
    <FormProvider {...methods}>
      <Root
        header={<TestRunHeader />}
        content={<TestRunContent />}
        scroll="content"
      />
    </FormProvider>
  );
};

export default withReducer("testRunApp", reducer)(TestRun);
