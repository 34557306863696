
import ProjectDetails from './project/ProjectDetails';
import ProjectSettingsApp from './settings/ProjectSettingsApp';
import { lazy } from 'react';
import DashboardApp from '../dashboard/DashboardApp';
import ProjectApp from './project/ProjectApp';


const ProjectsApp = lazy(() => import('./projects/ProjectsApp'));

const ProjectAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'projects',
      element: <ProjectsApp />,
    },
    {
      path: 'projects/new',
      element: <ProjectApp />,
    },
    {
      path: 'projects/:projectId/edit',
      element: <ProjectApp />,
    },
    {
      path: 'projects/:projectId/dashboard',
      element: <DashboardApp />,
    },
    {
      path: 'projects/:projectId/settings',
      element: <ProjectSettingsApp />,
    }
  ],
};

export default ProjectAppConfig;
