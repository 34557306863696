import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { toggleAIChatPanel } from "./store/stateSlice";
import { sendMessage } from "./store/assistantSlice";
import AIButton from "./AIButton";
import { Tooltip } from "@mui/material";

const AIChatPanelToggleButton = (props) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(toggleAIChatPanel());
  };

  return (
    <AIButton variant="outline" onClick={handleClick} />
  );
};

export default AIChatPanelToggleButton;
