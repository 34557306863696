
import jwtService from 'src/app/auth/services/jwtService';
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';

import { isEmpty } from 'lodash';


export const getProjects = createAsyncThunk(
  'projectsApp/getProjects',
  async (params, { dispatch }) => {
    const res = await jwtService.GET(`/projects`, dispatch, {}, params)
    const result = res.result.map((item) => {
      return {
        ...item
      }
    });
    return { meta: res.paginate, result };
  }
)

export const exportTestCases = createAsyncThunk(
  'projectsApp/getProjects',
  async (projectKey, { dispatch }) => {

    const response = await jwtService.GET(`/projects/${projectKey}/export/testcases`, dispatch, {}, {}, 'blob')
    const blob = new Blob([response], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${projectKey}-testcases.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return { message: 'file exported successfully!' }
  })

export const setPagination = createAsyncThunk(
  'projectsApp/setPagination',
  async ({ pagination, sortBy }, { getState }) => {
    const state = getState().projectsApp;
    const { projects } = state;

    return {
      pagination: { ...projects.pagination, ...pagination },
      sortBy: { ...projects.sortBy, ...sortBy }
    };
  }
);

const projectsSlice = createSlice({
  name: 'projectsApp',
  initialState: {
    loading: false,
    data: [],
    pagination: { page: 1, per: 20 },
    sortBy: {},
    meta: {
      "total_pages": 1,
      "total_count": 1,
      "prev_page": null,
      "current_page": 1,
      "next_page": 2
    }
  },
  reducers: {
    setProjectLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getProjects.pending]: (state) => {
      return { ...state, isLoading: true };
    },
    [getProjects.fulfilled]: (state, action) => {
      const { result, meta } = action.payload;
      return {
        ...state,
        data: result,
        meta,
        isLoading: false
      };
    },
    [getProjects.rejected]: (state) => {
      return { ...state, isLoading: false };
    },
    [setPagination.fulfilled]: (state, action) => {
      const { pagination, sortBy } = action.payload;
      return { ...state, pagination, sortBy };
    },
  }
});

export const selectProjects = ({ projectsApp }) => {
  return projectsApp.projects;
}

export const selectProjectLoading = ({ projectsApp }) => projectsApp.projects.loading;
export const {
  setProjectLoading
} = projectsSlice.actions

export default projectsSlice.reducer;

