import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { selectMembers } from "./store/teamSlice";
import EmptyState from "app/shared-components/EmptyState";
import { motion } from "framer-motion";
import useRoleBasedAccess from "src/app/utils/customHook";
import { authRoles } from "src/app/auth";

const MemberList = (props) => {
  const members = useSelector(selectMembers);
  const { hasRequiredRole } = useRoleBasedAccess(authRoles.tester, []);

  if (members.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
      >
        <EmptyState
          msg="There are not members assigned to this project."
          title="No Members"
        />
      </motion.div>
    );
  }

  return (
    <List sx={{ width: "100%" }}>
      {members.map((member, index) => (
        <>
          <ListItem
            key={member.index}
            alignItems="flex-start"
            secondaryAction={
              hasRequiredRole && (
                <IconButton
                  onClick={() => props.onDeleteMember(member.id)}
                  edge="end"
                  aria-label="comments"
                >
                  <DeleteIcon />
                </IconButton>
              )
            }
          >
            <ListItemAvatar key={member.index}>
              <Avatar
                alt={member.first_name}
                src="/static/images/avatar/1.jpg"
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "grid" }}
                    component="span"
                    variant="subtitle2"
                    className="capitalize"
                  >
                    {member.first_name} {member.last_name}
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {member.email}
                  </Typography>
                  {` - ${member.job_title}`}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider key={member.id} className="pt-20  mb-10" />
        </>
      ))}
    </List>
  );
};

export default MemberList;
