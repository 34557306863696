import React, { useState } from 'react';
import LinkIcon from '@mui/icons-material/Link';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import { useLocalStorage } from 'src/app/utils/customHook';
import { selectCurrentSuite } from '../store/suiteSlice';
import { useSelector } from 'react-redux';

function CopyLink({ dialogIsOpen, testId, url }) {
  const [copied, setCopied] = useState(false);
  const [selectedProject, _] = useLocalStorage('selected_project');
  const currentSuite = useSelector(selectCurrentSuite);

  const copyLink = () => {
    const newUrl =
      dialogIsOpen && testId
        ? `${window.location.origin}/project/${selectedProject.abbreviation}/suits/${currentSuite.id}/tests/${testId}`
        : url;

    navigator.clipboard.writeText(newUrl);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <>
      {copied ? (
        <Tooltip title='Copied!' arrow placement='top' size='sm'>
          <IconButton color='success' aria-label='delete' size='small'>
            <CheckCircleIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Copy suite's link" arrow placement='top' size='sm'>
          <IconButton
            onClick={() => copyLink()}
            aria-label='delete'
            size='small'
          >
            <LinkIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

export default CopyLink;
