import { Typography } from "@mui/material";
import React from "react";
import { IteratorLabel } from "../IteratorLabel";

export const SplitterSection = ({ splitters = [] }) => {
  return (
    <>
      <Typography className=" font-semibold mb-5 text-15">
        Splitter Group
      </Typography>
      {splitters.map((splitter) => (
        <div key={splitter.id} className="ml-10 flex">
          <Typography className="mr-10  mb-10" variant="caption">
            {splitter.name}:
          </Typography>
          <div className="flex gap-2">
            {splitter.labels.map((label) => (
              <IteratorLabel className="mr-5" key={label} label={label} />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};
