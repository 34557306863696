import {
  Autocomplete,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getUsers,
  selectUsers,
} from "src/app/main/system-settings/users/store/usersSlice";
import { useParams } from "react-router-dom";
import {
  addMembers,
  getMembers,
  selectMembers,
  deleteMember,
} from "../store/teamSlice";
import MemberList from "../MemberList";
import { showMessage } from "app/shared-components/ShowMessage";
import { useRef } from "react";
import FuseLoading from "@fuse/core/FuseLoading";
import useRoleBasedAccess from "src/app/utils/customHook";
import { authRoles } from "src/app/auth";

const TeamTab = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers) || [];
  const params = useParams();
  const members = useSelector(selectMembers || []);
  const { projectId } = params;
  const autocompleteRef = useRef();
  const [loading, setLoading] = React.useState(true);
  const { hasRequiredRole } = useRoleBasedAccess(authRoles.tester, []);

  useEffect(() => {
    dispatch(getUsers({ sort: "id", searchText: "", sizePage: 100, page: 0 }));
    dispatch(getMembers(projectId)).then(() => setLoading(false));
  }, []);

  const { control, watch, reset, handleSubmit, formState } = useForm({
    mode: "onChange",
    defaultValues: { members: [], project_id: projectId },
  });

  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const { isValid, dirtyFields, errors } = formState;

  const form = watch();

  function onSubmitNew(data) {
    dispatch(addMembers(data)).then((res) => {
      if (res.payload.status == "error")
        return handleError(res.payload.message);
      dispatch(
        showMessage({
          message: res.payload.message,
          variant: "success",
        })
      );
      reset();
      dispatch(getMembers(projectId));
    });
  }

  const onDeleteMember = (memberId) => {
    dispatch(deleteMember(memberId)).then((res) => {
      if (res.payload.status == "error")
        return handleError(res.payload.message);

      dispatch(
        showMessage({
          message: res.payload.message,
          variant: "success",
        })
      );
      dispatch(getMembers(projectId));
    });
  };

  const handleError = (message) => {
    dispatch(
      openDialog({
        children: <SystemDialog message={message} />,
      })
    );
  };

  const checkAssignedMembers = (id) => {
    if (_.find(members, ["user_id", id])) return true;
    return false;
  };

  function handle(option) {
    return option.id;
  }

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="w-full"
    >
      <div className="px-32 py-24">
        <Typography variant="h6">Team</Typography>
        {hasRequiredRole && (
          <>
            <div className="flex mt-40 items-center">
              <Controller
                control={control}
                name="members"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    ref={autocompleteRef}
                    multiple
                    id="members"
                    options={users}
                    getOptionDisabled={(option) =>
                      checkAssignedMembers(option.id)
                    }
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.email}
                    renderOption={(_props, option, { selected }) => (
                      <li {..._props}>
                        <div className="w-full ml-10">
                          <Typography
                            className="capitalize"
                            variant="subtitle2"
                          >
                            {option.first_name} {option.last_name}
                          </Typography>
                          <Typography> {option.email}</Typography>
                        </div>
                      </li>
                    )}
                    value={
                      value ? value.map((id) => _.find(users, { id })) : []
                    }
                    onChange={(event, newValue) => {
                      onChange(newValue.map((item) => handle(item)));
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="
              Add team members"
                        placeholder="
              Add team members"
                      />
                    )}
                  />
                )}
              />
              <Button
                className="ml-8"
                variant="contained"
                disabled={form.members.length == 0}
                color="secondary"
                onClick={handleSubmit(onSubmitNew)}
              >
                Add
              </Button>
            </div>
            <Divider className="pt-32  mb-10" />
          </>
        )}
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <FuseLoading />
          </div>
        ) : (
          <MemberList onDeleteMember={onDeleteMember} />
        )}
      </div>
    </motion.div>
  );
};

export default TeamTab;
