import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import Input from "@mui/material/Input";
import { useDispatch, useSelector } from "react-redux";
import { selectUsersSearchText, setUsersSearchText } from "../store/usersSlice";
import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

function UsersHeader(props) {
  const dispatch = useDispatch();
  const searchText = useSelector(selectUsersSearchText);
  const [inputValue, setInputValue] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      dispatch(setUsersSearchText(event));
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (event.target.value.length == 0) dispatch(setUsersSearchText(event));
  };

  return (
    <div  className=" flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32 ">
      <Typography
        component={motion.span}
        initial={{ x: -20 }}
        animate={{ x: 0, transition: { delay: 0.2 } }}
        delay={300}
        className="flex text-24 md:text-32 font-extrabold tracking-tight"
       
      >
        Users
      </Typography>
      <div className="flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center justify-end space-x-8">
        <Paper
          component={motion.div}
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
          className="flex items-center w-full sm:max-w-256 space-x-8 px-16 rounded-full border-1 shadow-0"
        >
          <FuseSvgIcon color="disabled">heroicons-solid:search</FuseSvgIcon>

          <Input
            placeholder="Search users"
            className="flex flex-1"
            disableUnderline
            fullWidth
            value={inputValue}
            onKeyPress={handleKeyPress}
            inputProps={{
              "aria-label": "Search users",
            }}
            onChange={handleInputChange}
          />
        </Paper>
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
        >
          <Button
            className=""
            component={Link}
            to="/system-settings/users/new"
            variant="contained"
            color="secondary"
            startIcon={<FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon>}
          >
            Add
          </Button>
        </motion.div>
      </div>
    </div>
  );
}

export default UsersHeader;
