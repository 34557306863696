import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { motion } from "framer-motion";
import {Typography, Skeleton} from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";

import { useLocalStorage } from "src/app/utils/customHook";
import { getSelectedTest } from "./store/testSlice";


export const TestCaseHeaderView = (props) => {
  const { suiteId } = useParams();
  const test = useSelector(getSelectedTest)
  const [selectedProject, _] = useLocalStorage('selected_project');

  return (
    <div className="flex flex-col mx-32">
      <motion.div
        initial={{ x: 20, opacity: 0, marginTop: 20 }}
        animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
      >
        <Typography
          className="flex items-center sm:mb-12"
          component={Link}
          role="button"
          to={`/tests/project/${selectedProject?.id}/suites/${suiteId}`}
          color="secondary"
        >
          <FuseSvgIcon size={20}>heroicons-outline:arrow-sm-left</FuseSvgIcon>
          <span className="flex mx-4 font-medium">{test?.suite.name}</span>
        </Typography>
      </motion.div>

      <div className="flex flex-col w-full sm:flex-row sm:items-center min-w-0 mb-20 ">
        <motion.div
          className="truncate"
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.3 } }}
        >
          {!test ? (
            <Skeleton animation="wave" className="w-sm h-52 " />
          ) : (
            <Typography className="flex-1 text-4xl truncate font-semibold tracking-tight leading-7 md:leading-snug ">
              {test?.code}
            </Typography>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default TestCaseHeaderView;
