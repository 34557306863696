import fetch from 'node-fetch';
import { forEach } from 'lodash';
import { showMessage } from 'app/shared-components/ShowMessage';
import messageSlice from 'app/store/fuse/messageSlice';

// export const formatError = (obj, error, dispatch) => {
//   forEach((obj), (value, key) => {
//     if (error[key]) {
//      dispatch(showMessage({
//       message: error[key][0],
//       variant: 'error'
//     }));
//     }
//  })
// }


export const formatError = (obj) => {
  let messages = [];
  forEach(obj, (value, key) => {
    if (Array.isArray(value)) {
      messages.push(value[0]);
    } else {
      Object.keys(value).forEach(key => {
        messages.push(value[key]);
      });
    }
  });
  return messages;
};

export const handleBlobImages = async (imageUrl, fileName) => {
  try {
    const imageData = await fetch(imageUrl);
    const blob = await imageData.blob();
    const file = new File([blob], `${fileName}.${blob.type.split('/')[1]}`, {
      type: blob.type,
    });

    const url = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });

    return { file, url };
  } catch (error) {
    console.log('error', error);
  }
};
