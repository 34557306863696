const jwtServiceConfig = {
  sessions: 'sessions/',
  refreshToken: 'sessions/refresh/',
  password: 'user/forgot-password',
  signIn: 'api/auth/sign-in',
  signUp: 'api/auth/sign-up',
  updateUser: 'api/auth/user/update',
  activate: 'user/activate',
  resetPassword: 'user/reset-password',
  projects: '/projects',
  suite: '/suite',
  tests: '/testcase',
  type: '/type',
  dashboard: '/dashboard',
  user: '/user/',
  organization: '/organization',
  integration: '/integration',
};

export default jwtServiceConfig;
