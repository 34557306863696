import { selectSimplePagination, setSimplePagination } from 'app/store/paginationSlice';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const useSimplePagination = (initialValue) => {

    const pagination = useSelector(selectSimplePagination);
    const dispatch = useDispatch();

    const [value, setValue] = useState(() =>
        initialValue ? initialValue : pagination
    );

    const setPagination = (item) => {
        setValue(item);
        dispatch(setSimplePagination(item))
    }

    return [value, setPagination]

}

export default useSimplePagination;
