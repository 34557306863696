

import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { Pagination } from '@mui/material';
import { selectFilterParams } from 'app/store/filterSlice';
import { useSelector } from 'react-redux';
import { selectTestsLoading } from '../store/testsSlice';
import { SkeletonTestList } from './SkeletonTestList';
import { TestCaseItemList } from './TestCaseItemList';
import TestEmptyState from './TestEmptyState'
import { selectCount, selectSimplePagination, setSimplePagination } from 'app/store/paginationSlice';
import { useDeepCompareEffect } from '@fuse/hooks';
import { selectCurrentSuite } from '../store/suiteSlice';

export const TestCaseList = (props) => {
  const {
    actionButtons,
    showSubTitle,
    onChange,
    showStatus = false,
    tests = [],
    statusIndicator = true,
    multiple = true } = props;
  const loading = useSelector(selectTestsLoading);
  const filters = useSelector(selectFilterParams);
  const pagination = useSelector(selectSimplePagination);
  const pageCount = useSelector(selectCount)
  const selectedSuite = useSelector(selectCurrentSuite);

  function handleChangePage(e, page) {
    setSimplePagination({ page })
    onChange(selectedSuite?.id, page, filters)
  }

  useDeepCompareEffect(() => {
    setSimplePagination({ page: 1 })
    onChange(selectedSuite?.id, 1, filters)
  }, [selectedSuite, filters])


  if (loading) {
    return (
      <SkeletonTestList />
    );
  }

  return (
    <>
      {
        tests.length > 0 ?
          <div className="w-full flex flex-col min-h-full">
            <FuseScrollbars className="grow overflow-x-auto mt-20">
              {
                tests.map((item) => (
                  <TestCaseItemList
                    statusIndicator={statusIndicator}
                    showStatus={showStatus}
                    actionButtons={actionButtons}
                    key={item.id}
                    multiple={multiple}
                    test={item} />
                ))
              }
            </FuseScrollbars>

            <Pagination color="secondary" className="py-10 shrink-0 flex justify-end  border-t-1"
              count={pageCount}
              onChange={handleChangePage}
              defaultPage={pagination.page} />
          </div>


          : <TestEmptyState showSubTitle={showSubTitle} />
      }
    </>
  )
}

