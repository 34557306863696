export const StatusBadge = ({ status }) => {
  const statusColor = () => {
    let color = status?.color || 'gray';
    return {
      className: `bg-${color}-100 text-${color}-800 `,
      name: status?.name,
    };
  };
  return (
    <div className="">
      <span
        className={` text-center font-bold text-xs px-10 py-3  rounded-full tracking-wide uppercase leading-relaxed whitespace-nowrap  ${statusColor().className
          }`}
      >
        {' '}
        {statusColor().name}
      </span>
    </div>
  );
};
