import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { LoadingButton } from "@mui/lab";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { editProject, createNewProject } from "../store/projectSlice";
import { showMessage } from "app/store/fuse/messageSlice";

const ProjectHeader = () => {
  const { projectId } = useParams();
  const methods = useFormContext();
  const { trigger, getValues } = methods;
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const save = async () => {
    const isValid = await trigger();
    if (isValid) {
      setLoading(true);

      const projectData = getValues();
      projectData.projectId = projectId;
      if (
        !(projectData.logo instanceof File) &&
        projectData.logo.includes("tambora-empty-logo.png")
      )
        projectData.logo = "";

      const response = projectId
        ? dispatch(editProject(projectData))
        : dispatch(createNewProject(projectData));

      response.then((res) => {
        if (res.payload.status == "success") {
          navigate("/projects");
          dispatch(
            showMessage({
              message: res.payload.message,
              variant: "success",
            })
          );
        }
        setLoading(false);
      });
    }
  };

  return (
    <div className="  flex flex-col sm:flex-row flex-1 w-full items-center justify-between  py-20 px-24 md:px-32">
      <div className="flex flex-col items-center sm:items-start w-full  ">
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
        >
          <Typography
            className="flex items-center sm:mb-12"
            component={Link}
            role="button"
            to={`/projects`}
            color="secondary"
          >
            <FuseSvgIcon size={20}>heroicons-outline:arrow-sm-left</FuseSvgIcon>
            <span className="flex mx-4 font-medium ">Projects</span>
          </Typography>
        </motion.div>
        <div className="flex items-center max-w-full">
          <motion.div
            className="flex flex-col items-center sm:items-start min-w-0 mx-8 sm:mx-16 "
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.3 } }}
          >
            <Typography className="text-2xl md:text-4xl font-extrabold">
              {`${projectId ? "Edit" : "New"}  Project`}
            </Typography>
          </motion.div>
        </div>
      </div>
      <motion.div
        className="flex"
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
      >
        <LoadingButton
          loading={loading}
          className="whitespace-nowrap mx-4"
          variant="contained"
          color="secondary"
          onClick={save}
        >
          Save
        </LoadingButton>
      </motion.div>
    </div>
  );
};

export default ProjectHeader;
