import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { TypeIcon } from "./TypeIcon";
import { styled } from "@mui/material/styles";
import { Box, Checkbox } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { TreeItemMenu } from "./TreeItemMenu";
import { checkTargetForNewValues } from "framer-motion";
import useRoleBasedAccess from "src/app/utils/customHook";
import { authRoles } from "src/app/auth";

const CustomNodeStyled = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  borderTopRightRadius: theme.spacing(2),
  borderBottomRightRadius: theme.spacing(2),
  paddingRight: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMedium,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
    color: "var(--tree-view-color)",
  },
}));

export const CustomNode = (props) => {
  const { data, id } = props.node;
  const { actionButton = true, droppable = true } = props;
  const indent = props.depth * 24;
  const [hover, setHover] = useState(false);
  const params = useParams();
  const { hasRequiredRole } = useRoleBasedAccess(authRoles.external_tester, []);
  const isAllTests = data.id == "all_test_cases";
  const onMenuClose = () => {
    setHover(false);
  };

  const handleSelect = (e) => {
    e.stopPropagation();
  };

  const handleToggle = (e) => {
    if (data.is_module) {
      props.onToggle(id);
    } else {
      props.onSelect(e, props.node);
    }
    e.stopPropagation();
  };

  return (
    <CustomNodeStyled
      className={`${props.isSelected ? "Mui-selected" : ""}`}
      sx={{
        display: "flex",
        alignItems: "center",
        p: 0.5,
        pr: 0,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box
        className={`tree-node cursor-pointer  h-32 items-center ${
          props.isSelected ? "Mui-selected" : ""
        }`}
        onClick={handleToggle}
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto 1fr auto",
          paddingInlineStart: indent,
        }}
      >
        <div
          className={`h-24 justify-center w-24 items-center cursor-pointer flex rotate-90 transition duration-500 ${
            props.isOpen ? "rotate-90" : "rotate-0"
          }`}
        >
          {data?.children?.length > 0 && <ArrowRightIcon />}
        </div>
        {/* <Checkbox
          color="primary"
          size="small"
          // checked={props.isSelected}
          onClick={handleSelect}
        /> */}
        <div className="">
          <TypeIcon
            isAllTests={id == "all"}
            isModule={data.is_module}
            droppable={droppable}
          />
        </div>

        <Typography
          sx={{ fontWeight: "inherit" }}
          className="pl-8 truncate text-ellipsis"
          variant="body2"
        >
          {props.node.text}
        </Typography>
      </Box>
      {hasRequiredRole && hover && data && actionButton && id != "all" && (
        <TreeItemMenu
          suite={data}
          projectId={params.projectId}
          isModule={data.is_module}
          onMenuClose={onMenuClose}
        />
      )}
    </CustomNodeStyled>
  );
};
