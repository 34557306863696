import React from "react";
import { motion } from "framer-motion";
import { Paper, Skeleton, Typography } from "@mui/material";
import StatusCard from "./StatusCard";
import FuseUtils from "@fuse/utils/FuseUtils";
import { useSelector } from "react-redux";
import { selectTestRunStatusStats } from "../../../store/testRunProcessSlice";

const StatusWidget = (props) => {
  const { item } = props;
  const stats = useSelector(selectTestRunStatusStats);

  if (FuseUtils.isObjectEmpty(stats)) {
    return (
      <div className="md:col-span-5 lg:col-span-6 flex gap-20 w-full">
        {[1, 2, 3, 4].map((item) => (
          <Skeleton
            key={item}
            variant="rounded"
            className="w-full h-120 rounded-8"
          />
        ))}
      </div>
    );
  }

  const { labels, series, colors, percentage } = stats;
  const passedIndex = labels.indexOf("Passed");
  const failedIndex = labels.indexOf("Failed");

  return (
    <>
      {/* PASSED */}
      <motion.div variants={item} className="col-span-2">
        <StatusCard
          color={colors[passedIndex]}
          label={labels[passedIndex]}
          serie={series[passedIndex]}
          percentage={percentage[passedIndex]}
        />
      </motion.div>

      {/* FAILED */}
      <motion.div variants={item} className="col-span-2">
        <StatusCard
          color={colors[failedIndex]}
          label={labels[failedIndex]}
          serie={series[failedIndex]}
          percentage={percentage[failedIndex]}
        />
      </motion.div>

      {/* OTHER STATUS */}
      {series.map((item, index) => {
        return index != failedIndex && index != passedIndex ? (
          <motion.div key={index} variants={item} className="">
            <StatusCard
              key={index}
              color={colors[index]}
              label={labels[index]}
              serie={item}
              percentage={percentage[index]}
            />
          </motion.div>
        ) : undefined;
      })}
    </>
  );
};

export default StatusWidget;
