import { Chip, Typography } from "@mui/material";
import React from "react";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";

const TestTags = ({ tags }) => {
  return (
    <>
      <Typography className=" text-gray-700 font-semibold mb-5 text-13">
        Tags
      </Typography>
      <div className="flex flex-row flex-wrap gap-4">
        {tags.map((tag) => (
          <Chip
            key={tag}
            className=" rounded-6"
            size="small"
            icon={<SellOutlinedIcon />}
            label={tag}
          />
        ))}
      </div>
    </>
  );
};

export default TestTags;
