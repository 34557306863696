import { TextField, Typography } from "@mui/material";
import TreeSelect from "mui-tree-select";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import orderSuitesAsTree from "../utils/OrderTree";

class Node {
  constructor(value) {
    this.value = value;
  }
  getParent(suites) {
    const parent = (() => {
      if ("children" in this.value) {
        for (const { children } of suites) {
          const state = children.find(({ children }) =>
            children.some(({ id }) => id === this.value.id)
          );
          if (state) {
            return state;
          }
        }
        return null;
      }
    })();
    return parent ? new Node(parent) : parent;
  }
  getChildren() {
    if (this.value.children.length > 0) {
      return this.value.children.map((children) => new Node(children));
    } else {
      return null;
    }
  }

  toString() {
    return this.value?.name;
  }
}

export default function TamboraTreeSelect(props) {
  const { options = [], label = "Suite" } = props;
  const treeSuites = orderSuitesAsTree(options);

  const [value, setValue] = useState(null);

  const onSuiteChange = (_, value) => {
    props.onChange(_, value?.value);
    if (value == null) {
      return setValue(null);
    }
    setValue(value);
  };

  useEffect(() => {
    const suite = _.find(options, ["id", props.value?.id])?.data;
    if (suite) setValue(new Node(suite));
  }, [options]);

  return (
    <TreeSelect
      size="small"
      disablePortal
      getChildren={(node) =>
        node
          ? node.getChildren()
          : treeSuites.map((suite) => new Node(suite.data))
      }
      getOptionDisabled={(option) => {
        return (
          option.value.is_module == true && option.value.children.length === 0
        );
      }}
      getParent={(node) =>
        node.getParent(treeSuites.map((suite) => suite.data))
      }
      value={value}
      onChange={(_, value) => onSuiteChange(_, value)}
      renderInput={props.renderInput}
    />
  );
}
