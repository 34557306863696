import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import _, { rest, update } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { DialogActions } from "@mui/material";
import {
  closeSuiteDialog,
  createSuite,
  updateSuite,
} from "../store/suiteSlice";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useEffect } from "react";
import { checkTargetForNewValues } from "framer-motion";
import { getSuites } from "../store/suitesSlice";
import SystemDialog from "app/shared-components/SystemDialog";
import { openDialog } from "app/store/fuse/dialogSlice";
import { showMessage } from "app/shared-components/ShowMessage";

const schema = yup.object().shape({
  name: yup
    .string()
    .max(50, "Suite name must have 50 characters max")
    .required("You must enter a Suite name"),
});

export const SuiteForm = ({ projectId, suite, isModule, isNew, parentId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { handleSubmit, formState, control, reset } = useForm({
    mode: "onChange",
    defaultValues: _.merge({}, isNew ? {} : suite?.data, {
      project_id: projectId,
      is_module: isModule,
      parent_id: parentId,
    }),
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const saveSuite = (value) => {
    setLoading(true);
    isNew ? create(value) : update(value);
  };

  const update = (value) => {
    dispatch(updateSuite(value)).then((res) => {
      setLoading(false);
      if (res.payload.status == "error") {
        return dispatch(
          openDialog({
            children: <SystemDialog message={res.payload.message} />,
          })
        );
      }
      dispatch(getSuites({ projectID: projectId, isTree: false }));
      dispatch(closeSuiteDialog());
      dispatch(
        showMessage({
          message: res.payload.message, //text or html
          variant: "success", //success error info warning null
        })
      );
    });
  };

  const create = (value) => {
    dispatch(createSuite(value)).then((res) => {
      setLoading(false);
      if (res.payload.status == "error") {
        return dispatch(
          openDialog({
            children: <SystemDialog message={res.payload.message} />,
          })
        );
      }

      dispatch(getSuites({ projectID: projectId, isTree: false }));
      dispatch(closeSuiteDialog());
      dispatch(
        showMessage({
          message: res.payload.message, //text or html
          variant: "success", //success error info warning null
        })
      );
    });
  };

  const onCloseDialog = () => {
    dispatch(closeSuiteDialog());
  };

  return (
    <form
      noValidate
      className="flex flex-col"
      onSubmit={handleSubmit(saveSuite)}
      name="suiteRForm"
    >
      <div className="p-20" data-test="suite-dialog-content">
        {/* Name */}
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-8 mb-16"
              label="Name"
              required
              id="name"
              variant="outlined"
              fullWidth
              error={!!errors.name}
              helperText={errors?.name?.message}
            />
          )}
        />

        {/* Name */}
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Description"
              className="mt-8 mb-16"
              id="sumary2"
              multiline
              rows={3}
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div>

      <div className="flex flex-auto text-right px-24 py-20 bg-gray-50  dark:bg-[#111827]">
        <div className="flex items-right w-full space-x-8 mt-16 sm:mt-0">
          <Button className="ml-auto" onClick={onCloseDialog}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            color="secondary"
            loading={loading}
            variant="contained"
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </form>
  );
};
