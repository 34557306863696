import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { memo, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import CountUp from 'react-countup';

function UsersWidget() {

    const { stats } = useSelector(state => state.dashboardApp.dashboard);


    return (
        <>
            <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
                <div className="flex items-center justify-between px-8 pt-12">

                </div>
                <div className="text-center mt-8 flex-row">
                    <Typography className="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-blue-500">
                        <CountUp start={0} end={stats?.total_users} />

                    </Typography>
                    <Typography className="text-lg pb-20 font-medium text-blue-600 dark:text-blue-500">
                        Team
                    </Typography>
                </div>

            </Paper>

        </>


    );
}

export default memo(UsersWidget);
