import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IconButton, TablePagination, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {
  deleteMappingProject,
  getMappedProjects,
  selectMappedProjects,
} from "../../store/jiraSlice";
import EmptyState from "app/shared-components/EmptyState";
import { motion } from "framer-motion";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { openDialog, closeDialog } from "app/store/fuse/dialogSlice";
import ConfirmDialog from "app/shared-components/ConfirmDialog";
import { showMessage } from "app/shared-components/ShowMessage";

const MappingProjectTable = () => {
  const { result } = useSelector(selectMappedProjects);
  const mappedProject = result || [];
  const dispatch = useDispatch();

  const deleteConfirmation = (mappingId) => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Are you sure?"
            confirmButton={true}
            confirmButtonLabel="Delete"
            confirmAction={() => deleteMapping(mappingId)}
            message="Do you really want delete? this process cannot be undone."
            status="info"
          />
        ),
      })
    );
  };

  const deleteMapping = (mappingId) => {
    dispatch(closeDialog());
    dispatch(deleteMappingProject(mappingId)).then((res) => {
      if (res.payload.status == "success") {
        dispatch(getMappedProjects()).then(() => {
          dispatch(
            showMessage({
              message: res.payload.message,
              variant: "success",
            })
          );
        });
      }
    });
  };

  if (mappedProject.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-1 items-center justify-center h-full"
      >
        <Typography color="text.secondary" variant="h6">
          There are no mapped projects!
        </Typography>
      </motion.div>
    );
  }

  return (
    <div className="flex flex-col ">
      <TableContainer
        sx={{ maxHeight: 700 }}
        className="grow overflow-x-auto bg-white"
      >
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          stickyHeader
          aria-labelledby="tableTitle"
        >
          <TableHead>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>Jira Account</TableCell>
              <TableCell>Jira Project</TableCell>
              <TableCell>Tambora Project</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappedProject.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.jira_account}
                  </TableCell>
                  <TableCell>
                    {row?.jira_project.name} ({row?.jira_project.key})
                  </TableCell>
                  <TableCell>{row?.project.name} </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => deleteConfirmation(row.id)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination className="shrink-0 border-t-1" component="div" /> */}
    </div>
  );
};

export default MappingProjectTable;
