import authRoles from '../../auth/authRoles';
import ActivateAccount from './ActivateAccount';

const ActivateAccountConfig = {
    settings: {
      layout: {
        config: {
          navbar: {
            display: false,
          },
          toolbar: {
            display: false,
          },
          footer: {
            display: false,
          },
          leftSidePanel: {
            display: false,
          },
          rightSidePanel: {
            display: false,
          },
        },
      },
    },
    auth: authRoles.onlyGuest,
    routes: [
      {
        path: 'activate-account',
        element: <ActivateAccount />,
      },
    ],
  };
  
  export default ActivateAccountConfig;