import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useSwipeable } from "react-swipeable";
import { useSelector } from "react-redux";
import {
  closeAIChatPanel,
  openAIChatPanel,
  selectAIChatPanelState,
} from "./store/stateSlice";
import withReducer from "app/store/withReducer";
import reducer from "./store";
import { memo, useCallback, useEffect, useRef } from "react";
import { AppBar, IconButton, Paper, Toolbar, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import AIChat from "./AIChat";
import keycode from "keycode";
import { selectCurrentSuite } from "src/app/main/testcase/store/suiteSlice";
import AIButton from "./AIButton";

const Root = styled("div")(({ theme, opened }) => ({
  position: "sticky",
  display: "flex",
  top: 0,
  // right: 70,
  width: 0,
  // maxWidth: 70,
  // minWidth: 70,

  height: "100vh",
  zIndex: 9999,

  [theme.breakpoints.down("lg")]: {
    position: "fixed",
    height: "100%",
    width: 0,
    maxWidth: 0,
    minWidth: 0,
  },

  ...(opened && {
    overflow: "visible",
  }),

  //   ...(!opened && {
  //     overflow: "hidden",
  //     animation: `hide-panel 1ms linear ${theme.transitions.duration.standard}`,
  //     animationFillMode: "forwards",
  //   }),

  "& > .panel": {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 560,
    minWidth: 560,
    height: "100%",
    paddingRight: 70,
    overflow: "hidden",
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    transform: "translate3d(0,0,0)",
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),

    ...(opened && {
      transform:
        theme.direction === "rtl"
          ? "translate3d(290px,0,0)"
          : "translate3d(-490px,0,0)",
    }),

    [theme.breakpoints.down("lg")]: {
      left: "auto",
      position: "fixed",
      transform:
        theme.direction === "rtl"
          ? "translate3d(-360px,0,0)"
          : "translate3d(360px,0,0)",
      boxShadow: "none",
      width: 320,
      minWidth: 320,
      maxWidth: "100%",

      ...(opened && {
        transform: "translate3d(0,0,0)",
        boxShadow:
          "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      }),
    },
  },

  "@keyframes hide-panel": {
    "0%": {
      overflow: "visible",
    },
    "99%": {
      overflow: "visible",
    },
    "100%": {
      overflow: "hidden",
    },
  },
}));

const AIChatPanel = () => {
  const ref = useRef();
  const state = useSelector(selectAIChatPanelState);
  const dispatch = useDispatch();
  const suite = useSelector(selectCurrentSuite);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      return state && theme.direction === "rtl" && dispatch(closeChatPanel());
    },
    onSwipedRight: () => {
      return state && theme.direction === "ltr" && dispatch(closeChatPanel());
    },
  });

  const handleDocumentKeyDown = useCallback(
    (event) => {
      if (keycode(event) === "esc") {
        dispatch(closeAIChatPanel());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      document.removeEventListener("keydown", handleDocumentKeyDown);
    };
  }, [dispatch, handleDocumentKeyDown]);

  useEffect(() => {
    if (state) {
      document.addEventListener("keydown", handleDocumentKeyDown);
    } else {
      document.removeEventListener("keydown", handleDocumentKeyDown);
    }
  }, [handleDocumentKeyDown, state]);

  /**
   * Click Away Listener
   */
  // useEffect(() => {
  //   function handleDocumentClick(ev) {
  //     if (ref.current && !ref.current.contains(ev.target)) {
  //       dispatch(closeAIChatPanel());
  //     }
  //   }

  //   if (state) {
  //     document.addEventListener("click", handleDocumentClick, true);
  //   } else {
  //     document.removeEventListener("click", handleDocumentClick, true);
  //   }

  //   return () => {
  //     document.removeEventListener("click", handleDocumentClick, true);
  //   };
  // }, [state, dispatch]);

  /**
    * Close Panel when change the Suite
    */
  useEffect(() => {
    dispatch(closeAIChatPanel());
  }, [suite])

  return (
    <Root opened={state ? 1 : 0} {...handlers}>
      <div className="panel flex flex-col max-w-full" ref={ref}>
        <AppBar position="static" className="shadow-md bg-gradient-to-r from-opaque-green via-caribbean-green to-caribbean-green
         ">
          <Toolbar className=" pl-20 pr-10">

            <AIButton variant="startWhite" />




            <div className="flex flex-1 items-left px-12">
              <Typography className="mx-16 text-16" color="inherit">
                Test Cases Assistant
              </Typography>
            </div>

            <div className="flex px-4">
              <IconButton
                onClick={(ev) => dispatch(closeAIChatPanel())}
                color="inherit"
                size="large"
              >
                <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Paper className="flex flex-1 flex-row min-h-px shadow-0">
          {state && <AIChat className="flex flex-1 z-10" />}
        </Paper>
      </div>
    </Root>
  );
};

export default withReducer("AssistantApp", reducer)(memo(AIChatPanel));
