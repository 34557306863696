import { styled } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { useEffect, useState } from 'react';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { SuiteNavigation } from './sidebar/SuiteNavigation';
import { SuiteDialog } from './sidebar/SuiteDialog';
import withReducer from 'app/store/withReducer';
import reducer from './store';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getAutomationStatus, getIterators, getPriorityType, getTestcaseTypes, getTestTags } from './store/testSlice';
import { useDispatch } from 'react-redux';
import { getTestStatus } from './store/testsSlice';
import { getSuite, setCurrentSuite } from './store/suiteSlice';
import { clearFilters } from 'app/store/filterSlice';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

export const TestCaseLayout = () => {


  const routeParams = useParams();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(!isMobile);
  const { suiteSidebarStatus } = useSelector(state => state.suitesApp.suite);
  const [showHeader, setShowHeader] = useState(false);
  const { suiteId, projectId } = routeParams;
  let location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
    setRightSidebarOpen(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    if (location.pathname.includes('suite/'))
      setShowHeader(true)
  }, [location]);


  useEffect(() => {
    if (suiteId && suiteId != "all") {
      dispatch(getSuite(suiteId)).then((res) => {
        dispatch(setCurrentSuite(res.payload));
      });
    } else {
      dispatch(setCurrentSuite({ id: "all", name: "All Test cases" }));
    }

    dispatch(getIterators(projectId));
    dispatch(getTestStatus());
    dispatch(getAutomationStatus());
    dispatch(getPriorityType());
    dispatch(getTestcaseTypes());
    dispatch(getTestTags(projectId));

    return () => {
      dispatch(clearFilters())
    }
  }, []);


  return (
    <Root
      content={<Outlet />}
      leftSidebarOpen={suiteSidebarStatus}
      leftsidebarwidth={500}
      leftSidebarOnClose={() => {
        setLeftSidebarOpen(false);
      }}
      leftSidebarContent={
        <>
          <SuiteNavigation />
          <SuiteDialog />
        </>

      }
      scroll="content"
    />
  );
}
export default withReducer('suitesApp', reducer)(TestCaseLayout);


