
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import jwtService from 'src/app/auth/services/jwtService';

export const sendMessage = createAsyncThunk(
  'assitant/chat/sendMessage',
  async (message, { dispatch }) => {
    const response = await jwtService.POST(`/chatbot/assistant_message`, message, dispatch);
    return response;
  }
);

export const chat_completion = createAsyncThunk(
  'assitant/chat/chat_completion',
  async (data, { dispatch }) => {
    const response = await jwtService.POST(`/chatbot/chat_completion`, data, dispatch);
    return response;
  }
);

export const getMessages = createAsyncThunk(
  'assitant/chat/getMessages',
  async (conversation_id, { dispatch }) => {
    const response = await jwtService.GET(`/chatbot/${conversation_id}/messages`, dispatch);
    return response;
  }
);


const assistantSlice = createSlice({
  name: 'assistantSlice/chat',
  initialState: {
    chat: []
  },

  reducers: {
    addChatMessage: (state, action) => {
      const message = {
        "role": action.payload.role,
        "message": action.payload.message
      }
      state.chat.push(message);
    },
    clearChat: (state, action) => {
      state.chat = [];
    }
  },
  extraReducers: {
    [sendMessage.fulfilled]: (state, action) => {
      state.chat.push(action.payload.data);
    },
    [getMessages.fulfilled]: (state, action) => {
      action.payload.data.unshift({
        "role": "assistant",
        "message": `{"status":"success", "message": "Hello! I am here to help you create detailed test cases. Could you please provide the requirements or specific functionality that needs to be tested?."}`
      })

      state.chat = action.payload.data
    }
  },
});

export const {
  addChatMessage,
  clearChat } = assistantSlice.actions;
export const selectAssistantChat = ({ AssistantApp }) => AssistantApp.assistantChat.chat
export default assistantSlice.reducer;