
import { lazy } from 'react';
import Document from './document/Document';


const DocumentsApp = lazy(() => import('./documents/Documents'));

const DocumentAppConfig = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: 'projects/:projectKey/documents',
            element: <DocumentsApp />,
        },
        {
            path: 'projects/:projectKey/documents/:documentId/:viewMode',
            element: <Document />,
        },
        {
            path: 'projects/:projectKey/documents/:documentId/:viewMode',
            element: <Document />,
        },
        {
            path: 'projects/:projectKey/documents/:viewMode',
            element: <Document />,
        },
    ],
};

export default DocumentAppConfig;
