import { combineReducers } from '@reduxjs/toolkit';
import testRunsSlice from './testRunsSlice';
import testRunSlice from './testRunSlice';
import testRunProcessSlice from './testRunProcessSlice';

const reducer = combineReducers({
    testRunsSlice,
    testRunSlice,
    testRunProcessSlice
});

export default reducer;