import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SystemErrorDialog from 'app/shared-components/SystemErrorDialog';
import axios from 'axios';
import { openDialog } from 'app/store/fuse/dialogSlice';

export const importTests = createAsyncThunk(
    'importApp/importTests',
    async (importData, { rejectWithValue, dispatch }) => {
        const headers = { 'Content-Type': 'multipart/form-data' };
        const formData = new FormData();
        formData.append('suite_id', importData.suite_id);
        formData.append('file', importData.file);
        try {
            const response = await new Promise((resolve, reject) => {
                axios
                    .post(`/testcase/import`, formData, headers)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
            return response;
        } catch (error) {
            if (error.response.status == 500) {
                dispatch(
                    openDialog({
                        children: (
                            <SystemErrorDialog response={error.response}></SystemErrorDialog>
                        ),
                    })
                );
                // return rejectWithValue(error.response);
            } else {
                return rejectWithValue(error.response.data);
            }



        }
    }
)

const importSlice = createSlice({
    name: 'importApp',
    initialState: {},
    reducers: {},
    extraReducers: {
        [importTests.fulfilled]: (state, action) => {
            state.tests = action.payload.result
        }
    },

})


export default importSlice.reducer;