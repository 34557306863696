import React, { useEffect } from "react";
import { motion } from "framer-motion";

import MappedProjectsCard from "./MappedProjectsCard";
import AddMappingCard from "./AddMappingCard";
import { useDispatch } from "react-redux";
import { getProjects } from "src/app/main/projects/store/projectsSlice";

const ProjectMappingTab = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjects());
  }, []);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="w-full"
    >
      <div className="flex gap-20">
        <div className=" w-1xl">
          <AddMappingCard item={item} />
        </div>

        <div className="w-full">
          <MappedProjectsCard item={item} />
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectMappingTab;
