import { memo, useEffect, useState } from 'react';
import * as yup from 'yup';
import ReactApexChart from 'react-apexcharts';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import Typography from '@mui/material/Typography';
import { getCreatedTests } from '../../../store/dashboardSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';

const schema = yup.object().shape({
  date_from: yup.date(),
  date_to: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)) // Solves issue: https://github.com/jquense/yup/issues/764
    .when('date_from', (date_from, schema) =>
      date_from
        ? schema.min(date_from, 'Date To must be greater or equal to Date From')
        : schema
    ),
});

function TestCaseUserWidget() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const params = useParams()
  const [awaitRender, setAwaitRender] = useState(true);
  const { testUsersMetrics, selectedProject } = useSelector(
    (status) => status.dashboardApp.dashboard
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      date_from: '',
      date_to: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset()
  }, [selectedProject, params.projectId])


  const { control, formState, setValue, watch, reset } = methods;
  const { errors } = formState;

  const { series = [], labels = [] } = testUsersMetrics || [];

  const filterByRange = () => {
    const dateFrom = watch('date_from');
    const dateTo = watch('date_to');

    if (dateTo >= dateFrom) {
      dispatch(
        getCreatedTests({
          ...(selectedProject && { project_id: selectedProject }),
          ...(watch('date_from') && { date_from: watch('date_from') }),
          ...(watch('date_to') && { date_to: watch('date_to') }),
        })
      );
    }
  };

  const chartOptions = {
    chart: {
      animations: {
        speed: 400,
        animateGradually: {
          enabled: false,
        },
      },
      fontFamily: 'inherit',
      foreColor: 'inherit',
      height: '100%',
      type: 'donut',
    },
    labels,
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
    },
    plotOptions: {
      pie: {
        size: '65%',
        customScale: 0.9,
        expandOnClick: true,
        PeriodicWave: {
          size: '70%',
        },
      },
    },
    stroke: {
      colors: [theme.palette.background.paper],
    },
    series: series,
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: 'dark',
      custom: ({
        seriesIndex,
        w,
      }) => `<div class="flex items-center h-32 min-h-32 max-h-23 px-12">
                  <div class="w-12 h-12 rounded-full" style="background-color: ${w.globals.colors[seriesIndex]};"></div>
                  <div class="ml-8 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
                  <div class="ml-8 text-md font-bold leading-none">${w.config.series[seriesIndex]}</div>
              </div>`,
    },
  };

  useEffect(() => {
    setAwaitRender(false);
  }, []);

  if (awaitRender) {
    return null;
  }

  return (
    <Paper className='flex flex-auto shadow rounded-2xl overflow-hidden h-full'>
      <div className='flex  flex-col items-start p-20 gap-20'>
        <Typography className='text-lg font-medium tracking-tight leading-6 truncate '>
          Created Tests by Tester
        </Typography>

        <Controller
          name='date_from'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.date_from}
              type='date'
              helperText={errors?.date_from?.message}
              label='Date From'
              id='date_from'
              variant='filled'
              size='small'
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                field.onChange(e);
                filterByRange();
              }}
            />
          )}
        />

        <Controller
          name='date_to'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              style={{ maxWidth: '146px' }}
              error={!!errors.date_to}
              type='date'
              helperText={errors?.date_to?.message}
              label='Date To'
              id='date_to'
              variant='filled'
              size='small'
              onChange={(e) => {
                field.onChange(e);
                filterByRange();
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-1 grid-flow-row gap-24 w-full h-full'>
        <div className='flex flex-col flex-auto'>
          {/* <Typography className="font-medium" color="text.secondary">
            Percentage
          </Typography> */}
          <div className='flex flex-col flex-auto'>
            {series.length ? (
              <ReactApexChart
                className='flex flex-auto items-center justify-center w-full h-full'
                options={chartOptions}
                series={chartOptions.series}
                type={chartOptions.chart.type}
                height={chartOptions.chart.height}
              />
            ) : (
              <div className='flex items-center justify-center h-full w-full'>
                <Typography
                  className='font-medium py-20'
                  color='text.secondary'
                >
                  No Metrics Found
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    </Paper>
  );
}

export default memo(TestCaseUserWidget);
