/**
 * Authorization Roles
 */
const authRoles = {
  admin: ['Organization Admin'],
  tester: ['Organization Admin', 'Tester'],
  external_tester: ['Organization Admin', 'Tester', 'External Tester'],
  user: ['Organization Admin', 'Tester', 'External Tester', 'User',],
  onlyGuest: [],
};

export default authRoles;
