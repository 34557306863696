import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";

const AccordionPanel = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "transparent",
  padding: "0px",
}));

export default AccordionPanel;
