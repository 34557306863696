import { Paper, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
export const SkeletonTestList = ({ test }) => {
  return (
    <div className="flex flex-col w-full">
      {[1, 2, 3, 4, 5].map((item) => (
        <Paper
          key={item}
          className="flex flex-col w-full  h-[115px] shadow rounded px-20  py-10 overflow-hidden m-10"
        >
          <div className="flex gap-10 space-x-2 min-h-[34px]">
            <div className="flex-1">
              <Skeleton width={90} />
            </div>
            <div className="flex pr-32 flex-shrink-0 self-center">
              <Skeleton width={150} />
            </div>
          </div>

          <div className="flex  flex-row items-center gap-10">
            <div className="basis-[15%] min-w-[15%]">
              <Skeleton height={60} width={150} />
            </div>

            <div className="w-full">
              <Skeleton className="w-full" />
            </div>
          </div>
        </Paper>
      ))}
    </div>
  );
};
