import { motion } from 'framer-motion';
import TestcaseWidget from './widgets/TestcaseWidget';
import AutomatedTestWidget from './widgets/AutomatedTestWidget';
import OpenBugsWidget from './widgets/OpenBugsWidget';
import UsersWidget from './widgets/UsersWidget';
import TestCaseStatusWidget from './widgets/TestCaseStatusWidget';
import TestCaseUsersWidget from './widgets/TestCaseUsersWidget';
import useRoleBasedAccess from 'src/app/utils/customHook';
import { authRoles } from 'src/app/auth';
import { useParams } from 'react-router-dom';
import DesingVsExecutionWidget from './widgets/DesingVsExecutionWidget';


const AdminStats = ({ item }) => {
  return (
    <>
      <motion.div variants={item}>
        <TestcaseWidget />
        <OpenBugsWidget />
      </motion.div>
      <motion.div variants={item}>
        <AutomatedTestWidget />
        <UsersWidget />
      </motion.div>

      <motion.div variants={item} className='sm:col-span-2'>
        <TestCaseUsersWidget />
      </motion.div>
    </>
  )
}

const NoAdminStats = ({ item }) => {
  return (
    <>
      <motion.div variants={item}>
        <TestcaseWidget />
      </motion.div>
      <motion.div variants={item}>
        <OpenBugsWidget />
      </motion.div>
      <motion.div variants={item}>
        <AutomatedTestWidget />
      </motion.div>
      <motion.div variants={item}>
        <UsersWidget />
      </motion.div>
    </>
  )
}

function HomeTab() {
  const { hasRequiredRole } = useRoleBasedAccess(authRoles.admin, []);
  const params = useParams()

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-24 w-full min-w-0 p-24'
      variants={container}
      initial='hidden'
      animate='show'
    >
      {hasRequiredRole && !params?.projectId ? <AdminStats item={item} /> : <NoAdminStats item={item} />}
      <motion.div variants={item} className='sm:col-span-2 md:col-span-4'>
        <TestCaseStatusWidget />
      </motion.div>

      <motion.div variants={item} className='col-span-4 mt-24'>
        <DesingVsExecutionWidget />
      </motion.div>

    </motion.div>
  );
}

export default HomeTab;
