import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import reducer from "../store";
import withReducer from "app/store/withReducer";
import { styled } from "@mui/material/styles";
import FusePageSimple from "@fuse/core/FusePageSimple";
import ProjectHeader from "./ProjectHeader";
import ProjectContent from "./ProjectContent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useDispatch } from "react-redux";
import { getProject, getProjectStatus } from "../store/projectSlice";
import { useParams } from "react-router-dom";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
  },
}));

const schema = yup.object().shape({
  name: yup.string().required("You must enter a Name"),
  abbreviation: yup
    .string()
    .required("You must enter a Abbreviation")
    .max(5, "The Abbreviation must contains maximun 5 characters")
    .min(2, "The Abbreviation must contains minimum 2 characters")
    .matches(/^[A-Za-z]+$/, "Only letters are allowed"),
  status: yup.object().nullable().required("You must enter a Status"),
});

const ProjectApp = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const { reset } = methods;

  useEffect(() => {
    dispatch(getProjectStatus());
    if (projectId) {
      dispatch(getProject(projectId)).then((res) => {
        reset(res.payload);
      });
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <Root
        header={<ProjectHeader />}
        content={<ProjectContent />}
        scroll="content"
      />
    </FormProvider>
  );
};

export default withReducer("projectApp", reducer)(ProjectApp);
