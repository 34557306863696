import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import FusePageSimple from "@fuse/core/FusePageSimple";
import reducer from "../store";
import withReducer from "app/store/withReducer";
import DocumentHeader from "./DocumentHeader";
import DocumentContent from "./DocumentContent";
import EditDocumentContent from "./EditDocumentContent";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import mockData from "./data.json";
import { useSelector } from "react-redux";
import { clearDocument, selectDocument } from "../store/documentsSlice";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
  },
}));

const Document = () => {
  const document = useSelector(selectDocument);
  const { documentId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearDocument());
    };
  }, []);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      ...document.body,
      guidelines: document.body?.guidelines || mockData.guidelines,
    },
  });

  return (
    <FormProvider {...methods}>
      <Root
        header={<DocumentHeader />}
        content={<EditDocumentContent />}
        scroll="content"
      />
    </FormProvider>
  );
};

export default withReducer("documentApp", reducer)(Document);
