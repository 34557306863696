
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwtService from 'src/app/auth/services/jwtService';

// First, create the thunk
export const getSuites = createAsyncThunk(
  'suitsApp/getSuits',
  async ({ projectID, isTree, testRunKey = "" }, { dispatch }) => {
    try {
      let response = {};
      if (testRunKey) {
        response = await jwtService.GET(`/testrun/${testRunKey}/suites`, dispatch);
        response = response.data;
      } else {
        response = await jwtService.getSuites(projectID, isTree);
        response = response.result;
      }

      const data = response.map((item) => {
        return {
          id: item.id,
          parent: item.parent_id ? item.parent_id : 0,
          text: item.name,
          droppable: item.is_module,
          data: { ...item },
        }
      })
      return data;
    } catch (err) { return err }
  }
)

export const getSuitesNoTree = createAsyncThunk(
  'suitsApp/getSuitsNoTree',
  async (projectID) => {
    const response = await jwtService.getSuites(projectID, false);
    const data = response.result.map((item) => {
      return {
        ...item,
        label: item.name,
      }
    })
    return data;
  }
)


const suitsSlice = createSlice({
  name: 'suitsApp',
  initialState: {
    suits: [],
    suitsNoTree: [],
  },
  reducers: {
    refreshSuits: (state, action) => {
      state.suits = action.payload;
    },
  },
  extraReducers: {
    [getSuites.fulfilled]: (state, action) => { state.suits = action.payload },
    [getSuitesNoTree.fulfilled]: (state, action) => { state.suitsNoTree = action.payload },
  },
});

export const { refreshSuits } = suitsSlice.actions;
export default suitsSlice.reducer;