import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwtService from 'src/app/auth/services/jwtService';

export const getTestRunStatus = createAsyncThunk(
    'testRunApp/getTestRunStatus',
    async () => {
        const response = await jwtService.getTypes('testrun_status');
        return response.data;
    }
)

export const createTestRun = createAsyncThunk(
    'testRunApp/createTestRun',
    async (data, { dispatch }) => {
        const response = await jwtService.POST(`/testrun`, data, dispatch)
        return response;
    }
)

export const updateTestRun = createAsyncThunk(
    'testRunApp/updateTestRun',
    async (data, { dispatch }) => {
        const response = await jwtService.PUT(`/testrun/${data.id}`, data, dispatch)
        return response;
    }
)

export const deleteTestRun = createAsyncThunk(
    'testsApp/deleteTestRun',
    async (id, { dispatch }) => {
        const response = await jwtService.DELETE(`/testrun/${id}`, dispatch)
        return response;
    }
)

export const getTestRun = createAsyncThunk(
    'testRunApp/getTestRun',
    async (testRunKey, { dispatch }) => {
        const response = await jwtService.GET(`/testrun/${testRunKey}`, dispatch)
        return response;
    }
)



const testRunSlice = createSlice({
    name: 'testRunApp',
    initialState: {
        testRunStatus: [],
        currentTestRun: {},
        assignTestModalState: false,
        filterSidebarOpen: false
    },
    reducers: {
        openAssignTestModal: (state, action) => {
            state.assignTestModalState = action.payload;
        },
        setCurrentTestRun: (state, action) => {
            state.currentTestRun = action.payload;
        },
        filterSidebarOpen: (state, action) => {
            state.filterSidebarOpen = action.payload;
        },
    },
    extraReducers: {
        [getTestRunStatus.fulfilled]: (state, action) => {
            state.testRunStatus = action.payload
        },
        [getTestRun.fulfilled]: (state, action) => {
            state.currentTestRun = action.payload.data
        },
    },
});

export const selectTestRunStatus = ({ testRunApp }) => testRunApp.testRunSlice.testRunStatus;
export const selectAssignTestModalState = ({ testRunApp }) => testRunApp.testRunSlice.assignTestModalState;
export const selectCurrentTestRun = ({ testRunApp }) => testRunApp.testRunSlice.currentTestRun;
export const selectFilterSidebarOpen = ({ testRunApp }) => testRunApp.testRunSlice.filterSidebarOpen;

export const {
    openAssignTestModal,
    setCurrentTestRun,
    filterSidebarOpen } = testRunSlice.actions;
export default testRunSlice.reducer;
