import withRouter from "@fuse/core/withRouter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import FuseScrollbars from "@fuse/core/FuseScrollbars";
import UsersTableHead from "./UsersTableHead";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import FuseUtils from "@fuse/utils";
import { authRoles } from "src/app/auth";
import { motion } from "framer-motion";
import {
  getUsers,
  selectUsersSearchText,
  selectUsers,
} from "../store/usersSlice";
import FuseLoading from "@fuse/core/FuseLoading";
import _ from "@lodash";
import {
  Button,
  Chip,
  IconButton,
  TablePagination,
  Typography,
} from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { useSortBy } from "src/app/utils/customHook";
import { selectUser } from "app/store/userSlice";

function UsersTable(props) {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers) || [];
  const searchText = useSelector(selectUsersSearchText);
  const [loading, setLoading] = useState(true);
  const currentUser = useSelector(selectUser);

  const [sort, setSort] = useSortBy({
    direction: "asc",
    id: "email",
    sortBy: "email",
  });

  const [page, setPage] = useState(0);
  const [sizePage, setSizePage] = useState(10);

  // useEffect(() => {
  //   dispatch(getUsers()).then(() => setLoading(false));
  // }, []);

  useEffect(() => {
    dispatch(getUsers({ sort: sort.sortBy, searchText, sizePage, page })).then(
      () => setLoading(false)
    );
  }, [searchText, sort, sizePage, page]);

  function handleRequestSort(event, property) {
    setSort({
      direction: "desc",
      id: property,
    });
  }

  function handleClick(item) {
    if (!handlePermission(item)) return;
    props.navigate(`/system-settings/users/${item.id}`);
  }

  function handleChangePage(event, value) {
    setPage(value);
  }

  function handleChangeRowsPerPage(event) {
    setSizePage(event.target.value);
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full text-white">
        <FuseLoading />
      </div>
    );
  }

  if (users.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-1 items-center justify-center h-full text-white"
      >
        <Typography color="text.white" variant="h5">
          There are no users!
        </Typography>
      </motion.div>
    );
  }

  function handlePermission(item) {
    if (
      currentUser.role == authRoles.tester[1] &&
      item.role.name == authRoles.admin[0]
    )
      return false;
    return true;
  }

  return (
    <div className="w-full flex flex-col min-h-full "  > 
      <FuseScrollbars className="grow overflow-x-auto  " >
        <Table stickyHeader className="min-w-xl text-white  " aria-labelledby="tableTitle ">
          <UsersTableHead
            // selectedOrderIds={selected}
            order={sort}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={users.length}
            // onMenuItemClick={handleDeselect}
          />
          
          <TableBody>
            {users.map((n) => {
              return (
                <TableRow
                  sx={{
                    "&:last-child th": {
                      borderBottom: 1,
                      borderColor: "#e2e8f0",
                    },
                  }}
                  className="h-72 cursor-pointer "
                  disabled={true}
                  hover={handlePermission(n)}
                  key={n.id}
                  onClick={(event) => handleClick(n)}
                  tabIndex={-1}
                >
                  <TableCell
                    className="p-4 md:p-20 border-t-1"
                    component="th"
                    scope="row"
                  >
                    {n.email}
                  </TableCell>
                  <TableCell
                    className="p-4 md:p-16 truncate border-t-1 "
                    component="th"
                    scope="row"
                  >
                    {`${n.first_name} ${n.last_name}`}
                  </TableCell>
                  <TableCell
                    className="p-4 md:p-16 border-t-1 "
                    component="th"
                    scope="row"
                  >
                    {n.job_title.name}
                  </TableCell>
                  <TableCell
                    className="p-4 md:p-16 border-t-1"
                    component="th"
                    scope="row"
                  >
                    {n.role.name}
                  </TableCell>
                  <TableCell
                    className="p-4 md:p-16 border-t-1 "
                    component="th"
                    scope="row"
                  >
                    {n.is_active ? (
                      <FuseSvgIcon className="text-green" size={20}>
                        heroicons-outline:check-circle
                      </FuseSvgIcon>
                    ) : (
                      <FuseSvgIcon className="text-red" size={20}>
                        heroicons-outline:minus-circle
                      </FuseSvgIcon>
                    )}
                  </TableCell>
                  {/* <TableCell
                    className="w-40 md:w-64 text-center pr-20 border-t-1"
                    component="th"
                  >
                    <div className="flex items-center">
                      <IconButton>
                        <FuseSvgIcon>material-outline:edit</FuseSvgIcon>
                      </IconButton>
                      <IconButton>
                        <FuseSvgIcon>material-outline:delete</FuseSvgIcon>
                      </IconButton>
                    </div>
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </FuseScrollbars>
      <TablePagination
        className="shrink-0 border-t-1 "
        component="div"
        count={users.length}
        rowsPerPage={sizePage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default withRouter(UsersTable);
