import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectTestCaseTypes } from "../../../store/testSlice";

const sortByName = (data) => {
  const order = ["Scenario", "BDD", "Tests"];
  const sortedData = [...data].sort((a, b) => {
    let indexA = order.indexOf(a.name);
    let indexB = order.indexOf(b.name);

    if (indexA === -1) indexA = order.length;
    if (indexB === -1) indexB = order.length;

    return indexA - indexB;
  });
  return sortedData;
};

const TestcaseTypeOptions = (props) => {
  const { control } = props;
  const testcaseTypes = sortByName(useSelector(selectTestCaseTypes));

  return (
    <Controller
      name="template_id"
      className="mt-8"
      control={control}
      defaultValue={[]}
      render={({ field }) => (
        <RadioGroup {...field} row>
          {sortByName(testcaseTypes).map((template) => (
            <FormControlLabel
              key={template.id}
              value={template.id}
              control={<Radio />}
              label={template.name}
            />
          ))}
        </RadioGroup>
      )}
    />
  );
};

export default TestcaseTypeOptions;
