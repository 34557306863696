import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { AddSplitterCard } from "./AddSplitterCard";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SplitterList from "./SplitterList";
import { getSplitters } from "../../store/splitterSlice";
import { FormProvider, useForm } from "react-hook-form";

export const SplitterTab = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const methods = useForm();

  useEffect(() => {
    dispatch(getSplitters(projectId));
  }, []);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="w-full"
    >
      <FormProvider {...methods}>
        <div className="flex gap-20">
          <div className=" w-1xl">
            <AddSplitterCard />
          </div>
          <div className="w-full">
            <SplitterList />
          </div>
        </div>
      </FormProvider>
    </motion.div>
  );
};
