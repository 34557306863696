import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Paper } from "@mui/material";
import { TestView } from "./TestView";
import TestResults from "./header/test-form/form-preview/TestResults";
import { getTestById } from "./store/testSlice";

const TestCaseContentView = () => {
  const { testId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTestById(testId))
  }, [testId]);

  return (
    <div className="grid grid-cols-12 w-full gap-20 px-36 py-24">
      <Paper className="col-span-10 md:grid-cols-8 lg:col-span-8 p-24 shadow rounded-2xl overflow-hidden">
        <TestView />
      </Paper>
      <Paper className="col-span-10 md:grid-cols-4 lg:col-span-4 shadow rounded-2xl max-h-[60vh] overflow-y-auto px-10">
        <TestResults testId={testId} />
      </Paper>
    </div>
  )

}

export default TestCaseContentView;