import { useMemo, useState, useEffect } from "react";
import withRouter from "@fuse/core/withRouter";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { DndProvider } from "react-dnd";
import {
  Tree,
  MultiBackend,
  getBackendOptions,
} from "@minoru/react-dnd-treeview";
import { CustomNode } from "./CustomNode";
import { useSelector } from "react-redux";
import SuiteEmptyState from "./SuiteEmptyState";
import { useDispatch } from "react-redux";
import { closeDialog, openDialog } from "app/store/fuse/dialogSlice";
import ConfirmDialog from "app/shared-components/ConfirmDialog";
import {
  selectCurrentSuite,
  setCurrentSuite,
  updateSuite,
} from "../store/suiteSlice";
import { getSuites } from "../store/suitesSlice";
import { setTestsLoading } from "../store/testsSlice";
import { buildTreeMap, getSuiteParents } from "src/app/utils/SuiteTreePath";

const addAllSuiteOption = (suites) => {
  return [
    {
      id: "all",
      parent: 0,
      text: "All Test cases",
      droppable: false,
      data: {
        id: "all",
        is_module: false,
        name: "All Test cases",
      },
    },
    ...suites,
  ];
};

export const SuiteTreeView = (props) => {
  const { suits = [] } = useSelector((state) => state.suitesApp.suites);
  const [treeData = [], setTreeData] = useState(addAllSuiteOption(suits));
  const dispatch = useDispatch();
  const { projectId, suiteId } = useParams();
  const [selectedNode, setSelectedNode] = useState();
  const {
    isLink = true,
    actionButton,
    droppable = true,
    urlPath = `/tests/project/${projectId}`,
  } = props;

  const navigate = useNavigate();
  const suite = useSelector(selectCurrentSuite);

  useEffect(() => {
    setSelectedNode({ id: suite.id });
  }, []);

  // Index for the suits tree - easy to read
  const suitesMap = useMemo(() => buildTreeMap(suits), [suits]);

  // Recursively gets the parents of the suiteId
  const initialOpen = useMemo(() => getSuiteParents(suiteId, suitesMap), [suiteId, suitesMap]);

  const handleSelect = (e, node) => {
    setSelectedNode(node);

    //avoid multiple clicks on selected suite
    if (node.id === selectedNode?.id) return;

    dispatch(setTestsLoading(true));
    let path = `${urlPath}/suites/${node.id}`;
    dispatch(setCurrentSuite(node.data));
    setSelectedNode(node);
    if (isLink == true) {
      navigate(path);
    }
  };

  const confirmMove = (newTree, origin, target) => {
    const data = {
      parent_id: target ? target.id : null,
      project_id: origin.data.project_id,
      is_module: origin.data.is_module,
      id: origin.id,
      children: origin.data.children,
    };
    dispatch(updateSuite(data)).then((resp) => {
      setTreeData(newTree);
      dispatch(getSuites({ projectID: origin.data.project_id, isTree: false }));
      dispatch(closeDialog());
    });
  };

  const handleDrop = (newTree, options) => {
    if (!droppable) return;
    const { dragSource, dropTarget } = options;
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title={`Confirm Move`}
            confirmButton={true}
            confirmButtonLabel={`Confirm`}
            confirmAction={() => confirmMove(newTree, dragSource, dropTarget)}
            message={`Hey! Ready to move "${dragSource.text}" into "${dropTarget ? dropTarget.text : "Top Level Suites"
              }"? Confirm if you're all set!`}
            status="info"
          />
        ),
      })
    );
  };

  useEffect(() => {
    setTreeData(addAllSuiteOption(suits));
  }, [suits]);

  return (
    <>
      {suits.length ? (
        <DndProvider
          className="h-full"
          backend={MultiBackend}
          options={getBackendOptions()}
        >
          <div className="grow h-full">
            <Tree
              tree={treeData}
              rootId={0}
              classes={{
                draggingSource: "my-dragover-classname",
                dropTarget: "my-dragover-classname",
                root: "pb-100 pt-20",
              }}
              onDrop={handleDrop}
              render={(node, { depth, isOpen, onToggle }) => (
                <CustomNode
                  actionButton={actionButton}
                  node={node}
                  depth={depth}
                  isOpen={isOpen}
                  isSelected={node.id === selectedNode?.id}
                  onToggle={onToggle}
                  onSelect={handleSelect}
                />
              )}
              sort={false}
              initialOpen={initialOpen}
              insertDroppableFirst={false}
              enableAnimateExpand={true}
            />
          </div>
        </DndProvider>
      ) : (
        <SuiteEmptyState className="text-center w-full items-center" />
      )}
    </>
  );
};

export default withRouter(SuiteTreeView);
