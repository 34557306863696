import * as React from "react";
import ai_start_outline from './ai_starts_outline.png';
import ai_stars from './ai_stars.png';
import ai_stars_white from './ai_stars_white.png';

export default function AiIcon({ variant = "default", size }) {

  const [image, setImage] = React.useState(variant)

  React.useEffect(() => {

    if (variant == 'outline')
      setImage(ai_start_outline)
    else if (variant == 'startWhite')
      setImage(ai_stars_white)
    else
      setImage(ai_stars)

  }, [])




  return (
    <img
      width={size}
      height={size}
      alt='AI Stars'
      src={image}
      loading="lazy"
    />
  )



  // return (
  //   <>
  //     {loading ? (
  //       <SvgIcon style={{ width: 35, height: 35 }}>
  //         <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
  //           <circle
  //             cx="50"
  //             cy="50"
  //             r="32"
  //             strokeWidth="10"
  //             stroke="#55c89d"
  //             strokeDasharray="50.26548245743669 50.26548245743669"
  //             fill="none"
  //             strokeLinecap="round"
  //           >
  //             <animateTransform
  //               attributeName="transform"
  //               type="rotate"
  //               dur="1.923076923076923s"
  //               repeatCount="indefinite"
  //               keyTimes="0;1"
  //               values="0 50 50;360 50 50"
  //             ></animateTransform>
  //           </circle>
  //           <circle
  //             cx="50"
  //             cy="50"
  //             r="21"
  //             strokeWidth="10"
  //             stroke="#204651"
  //             strokeDashoffset="32.98672286269283"
  //             strokeDasharray="32.98672286269283 32.98672286269283"
  //             fill="none"
  //             strokeLinecap="round"
  //           >
  //             <animateTransform
  //               attributeName="transform"
  //               type="rotate"
  //               dur="1.923076923076923s"
  //               repeatCount="indefinite"
  //               keyTimes="0;1"
  //               values="0 50 50;-360 50 50"
  //             ></animateTransform>
  //           </circle>
  //         </svg>
  //       </SvgIcon>
  //     ) : (
  //       <SvgIcon style={{ width: 35, height: 35 }}>
  //         {/* credit: plus icon from https://heroicons.com/ */}
  //         <svg
  //           viewBox="0 0 100 100"
  //           fill="none"
  //           preserveAspectRatio="xMidYMid"
  //           style={{ marginRight: -2, display: "block" }}
  //         >
  //           <circle
  //             cx="50"
  //             cy="50"
  //             r="32"
  //             strokeWidth="10"
  //             stroke={disabled ? "#bcbcbc" : "#55c89d"}
  //             strokeDasharray="50.26548245743669 50.26548245743669"
  //             fill="none"
  //             strokeLinecap="round"
  //             transform="matrix(1,0,0,1,0,0)"
  //             // style="transform:matrix(1, 0, 0, 1, 0, 0);animation-play-state:paused"
  //           ></circle>
  //           <circle
  //             cx="50"
  //             cy="50"
  //             r="21"
  //             strokeWidth="10"
  //             stroke={disabled ? "#999999" : "#204651"}
  //             strokeDasharray="32.98672286269283 32.98672286269283"
  //             strokeDashoffset="32.98672286269283"
  //             fill="none"
  //             strokeLinecap="round"
  //             transform="matrix(1,0,0,1,0,0)"
  //           ></circle>
  //         </svg>
  //       </SvgIcon>
  //     )}
  //   </>
  // );
}
