import React from "react";
import { Avatar, Card, CardActionArea, Typography } from "@mui/material";

export const JiraCard = (props) => {
  const { name, base_url, email, is_active } = props.integration;
  return (
    <Card className="transition min-w-440 grow max-w-440 flex-auto m-10 hover:scale-105  duration-700">
      <div className="flex flex-col px-8 cursor-pointer">
        <div className="flex items-left p-28">
          <Avatar
            className="flex-0 w-72 h-72 mr-20"
            alt="Jira"
            src="./assets/images/brands/jira_logo.png"
          ></Avatar>
          <div className="flex flex-col ml-6">
            <Typography className="text-2xl text-gray-700 font-semibold leading-tight">
              {name}
            </Typography>
            <Typography className="text-secondary font-medium mb-1">
              {base_url}
            </Typography>
            <Typography className="text-secondary font-medium mb-1">
              {email}
            </Typography>
            {/* <StatusBadge
                    key="{item.status}"
                    status={item.status}
                  ></StatusBadge> */}
          </div>
        </div>
      </div>
    </Card>
  );
};
