import { memo } from 'react';
import QuickPanel from '../../shared-components/quickPanel/QuickPanel';
import NotificationPanel from '../../shared-components/notificationPanel/NotificationPanel';
import AIChatPanel from 'app/shared-components/ai-chatpanel/AIChatPanel';
import { useParams } from 'react-router-dom';

function RightSideLayout1(props) {

  const params = useParams()
  return (
    <>
      <AIChatPanel />
      <QuickPanel />

      <NotificationPanel />
    </>
  );
}

export default memo(RightSideLayout1);
