import { Badge, IconButton, Tooltip } from "@mui/material";
import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useSelector } from "react-redux";

const FilterDrawerButton = (props) => {
  const { onClick } = props;
  const { queryParams } = useSelector((state) => state.filter);

  return (
    <Tooltip title="Filter tests">
      <IconButton onClick={onClick} aria-label="delete">
        <FilterListIcon />
      </IconButton>
    </Tooltip>
  );
};

export default FilterDrawerButton;
