import FuseNavigation from '@fuse/core/FuseNavigation';
import FuseUtils from '@fuse/utils/FuseUtils';
import clsx from 'clsx';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNavigationItem, resetNavigation, selectNavigation, updateNavigationItem } from 'app/store/fuse/navigationSlice';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import { useLocalStorage } from 'src/app/utils/customHook';

function Navigation(props) {
  const navigation = useSelector(selectNavigation);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const dispatch = useDispatch();
  const [selectedProject, setSelectedProject] = useLocalStorage('selected_project')

  const path = window.location.pathname

  useEffect(() => {

    if (!FuseUtils.isObjectEmpty(selectedProject)) {
      dispatch(updateNavigationItem('project_group', {
        title: selectedProject.name,
        subtitle: 'Everything you need to QA Management',
      }));

      //Activate children menu of project
      const projectNav = dispatch(getNavigationItem('project_group'));
      projectNav.children.forEach((item) => {
        let url = item.url;
        if (item.id == 'project-setting')
          url = `/projects/${selectedProject.id}/settings`
        else if (item.id == 'tests-item')
          url = `/tests/project/${selectedProject.id}/suites/all`
        else if (item.id == 'dashboard-item')
          url = `/projects/${selectedProject.id}/dashboard`
        else if (item.id == 'tests-run-item')
          url = `/project/${selectedProject.abbreviation}/testruns`
        else if (item.id == 'document-item')
          url = `/projects/${selectedProject.abbreviation}/documents`

        dispatch(updateNavigationItem(item.id, {
          disabled: false,
          url: url
        }));
      });
    } else {
      dispatch(resetNavigation())
    }
  }, []);

  function handleItemClick(item) {

    dispatch(updateNavigationItem('tests-item', { active: false }));

    if (isMobile)
      dispatch(navbarCloseMobile());
  }

  return (
    <FuseNavigation
      className={clsx('navigation', props.className)}
      navigation={navigation}
      layout={props.layout}
      dense={props.dense}
      active={props.active}
      onItemClick={handleItemClick}
    />
  );
}

Navigation.defaultProps = {
  layout: 'vertical',
};



export default memo(Navigation);
