import { useEffect, useState, useMemo } from 'react';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { getSuites, getSuitesNoTree } from '../store/suitesSlice';
import { openSuiteDialog } from '../store/suiteSlice'
import { useDispatch, useSelector } from 'react-redux';
import { SuiteTreeView } from './SuiteTreeView';
import FuseLoading from '@fuse/core/FuseLoading';
import { isAdmin } from 'app/store/userSlice';
import SuiteNotAllowed from './SuiteNotAllowed';
import { util } from 'prettier';
import useRoleBasedAccess from 'src/app/utils/customHook';
import { authRoles } from 'src/app/auth';
import { Divider, IconButton, Tooltip } from '@mui/material';
import FolderIcon from "@mui/icons-material/Folder";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import SystemDialog from 'app/shared-components/SystemDialog';
import ConfirmDialog from 'app/shared-components/ConfirmDialog';
import { fontFamily } from '@mui/system';
import { resetNavigation } from 'app/store/fuse/navigationSlice';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { useLocalStorage } from 'src/app/utils/customHook';

export const SuiteNavigation = (props) => {

    const routeParams = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [isAllowed, setAllowed] = useState(false);
    const { suits = [] } = useSelector(state => state.suitesApp.suites);
    const [selectedProject, _] =
    useLocalStorage("selected_project");
    const { projectId: projectIdParam, projectKey } = routeParams;
    const admin = useSelector(isAdmin);
    const { hasRequiredRole } = useRoleBasedAccess(authRoles.external_tester, []);
    const navigate = useNavigate();


    const gotToProjetsPage = () => {
        navigate('/projects')
        dispatch(closeDialog());
    }

    const projectId = useMemo(() => {
        if (projectKey) {
            return selectedProject?.id
        }

        return projectIdParam;
    }, [projectKey, projectIdParam])

    useEffect(() => {
        dispatch(getSuites({ projectID: projectId, isTree: false }))
            .then((res) => {
                if (res.payload.response?.status === 403) {
                    setAllowed(false);
                    dispatch(resetNavigation())
                    dispatch(openDialog({
                        children: (<ConfirmDialog
                            title="Permission Denied"
                            message={res.payload.response.data.detail}
                            confirmButton={true}
                            cancelButton={false}
                            confirmButtonLabel="Go to Projects page"
                            confirmAction={gotToProjetsPage}
                        />),
                    }));
                }
                else { setAllowed(true); }
                setLoading(false)
            })
    }, [])


    const handleSuiteDialog = (isModule) => {
        dispatch(openSuiteDialog({ projectId: projectId, open: true, isModule, isNew: true }));
    }

    if (loading) {
        return (
            <div className="flex items-center justify-center h-full">
                <FuseLoading />
            </div>
        );
    }
    if (isAllowed)
        return (
            <div className="p-10 flex flex-col">
                <div className="sticky top-0 z-9999 flex w-full  bg-white py-10" >
                    <Typography color="text.secondary" className="text-3xl  items-center flex-grow font-bold tracking-tight">
                        Test Suites
                    </Typography>
                    {hasRequiredRole && <div className="flex flex-row justify-center gap-5  items-center text-center">
                        <Tooltip title="Add Module">
                            <IconButton onClick={() => handleSuiteDialog(true)} variant="contained">
                                <CreateNewFolderIcon />
                            </IconButton>

                        </Tooltip>
                        <Tooltip title="Add Suite">
                            <IconButton onClick={() => handleSuiteDialog(false)} variant="contained">
                                <NoteAddIcon />
                            </IconButton>
                        </Tooltip>

                    </div>}

                </div>
                <Divider />
                <div className='flex h-full'>
                    <SuiteTreeView suites={suits}  ></SuiteTreeView>
                </div>
            </div >

        )

}


