import { useSelector } from "react-redux";
import {
  selectCurrentSuite,
  setCurrentSuite,
} from "src/app/main/testcase/store/suiteSlice";
import { useDispatch } from "react-redux";
import {
  getTestsBySuiteId,
  selectTests,
  selectTestsLoading,
  setTestsLoading,
} from "src/app/main/testcase/store/testsSlice";
import { useEffect, React } from "react";
import { TestCaseList } from "src/app/main/testcase/content/TestCaseList";
import { setPageCount, setSimplePagination } from "app/store/paginationSlice";
import FuseUtils from "@fuse/utils/FuseUtils";
import { selectFilterParams } from "app/store/filterSlice";
import { useLocalStorage } from "src/app/utils/customHook";

const SelectTestContent = (props) => {
  const testCases = useSelector(selectTests);
  const selectedSuite = useSelector(selectCurrentSuite);
  const [selectedProject, setSelectedProject] =
    useLocalStorage("selected_project");
  const dispatch = useDispatch();
  const filters = useSelector(selectFilterParams);

  useEffect(() => {
    return () => {
      dispatch(setSimplePagination({ page: 1, size: 20 }));
      dispatch(setCurrentSuite({}));
    };
  }, []);

  const getTests = (suiteId, page = 1, filters = {}) => {
    let data = {
      filters,
      pagination: { page, size: 20 },
    };

    data =
      suiteId == "all"
        ? { ...data, projectId: selectedProject.id }
        : { ...data, suiteId };

    dispatch(getTestsBySuiteId({ ...data })).then((res) => {
      const paginate = res.payload.paginate;
      dispatch(setTestsLoading(false));
      dispatch(setPageCount(paginate.total_pages));
    });
  };

  const onFilterOrPaginationChange = (suiteId, page, filters) => {
    getTests(suiteId, page, filters);
  };

  return (
    <TestCaseList
      onChange={onFilterOrPaginationChange}
      tests={testCases}
      showSubTitle={false}
      actionButtons={false}
    />
  );
};

export default SelectTestContent;
