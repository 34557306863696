import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Input, Paper } from "@mui/material";
import React from "react";

const SearchInputText = (props) => {
  const { motion, placeholder, value, onKeyDown, onChange } = props;

  return (
    <Paper
      component={motion.div}
      initial={{ y: -5, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
      className="flex items-center w-full sm:max-w-256 space-x-8 px-16 rounded-full border-1 shadow-0"
    >
      <FuseSvgIcon color="disabled">heroicons-solid:search</FuseSvgIcon>
      <Input
        placeholder={placeholder}
        className="flex flex-1"
        disableUnderline
        onKeyDown={onKeyDown}
        fullWidth
        value={value}
        inputProps={{
          "aria-label": "Search",
        }}
        onChange={onChange}
      />
    </Paper>
  );
};

export default SearchInputText;
