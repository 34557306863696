import {
  TabContext,
  TabList,
  TabPanel,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
  Timeline,
} from "@mui/lab";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import { Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getTestResults, selectTestResults } from "../../../store/testSlice";
import { dateFormatter } from "../../../../../utils/Formatter";
import ResultEmptyState from "./ResultEmptyState";
import FuseLoading from "@fuse/core/FuseLoading";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const TestResults = ({ testId }) => {
  const testResults = useSelector(selectTestResults);
  const [value, setValue] = useState("0");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getTestResults(testId)).then(() => setLoading(false));
  }, [testId]);

  const setColor = (color) => ` text-${color}-800`;
  const setBgColor = (color) => `bg-${color}-800`;

  return (
    <TabContext value={value}>
      <TabList
        sx={{ borderBottom: 1, borderColor: "divider" }}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab label="Status History" value="0" />
      </TabList>

      <TabPanel className="p-0 mb-20" value={value}>
        <div>
          {loading ? (
            <div className="flex w-full items-center justify-center h-full">
              <FuseLoading showTitle={false} />
            </div>
          ) : (
            <>
              {testResults.length == 0 ? (
                <ResultEmptyState className="mb-20"></ResultEmptyState>
              ) : (
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  {testResults.map((item) => (
                    <TimelineItem key={item.id}>
                      <TimelineSeparator>
                        <TimelineDot
                          className={`${setBgColor(item.status.color)}`}
                        />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography>
                          {item.updated_by.first_name}{" "}
                          {item.updated_by.last_name}
                        </Typography>
                        <Typography className="font-300 text-12">
                          {dateFormatter(item.updated_at)}
                        </Typography>
                        <Typography
                          className={`text-12 ${setColor(item.status.color)}`}
                        >
                          {item.status.name}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              )}
            </>
          )}
        </div>
      </TabPanel>
    </TabContext>
  );
};

export default TestResults;
