import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { createDocument, updateDocument } from "../store/documentsSlice";
import { showMessage } from "app/shared-components/ShowMessage";

const DocumentHeader = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const methods = useFormContext();
  const { trigger, getValues } = methods;
  const { projectKey, documentId, viewMode } = useParams();
  const navigate = useNavigate();

  const save = async () => {
    const isValid = await trigger();
    const values = getValues();
    const data = {
      ...values,
      project_key: projectKey,
      body: values,
      name: values.report_title,
      description: values.product_description,
      is_vpat: 1,
    };

    if (isValid) {
      let response = documentId ? update(data) : create(data);
      response.then((res) => {
        if ((res.payload.status = "success")) {
          const document = res.payload.data;
          if (viewMode == "edit" || viewMode == "new")
            navigate(
              `/projects/${projectKey}/documents/${document.id}/preview`
            );
          else navigate(`/projects/${projectKey}/documents`);
          dispatch(
            showMessage({
              message: res.payload.message,
              variant: "success",
            })
          );
        }
      });
    }
  };

  const create = async (data) => {
    return dispatch(createDocument(data));
  };

  const update = async (data) => {
    data.id = documentId;
    return dispatch(updateDocument(data));
  };

  return (
    <div className="  flex flex-col sm:flex-row flex-1 w-full items-center justify-between  py-20 px-24 md:px-32">
      <div className="flex flex-col items-center sm:items-start w-full  ">
        <Typography
          className="flex items-center sm:mb-12"
          component={Link}
          role="button"
          to={`/projects/${projectKey}/documents`}
          color="secondary"
        >
          <FuseSvgIcon size={20}>heroicons-outline:arrow-sm-left</FuseSvgIcon>
          <span className="flex mx-4 font-medium ">Documents</span>
        </Typography>

        <div className="flex items-center max-w-full">
          <Typography className="text-2xl md:text-4xl font-extrabold">
            Edit Document
          </Typography>
        </div>
      </div>

      {viewMode === "preview" && (
        <Button
          className=" mr-10"
          variant="contained"
          color="primary"
          component={Link}
          to={`/projects/${projectKey}/documents/${documentId}/edit`}
          // className="w-5/6"
          // startIcon={<FuseSvgIcon>heroicons-outline:pencil</FuseSvgIcon>}
        >
          Edit
        </Button>
      )}

      <LoadingButton
        loading={loading}
        onClick={save}
        className="whitespace-nowrap mx-4"
        variant="contained"
        color="secondary"
      >
        Save
      </LoadingButton>
    </div>
  );
};

export default DocumentHeader;
