
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';


export const getUsers = createAsyncThunk('systemSettings/users/getUsers', async ({ sort = 'id', searchText = '', sizePage = 100, page = 1 }) => {


  const response = await new Promise((resolve, reject) => {
    axios
      .get(`${jwtServiceConfig.user}?search=${searchText}&sort=${sort}&size=${sizePage}&page=${page + 1}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
  const data = response;
  return data;
});


const usersSlice = createSlice({
  name: 'systemSettingsApp/users',
  initialState: {
    searchText: '',
    users: []
  },
  reducers: {
    setUsersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getUsers.fulfilled]: (state, action) => {
      state.users = action.payload
    },
  },
});

export const selectUsers = ({ systemSettingsApp }) => systemSettingsApp.users.users.result;
export const { setUsersSearchText } = usersSlice.actions;
export const selectUsersSearchText = ({ systemSettingsApp }) => systemSettingsApp.users.searchText;
export default usersSlice.reducer;