import { useSelector } from "react-redux";
import { styled, ThemeProvider, useTheme } from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";
import { selectContrastMainTheme } from "app/store/fuse/settingsSlice";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import {
  getCreatedVsExecuted,
  selectCreatedVsExecuted,
  selectedProject,
  selectPeriod,
  setPeriod,
} from "../../../store/dashboardSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";

const Root = styled(Paper)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "& .PrivatePickersYear-root": {
    "&.Mui-selected": {
      backgroundColor: theme.palette.text.primary,
    },
  },
}));

function DesingVsExecutionWidget() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const todayDate = new Date();
  const contrastTheme = useSelector(
    selectContrastMainTheme(theme.palette.primary.main)
  );
  const selectedProjecId = useSelector(selectedProject);
  const period = useSelector(selectPeriod);
  const [datePicketValue, setDatePicketValue] = useState(todayDate);

  const series = useSelector(selectCreatedVsExecuted);

  const changedPeriod = (newValue) => {};

  const changedMonth = (value) => {
    const _projectId = selectedProjecId || projectId;
    const period = moment(value).format("YYYY-MM");
    setDatePicketValue(value);
    dispatch(getCreatedVsExecuted({ period, projectId: _projectId }));
    dispatch(setPeriod(value));
  };

  useEffect(() => {
    dispatch(setPeriod(todayDate));
    setDatePicketValue(todayDate);
  }, [projectId]);

  const chartOptions = {
    chart: {
      animations: {
        speed: 400,
        animateGradually: {
          enabled: false,
        },
      },
      fontFamily: "inherit",
      foreColor: "inherit",
      width: "100%",
      height: "100%",
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#B1FF00", "#0086FF"],
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: ["#B1FF00", "#0086FF"],
    },
    grid: {
      show: true,
      borderColor: contrastTheme.palette.divider,

      padding: {
        // top: 10,
        bottom: 10,
        left: 0,
        right: 0,
      },
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    tooltip: {
      followCursor: true,
      theme: "dark",
      x: {
        format: "MMM dd, yyyy (ddd)",
      },
      y: {
        formatter: (value) => `${value}`,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      crosshairs: {
        stroke: {
          color: contrastTheme.palette.divider,
          dashArray: 0,
          // width: 2,
        },
      },
      labels: {
        style: {
          colors: contrastTheme.palette.text.secondary,
        },
        format: "dd ddd",
      },
      tickAmount: 30,
      tooltip: {
        enabled: false,
      },
      type: "datetime",
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: contrastTheme.palette.text.secondary,
        },
      },
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: false,
      },
      // min: (min) => min - 750,
      // max: (max) => max + 250,
      tickAmount: 5,
      show: true,
    },
  };

  return (
    <ThemeProvider theme={contrastTheme}>
      <Root className="sm:col-span-2 lg:col-span-3 dark flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
        <div className="flex items-center justify-between mt-40 ml-40 mr-24 sm:mr-40">
          <div className="flex flex-col">
            <Typography className="mr-16 text-2xl md:text-3xl font-semibold tracking-tight leading-7">
              Created Vs Executed Tests
            </Typography>
            <Typography className="font-medium" color="text.secondary">
              Represents the relationship between the total number of tests
              created and the number of tests actually executed over a given
              period.
            </Typography>
          </div>
          <div className="mt-12 sm:mt-0 sm:ml-8">
            <div>
              <DatePicker
                views={["year", "month"]}
                value={datePicketValue}
                onMonthChange={(value) => {
                  changedMonth(value);
                }}
                onChange={(newValue) => {
                  changedPeriod(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-auto h-320 mb-20">
          <ReactApexChart
            options={chartOptions}
            series={series}
            type={chartOptions.chart.type}
            height={chartOptions.chart.height}
          />
        </div>
      </Root>
    </ThemeProvider>
  );
}

export default DesingVsExecutionWidget;
