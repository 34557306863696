import React from 'react'
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
const SuiteEmptyState = props => {
  return (
    <>
      <div className='flex  w-full flex-col items-center mt-96'>
        <FuseSvgIcon className="mb-20 text-grey-400" size={80}>material-outline:text_snippet</FuseSvgIcon>
        <h3 className='font-bold text-center'>No suites found</h3>
        <p className='text-center'>Add your first suite</p>
      </div>
    </>
  )
}

export default SuiteEmptyState