import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import jwtService from 'src/app/auth/services/jwtService';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';
import axios from 'axios';
import moment from 'moment';

export const getProjects = createAsyncThunk(
  'dashboardApp/getProjects',
  async () => {
    try {
      const response = await jwtService.getDashboardProjects();
      const data = [{ _id: 0, name: 'All Projects' }, ...response.data];

      return data;
    } catch (err) {
      return err;
    }
  }
);

export const getOpenBugs = createAsyncThunk(
  'dashboardApp/getOpenBugs',
  async (project_id) => {
    let url = 'jira/open-bugs';
    if (project_id) url += `?project_id=${project_id}`;
    const response = await axios.get(`${jwtServiceConfig.integration}/${url}`);

    return response.data;
  }
);

export const getSummaryStats = createAsyncThunk(
  'dashboardApp/getSummaryStats',
  async (project_id) => {
    try {
      const response = await jwtService.getSummaryStats(project_id);
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

export const getCreatedVsExecuted = createAsyncThunk(
  'dashboardApp/getCreatedVsExecuted',
  async ({ period, projectId = "" }, { dispatch }) => {
    let url = `dashboard/designexecution?period=${period}`;
    if (projectId) url += `&project_id=${projectId}`;
    const response = await jwtService.GET(url, dispatch)
    return response;
  }
);

export const getTestcaseMetrics = createAsyncThunk(
  'dashboardApp/getTestcaseByStatus',
  async (project_id) => {
    try {
      const response = await jwtService.getTestcaseMetrics(project_id);
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

export const getCreatedTests = createAsyncThunk(
  'dashboard/getCreatedTests',
  async (queryParams = {}) => {
    try {
      const response = await jwtService.getCreatedTests(queryParams);
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

const dashboardSlice = createSlice({
  name: 'dashboardApp',
  initialState: {
    projects: [],
    openBugs: {},
    createdVsExecuted: [],
    stats: null,
    testCasesMetrics: null,
    testUsersMetrics: null,
    selectedProject: null,
    period: moment(new Date()).format('YYYY-MM')
  },
  reducers: {
    resetDashboardStats: (state, action) => {
      state.stats = null;
    },
    getSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    setPeriod: (state, action) => {
      state.period = moment(action.payload).format('YYYY-MM');
    },
  },
  extraReducers: {
    [getProjects.fulfilled]: (state, action) => {
      state.projects = action.payload;
    },
    [getSummaryStats.fulfilled]: (state, action) => {
      state.stats = action.payload;
    },
    [getTestcaseMetrics.fulfilled]: (state, action) => {
      state.testCasesMetrics = action.payload;
    },
    [getCreatedTests.fulfilled]: (state, action) => {
      state.testUsersMetrics = action.payload;
    },
    [getOpenBugs.fulfilled]: (state, action) => {
      state.openBugs = action.payload.data;
    },
    [getCreatedVsExecuted.fulfilled]: (state, action) => {
      state.createdVsExecuted = action.payload.data
    },
  },
});

export const selectOpenBugs = ({ dashboardApp }) => dashboardApp.dashboard.openBugs;
export const selectedProject = ({ dashboardApp }) => dashboardApp.dashboard.selectedProject;
export const selectPeriod = ({ dashboardApp }) => dashboardApp.dashboard.period;
export const selectCreatedVsExecuted = ({ dashboardApp }) => dashboardApp.dashboard.createdVsExecuted;
export const {
  resetDashboardStats,
  getSelectedProject,
  setPeriod } = dashboardSlice.actions;
export default dashboardSlice.reducer;
