import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";

export const ResultEmptyState = (props) => {
  return (
    <>
      <div className="flex flex-col items-center w-full h-full">
        <FuseSvgIcon className="text-grey-400" size={40}>
          heroicons-outline:information-circle
        </FuseSvgIcon>

        <Typography
          className="mt-5  font-semibold tracking-tight"
          color="text.secondary"
        >
          No Results
        </Typography>

        <Typography
          className="mt-10 text-center text-200 tracking-tight"
          color="text.secondary"
        >
          Once you start update the status of this test case, historical result
          will appear here.
        </Typography>
      </div>
    </>
  );
};

export default ResultEmptyState;
