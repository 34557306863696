import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';
import JiraItengrationApp from './integrations/jira/JiraItengrationApp';
import Users from "./users/user-list/Users";
import User from './users/user/User';

const SystemSettingsConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'system-settings',
            element: <Navigate to="/system-settings/users" />,
        },
        {
            path: 'system-settings',
            auth: [...authRoles.tester],
            children: [
                {
                    path: 'users',
                    element: <Users />
                },
                {
                    path: 'users/new',
                    element: <User />
                },
                {
                    path: 'users/:userId',
                    element: <User />
                },
                {
                    path: 'integrations',
                    children: [
                        {
                            path: 'jira',
                            element: <JiraItengrationApp />
                        }
                    ]

                }
            ],
        }
    ],
};

export default SystemSettingsConfig;