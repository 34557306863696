import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import jwtService from '../../auth/services/jwtService';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required('You must enter an email'),
});

const defaultValues = {
  email: '',
};

function ForgotPassword() {
  const [isLoading, setLoading] = useState(false);
  const [alertMessage, setMessage] = useState({});

  const { control, formState, handleSubmit, setError, resetField } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;


  function onSubmit({ email }) {
    setLoading(true);
    jwtService
      .sendResetPassword(email.toLowerCase())
      .then((response) => {
        setLoading(false);
        setMessage({ type: 'success', message: 'We sent you an email to reset your password in case that your account is activated', title: 'Success' });
      })
      .catch((error) => {
        const errorRes = error.response
        const errorData = errorRes.data
        if (errorRes.status !== 200) {
          setMessage({
            type: 'error', message: errorData.message
            , title: 'Error'
          })
        }
        if (errorData?.invitation_token) {
          setMessage({ type: 'warning', message: errorData?.invitation_token[0], title: 'Warning' });
        }
        if (errorData?.email) {
          setError('email', { message: errorData?.email[0] })
        }
        setLoading(false);
      });
  }

  function renderAlertMessage({ type = 'info', title, message }) {
    return (
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity={type} onClose={() => {
          setMessage({})
          setError('email', { message: '' })
        }}>
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Stack>
    )
  }

  return (
    <div className="flex flex-col items-center flex-1 min-w-0 sm:flex-row md:items-start sm:justify-center md:justify-start">
      <Paper className="w-full h-full px-16 py-8 sm:h-auto md:flex md:items-center md:justify-end sm:w-auto md:h-full md:w-1/2 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full mx-auto max-w-320 sm:w-320 sm:mx-0">
          {!isEmpty(alertMessage) && renderAlertMessage(alertMessage)}
          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Forgot password?
          </Typography>
          <div className="flex items-baseline mt-2 font-medium">
            <Typography>Fill the form to reset your password</Typography>
          </div>

          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Email"
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <LoadingButton
              variant="contained"
              color="secondary"
              className="w-full mt-16 "
              aria-label="Reset-password"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
              loading={isLoading}
            >
              Send reset link
            </LoadingButton>
            <Typography className="mt-32 text-md font-medium" color="text.secondary">
              <span>Return to</span>
              <Link className="ml-4" to="/sign-in">
                sign in
              </Link>
            </Typography>
          </form>
        </div>
      </Paper>

      <Box
        className="relative items-center justify-center flex-auto hidden h-full p-64 overflow-hidden md:flex lg:px-112"
        sx={{ backgroundColor: '#00343d' }}
      >

        <Box
          component="svg"
          className="absolute -top-64 -right-64 opacity-20"
          sx={{ color: 'primary.light' }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
        </Box>

        <div className="relative z-10 w-full max-w-4xl">
          <div className="font-bold leading-none text-gray-100 text-7xl">
            <img src="assets/images/logo/tambora_login.png" />
          </div>
          <div className="mt-24 text-lg leading-6 tracking-tight text-gray-400">
          </div>
        </div>
      </Box>
    </div>
  );
}

export default ForgotPassword;
