import React from "react";
import { showMessage } from "app/shared-components/ShowMessage";
import { closeDialog, openDialog } from "app/store/fuse/dialogSlice";
import ConfirmDialog from "app/shared-components/ConfirmDialog";
import {
  bulkDeleteTest,
  getTestsBySuiteId,
  openTestDialog,
  updatedSelectedTestIds,
} from "../store/testsSlice";
import { selectFilterParams } from "app/store/filterSlice";
import { Button, Typography } from "@mui/material";
import useSimplePagination from "src/app/utils/hooks/paginationHook";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export const SubHeaderActions = ({ selectedTestsIds, suiteId }) => {
  const [pagination, setPagination] = useSimplePagination();
  const filters = useSelector(selectFilterParams);
  const dispatch = useDispatch();

  const bulkDelete = () => {
    dispatch(bulkDeleteTest({ testcase_ids: selectedTestsIds })).then((res) => {
      if (res.payload?.status == "success") {
        // dispatch(removeTestFromList(selectedTestsIds));
        dispatch(getTestsBySuiteId({ suiteId, filters, pagination }));
        dispatch(
          showMessage({
            message: res.payload.message,
            variant: "success",
          })
        );
        dispatch(updatedSelectedTestIds([]));
        dispatch(closeDialog());
      }
    });
  };

  const comfirmDelete = () => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Are you sure?"
            confirmButton={true}
            status="warning"
            confirmButtonLabel="Delete"
            confirmAction={() => bulkDelete()}
            message="Hey, just to double-check, are you absolutely sure you want to nix those hand-picked test cases? Poof! All the stuff inside them is going down the rabbit hole, and there's no turning back from this adventure."
          />
        ),
      })
    );
  };

  return (
    <div className="flex gap-10 items-center">
      <Typography className="text-base/6 font-medium ">
        Bulk Actions:
      </Typography>
      <Button
        color="secondary"
        className=" h-28 py-0 "
        size="small"
        variant="contained"
        onClick={() =>
          dispatch(
            openTestDialog({ testId: null, open: true, viewMode: "bulk" })
          )
        }
      >
        Edit
      </Button>
      <Button
        color="secondary"
        className=" h-28 py-0"
        size="small"
        variant="contained"
        onClick={comfirmDelete}
      >
        Delete
      </Button>
      <Button
        color="secondary"
        className=" h-28 py-0"
        size="small"
        variant="contained"
        onClick={() =>
          dispatch(
            openTestDialog({ testId: null, open: true, viewMode: "clone" })
          )
        }
      >
        Clone
      </Button>
    </div>
  );
};

export default SubHeaderActions;
