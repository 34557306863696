import FuseUtils from "@fuse/utils/FuseUtils";
import { Chip, Typography } from "@mui/material";
import React from "react";

const AppliedFilter = (props) => {
  const { filterParams } = props;
  return (
    <div className="flex mt-10">
      {!FuseUtils.isObjectEmpty(filterParams) && (
        <Typography>Filters </Typography>
      )}

      {filterParams?.search && (
        <Chip
          className="mx-5"
          label={`Search: ${filterParams?.search}`}
          size="small"
        />
      )}

      {filterParams?.status && (
        <Chip
          className="mx-5"
          label={`Status: ${filterParams?.status}`}
          size="small"
        />
      )}

      {filterParams?.automation_status && (
        <Chip
          className="mx-5"
          label={`Automation Status: ${filterParams?.automation_status}`}
          size="small"
        />
      )}

      {filterParams?.splitter && (
        <Chip
          className="mx-5"
          label={`Splitter: ${filterParams?.splitter}`}
          size="small"
        />
      )}

      {filterParams?.priority && (
        <Chip
          className="mx-5"
          label={`Priority: ${filterParams?.priority}`}
          size="small"
        />
      )}

      {filterParams?.tags && (
        <Chip
          className="mx-5"
          label={`Tags: ${filterParams?.tags}`}
          size="small"
        />
      )}
    </div>
  );
};

export default AppliedFilter;
