import React from "react";
import { Checkbox, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectedTestIds,
  selectTests,
  updatedSelectedTestIds,
} from "../store/testsSlice";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import SubHeaderActions from "./SubHeaderActions";
import TamboraUtils from "src/app/utils/TamboraUtils";

const sumLabels = (array) => {
  const sum = array.reduce((acc, testCase) => {
    // If the splitters array is empty, count as 1
    const multiplier =
      testCase.splitters.length === 0
        ? 1
        : testCase.splitters.reduce((acc, splitter) => {
            // Multiply the length of labels
            return acc * splitter.labels.length;
          }, 1); // Initialize with 1 to handle empty splitters array

    // Add the multiplier to the accumulator
    return acc + multiplier;
  }, 0); // Initialize accumulator with 0

  return sum;
};

export default function TestCaseSubHeader(props) {
  const { actionButtons = true } = props;
  const selectedTestsIds = useSelector(selectedTestIds);
  const tests = useSelector(selectTests);
  const [checkState, setCheckState] = useState("unchecked");
  const dispatch = useDispatch();
  const { suiteId } = useParams();
  const [totalTestCases, setTotalTestCases] = useState(0);

  useEffect(() => {
    if (selectedTestsIds.length === 0) {
      setCheckState("unchecked");
    } else if (
      tests.every((element) =>
        TamboraUtils.includesKeyValue(
          selectedTestsIds,
          "testCaseId",
          element.id
        )
      )
    ) {
      setCheckState("checked");
    } else if (
      tests.some((element) =>
        TamboraUtils.includesKeyValue(
          selectedTestsIds,
          "testCaseId",
          element.id
        )
      )
    ) {
      setCheckState("indeterminate");
    } else {
      setCheckState("unchecked");
    }
    setTotalTestCases(sumLabels(selectedTestsIds));
  }, [selectedTestsIds, tests]);

  useEffect(() => {
    if (suiteId) {
      dispatch(updatedSelectedTestIds([]));
    }
  }, [suiteId]);

  const handleChange = (event) => {
    let newState = "unchecked";
    let selectedIds = [];
    if (event.target.checked) {
      selectedIds = tests
        .filter(
          (test) => !selectedTestsIds.some((item) => item.testCaseId == test.id)
        )
        .map((item) => {
          return {
            testCaseId: item.id,
            splitters: item.splitters,
          };
        });
      selectedIds = selectedTestsIds.concat(selectedIds);
      newState = "checked";
    } else {
      selectedIds = selectedTestsIds.filter(
        (value1) => !tests.some((item2) => item2.id == value1.testCaseId)
      );
    }
    setCheckState(newState);
    dispatch(updatedSelectedTestIds(selectedIds));
  };

  return (
    <div className="flex w-full px-20 bg-gray-100 dark:bg-blue-gray-800 gap-5 py-5 items-center">
      <Checkbox
        checked={checkState === "checked"}
        onChange={handleChange}
        indeterminate={checkState === "indeterminate"}
        size="small"
      ></Checkbox>

      {selectedTestsIds.length > 1 && actionButtons && (
        <SubHeaderActions
          suiteId={suiteId}
          selectedTestsIds={selectedTestsIds}
        />
      )}

      {totalTestCases > 0 && (
        <div className="flex gap-5 justify-end grow">
          <Typography className=" font-bold">{totalTestCases}</Typography>
          <Typography className="text-base/6 font-medium ">
            test(s) selected
          </Typography>
        </div>
      )}
    </div>
  );
}
