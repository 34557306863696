import React from "react";
import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectCurrentSuite } from "src/app/main/testcase/store/suiteSlice";
import TestCaseSubHeader from "src/app/main/testcase/header/TestCaseSubHeader";
import AppliedFilter from "src/app/main/testcase/header/AppliedFilter";
import { selectFilterParams } from "app/store/filterSlice";

const SelectTestHeader = () => {
  const selectedSuite = useSelector(selectCurrentSuite);
  const filterParams = useSelector(selectFilterParams);

  return (
    <div className="flex flex-col">
      <div className="  flex flex-col sm:flex-row flex-1 w-full items-center justify-between  py-20 px-24 md:px-32">
        <div className="flex flex-col items-center sm:items-start w-full  ">
          <div className="flex justify-between items-center w-full max-w-full">
            <motion.div
              className="flex flex-col items-center sm:items-start min-w-0 mx-8 sm:mx-16 "
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.3 } }}
            >
              <Typography className="text-1xl md:text-2xl font-extrabold">
                {selectedSuite?.name}
              </Typography>
              <AppliedFilter filterParams={filterParams} />
            </motion.div>
          </div>
        </div>
      </div>
      <TestCaseSubHeader actionButtons={false} />
    </div>
  );
};

export default SelectTestHeader;
