import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import { Checkbox, Divider, FormControlLabel, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { TestCaseStatusSelector } from './TestCaseStatusSelector';
import { IteratorLabel } from '../header/test-form/IteratorLabel';
import { useDispatch } from 'react-redux';
import { openTestDialog, selectedTestIds } from '../store/testsSlice';
import { TestMenuOption } from './TestMenuOption';
import useRoleBasedAccess from 'src/app/utils/customHook';
import { authRoles } from 'src/app/auth';
import { StatusBadge } from 'app/shared-components/StatusBadge';
import PriorityIcon from '../PriorityIcon';
import TestCaseCheckbox from './TestCaseCheckbox';
import { useSelector } from 'react-redux';
import { array } from 'prop-types';
import TamboraUtils from 'src/app/utils/TamboraUtils';
import FuseUtils from '@fuse/utils/FuseUtils';



export const TestCaseBadges = ({ test }) => {

    const badges = test.splitters.reduce((acc, curr) => {
        acc.push(...curr.labels)

        return acc
    }, [])

    const showMore = badges.length > 3
    const labels = badges.slice(0, 3)
    const remainings = badges.length - labels.length

    return (<div key={test.id} className="flex  sm:shrink-0 flex-col grow  justify-end gap-5">

        {test.automation_status.name != "Not Automated" &&
            <div className="flex  justify-end">
                <StatusBadge key={test.automation_status} status={test.automation_status} ></StatusBadge>
            </div>
        }


        <div className="flex justify-end gap-4">
            {
                labels.map((label, index) => {
                    return (
                        <IteratorLabel key={index} label={label}  ></IteratorLabel>
                    )
                })

            }
            {showMore && <IteratorLabel key="REMining" label={`+${remainings}`}  ></IteratorLabel>}
        </div>
    </div>)
}


export const TestCaseItemList = (props) => {
    const { test, actionButtons = true, showStatus = false,
        statusIndicator, multiple } = props;
    const { hasRequiredRole } = useRoleBasedAccess(authRoles.external_tester, []);
    const dispatch = useDispatch();
    const [hover, setHover] = useState(false)
    const selectedTests = useSelector(selectedTestIds)

    return (

        <Paper key={test.id} className="hover:cursor-pointer dark:hover:bg-blue-gray-900 hover:shadow-gray-500 hover:bg-gray-50 py-7 px-10 flex gap-10  shadow rounded overflow-hidden mx-10 my-3"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>

            {
                statusIndicator &&
                <div className={`bg-${test.status.color}-100 rounded-2 w-10  relative`}>

                </div>
            }


            {hasRequiredRole && multiple && <div className="flex  justify-start items-center mr-10">
                <TestCaseCheckbox
                    checked={TamboraUtils.includesKeyValue(selectedTests, "testCaseId", test.id)}
                    splitters={test.splitters}
                    testCaseId={test.id} />
            </div>}

            {showStatus &&
                <div className="flex  justify-start items-center mr-20">
                    <TestCaseStatusSelector className="z-0" test={test} status={test.status} />
                </div>
            }

            <div className="flex justify-start gap-10 truncate"
                onClick={() => dispatch(openTestDialog({ test: test, open: true, viewMode: "view" }))} >
                <div className="flex flex-col grow w-sm truncate ">

                    <div className="flex gap-1.5">
                        <Typography className=' font-bold' variant="caption">
                            {test.code}
                        </Typography>
                        <PriorityIcon title={test.priority?.name} icon={test.priority?.icon} color={test.priority?.color} />
                    </div>
                    <Typography className="text-base/6  font-medium truncate  ">
                        {test.summary}
                    </Typography>
                </div>



                {/* <div className="hidden flex-col lg:flex   justify-start truncate">
                    <Typography className="text-12 font-medium truncate ">
                        Created by
                    </Typography>
                    <Typography className=" font-medium truncate">
                        {test.created_by_name}
                    </Typography>
                </div> */}

            </div>
            {
                hasRequiredRole && hover && actionButtons ? <TestMenuOption test={test} /> : <TestCaseBadges className="hidden" key={test.id} test={test} />
            }
        </Paper>
    )
}

