import React from 'react'
import withRouter from '@fuse/core/withRouter';
import TestCaseHeader from '../header/TestCaseHeader';
import { useDispatch, useSelector } from 'react-redux';
import { TestCaseList } from './TestCaseList';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { getTestsBySuiteId, selectTests, setTestsLoading } from '../store/testsSlice';
import { setPageCount } from 'app/store/paginationSlice';
import { clearFilters } from 'app/store/filterSlice';
import FuseUtils from '@fuse/utils/FuseUtils';
import { useLocalStorage } from 'src/app/utils/customHook';


export const TestCaseContent = (props) => {

  const testCases = useSelector(selectTests);
  const dispatch = useDispatch();
  const [selectedProject, setSelectedProject] = useLocalStorage("selected_project");

  const getTest = (suiteId, filters, page) => {

    if (!suiteId) return;

    let data = {
      filters,
      pagination: { page, size: 20 }
    }

    data = (suiteId == "all") ?
      { ...data, projectId: selectedProject.id } :
      { ...data, suiteId }

    dispatch(
      getTestsBySuiteId({ ...data })
    ).then((res) => {
      const paginate = res.payload.paginate;
      dispatch(setPageCount(paginate.total_pages))
      dispatch(setTestsLoading(false));
    })
  }


  const onChange = (suiteId, page, filters) => {
    if (FuseUtils.isObjectEmpty(filters))
      dispatch(clearFilters())
    getTest(suiteId, filters, page);
  }

  return (

    <FusePageSimple className="bg-transparent"
      header={
        <TestCaseHeader ></TestCaseHeader>}
      content={
        <div className='flex w-full justify-center h-screen pb-36 '>
          <TestCaseList
            onChange={onChange}
            tests={testCases} />
        </div>
      }
      scroll="content">
    </FusePageSimple >
  )
}

export default withRouter(TestCaseContent);