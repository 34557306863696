import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import { selectFilterParams, updateFilterParams } from "app/store/filterSlice";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import PriorityIcon from "src/app/main/testcase/PriorityIcon";
import {
  selectAutomationStatus,
  selectPriorityTypes,
  selectTestTags,
} from "src/app/main/testcase/store/testSlice";
import {
  selectTestStatus,
  setTestsLoading,
  updatedSelectedTestIds,
} from "../../main/testcase/store/testsSlice";
import { useParams } from "react-router-dom";
import { AutocompleteTag } from "../automation-tags/AutocompleteTag";
import { setSimplePagination } from "app/store/paginationSlice";

function getAttFromArray(array, att) {
  return array.map((item) => item[att]).join(",");
}

function removeEmptyAttributes(obj) {
  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      (obj[key] === null ||
        obj[key] === undefined ||
        obj[key] === "" ||
        obj[key]?.length == 0)
    ) {
      delete obj[key];
    }
  }
}

export const FilterDrawerForm = (props) => {
  const { onClose, splitters, cancelButton = true } = props;
  const { queryParams } = useSelector((state) => state.filter);
  const priorityTypes = useSelector(selectPriorityTypes);
  const statusTypes = useSelector(selectTestStatus);
  const automationStatusTypes = useSelector(selectAutomationStatus);
  const filterParams = useSelector(selectFilterParams);
  const testTags = useSelector(selectTestTags);
  const dispatch = useDispatch();
  const { suiteId } = useParams();
  const options = splitters.flatMap((option) =>
    option.labels.map((label) => ({
      id: option.id,
      code: `${option.id}-${label}`,
      name: option.name,
      label,
    }))
  );

  const {
    control,
    getValues,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      tags: queryParams?.tags?.split(","),
      priority: priorityTypes?.filter((item) =>
        queryParams?.priority?.split(",").includes(item.name)
      ),
      status: statusTypes?.filter((item) =>
        queryParams?.status?.split(",").includes(item.name)
      ),
      automation_status: automationStatusTypes?.filter((item) =>
        queryParams?.automation_status?.split(",").includes(item.name)
      ),
      splitter: options.find((item) => item.label == queryParams?.splitter),
    },
    mode: "onChange",
  });

  const applyFilter = () => {
    const value = getValues();
    dispatch(setSimplePagination({ page: 1 }));
    removeEmptyAttributes(value);

    onClose();
    if ("priority" in value)
      value.priority = getAttFromArray(value.priority, "name");

    if ("automation_status" in value)
      value.automation_status = getAttFromArray(
        value.automation_status,
        "name"
      );

    if ("status" in value) value.status = getAttFromArray(value.status, "name");

    if ("splitter" in value) value.splitter = value.splitter.label;

    if ("tags" in value) value.tags = value.tags.join(",");

    if ("search" in filterParams) value.search = filterParams.search;

    dispatch(setTestsLoading(true));
    dispatch(updateFilterParams(value));
    dispatch(updatedSelectedTestIds([]));
  };

  return (
    <form noValidate className="m-20 flex flex-col h-full">
      <div className="grow h-full">
        {/* STATUS */}
        <Controller
          name="status"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="status"
              multiple
              size="small"
              className="my-20"
              options={statusTypes}
              disablePortal
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, value) => onChange(value)}
              getOptionLabel={(item) => (item.name ? item.name : "")}
              value={value}
              renderInput={(params) => (
                <TextField {...params} label="Test Status" />
              )}
            />
          )}
        />
        {/* AUTOMATION STATUS */}
        <Controller
          name="automation_status"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="automation_status"
              multiple
              size="small"
              className="my-20"
              options={automationStatusTypes}
              disablePortal
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, value) => onChange(value)}
              getOptionLabel={(item) => (item.name ? item.name : "")}
              value={value}
              renderInput={(params) => (
                <TextField {...params} label="Automation Status" />
              )}
            />
          )}
        />
        {/* Splitter */}
        <Controller
          name="splitter"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="splitter"
              size="small"
              disablePortal
              onChange={(event, value) => onChange(value)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={value}
              options={options}
              groupBy={(option) => option.name}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label="Splitters" />
              )}
            />
          )}
        />

        {/* PRIORITY */}
        <Controller
          name="priority"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="priority"
              multiple
              size="small"
              className="my-20"
              options={priorityTypes}
              disablePortal
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, value) => onChange(value)}
              getOptionLabel={(item) => (item.name ? item.name : "")}
              value={value}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <PriorityIcon icon={option.icon} color={option.color} />
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Priority" />
              )}
            />
          )}
        />

        {/* TAGS */}
        <Controller
          name="tags"
          control={control}
          render={({ field: { onChange, value } }) => (
            <AutocompleteTag
              value={value}
              options={testTags}
              create={false}
              onChange={(event, value) => onChange(value)}
            />
          )}
        />
      </div>
      <Divider className="my-20" />

      <div className="flex justify-end gap-20">
        {cancelButton && (
          <Button
            size="small"
            onClick={() => {
              onClose();
            }}
            variant="contained"
            className="dark:bg-transparent"
          >
            Cancel
          </Button>
        )}

        <Button
          size="small"
          // disabled={!isDirty}
          onClick={applyFilter}
          type="button"
          variant="contained"
          color="secondary"
        >
          Apply filters
        </Button>
      </div>
    </form>
  );
};
