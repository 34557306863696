import _ from '@lodash';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectUser } from 'app/store/userSlice';
import {
  getCreatedTests,
  getOpenBugs,
  getProjects,
  getSummaryStats,
  getTestcaseMetrics,
  resetDashboardStats,
  getSelectedProject,
  getCreatedVsExecuted,
  selectPeriod,
} from './store/dashboardSlice';
import useRoleBasedAccess, { useLocalStorage } from 'src/app/utils/customHook';
import { IconButton } from '@mui/material';
import { exportTestCases } from '../projects/store/projectsSlice';
import { LoadingButton } from '@mui/lab';
import { authRoles } from 'src/app/auth';

function DashboardAppHeader(props) {
  const dispatch = useDispatch();
  const [loadingExport, setLoadingExport] = useState(false);
  const user = useSelector(selectUser);
  const params = useParams();
  const [currentProject, setCurrentProject] =
    useLocalStorage('selected_project');

  const period = useSelector(selectPeriod)
  const { projectId } = params;
  const { hasRequiredRole } = useRoleBasedAccess(authRoles.tester, []);

  const { projects = [] } = useSelector(
    (state) => state.dashboardApp.dashboard
  );
  const [selectedProject, setSelectedProject] = useState({
    _id: 0,
    name: 'All Projects',
    menuEl: null,
  });



  const queryOptions = {
    ...((params.projectId && { project_id: params.projectId }) ||
      (selectedProject._id !== 0 && { project_id: selectedProject._id })), // Revisar cuando se selecciona un projectId por Params
  };

  const exportTestcases = () => {
    setLoadingExport(true);
    dispatch(exportTestCases(currentProject.abbreviation)).then(() => setLoadingExport(false))
  }

  useEffect(() => {
    dispatch(resetDashboardStats());
    dispatch(getProjects());
    dispatch(getOpenBugs(projectId));
    dispatch(getSummaryStats(projectId));
    dispatch(getTestcaseMetrics(projectId));
    dispatch(getCreatedTests(queryOptions));
    dispatch(getCreatedVsExecuted({ period: moment(new Date()).format("YYYY-MM"), projectId }));
  }, [params]);

  function handleChangeProject(_id) {
    const queryOptions = {}
    setSelectedProject({
      _id,
      menuEl: null,
    });

    if (_id)
      queryOptions.project_id = _id

    dispatch(getSummaryStats(_id));
    dispatch(getTestcaseMetrics(_id));
    dispatch(getOpenBugs(_id));
    dispatch(getCreatedTests(queryOptions));
    dispatch(getSelectedProject(_id));
    dispatch(getCreatedVsExecuted({ period, projectId: _id }));
  }

  function handleOpenProjectMenu(event) {
    setSelectedProject({
      _id: selectedProject._id,
      menuEl: event.currentTarget,
    });
  }

  function handleCloseProjectMenu() {
    setSelectedProject({
      _id: selectedProject._id,
      menuEl: null,
    });
  }

  return (
    <div className='flex flex-col w-full px-24 sm:px-32'>
      <div className='flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 my-32 sm:my-32'>
        <div className='flex flex-auto items-center min-w-0 w-full justify-between'>
          <div className='flex flex-col min-w-0 mx-16'>
            <Typography className='text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate'>
              {params?.projectId
                ? currentProject.name
                : `Welcome, ${user.data.displayName}!`}
            </Typography>

            <div className='flex items-center'>
              {/*<FuseSvgIcon size={20} color="action">
                                heroicons-solid:bell
                            </FuseSvgIcon>*/}
              <Typography
                className='mx-6 leading-6 truncate'
                color='text.secondary'
              >
                Dashboard
              </Typography>
            </div>


          </div>
          {
            hasRequiredRole && <LoadingButton
              onClick={exportTestcases}
              variant='contained'
              color='secondary'
              loading={loadingExport}
            >
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"

                size="large"
              >
                <FuseSvgIcon>feather:download</FuseSvgIcon>
              </IconButton>
              Export Testcases
            </LoadingButton>
          }




        </div>
      </div>
      {!params?.projectId && (
        <div className='flex items-center'>
          <Button
            onClick={handleOpenProjectMenu}
            className='flex items-center border border-solid border-b-0 rounded-t-xl rounded-b-0 h-40 px-16 text-13 sm:text-16'
            variant='default'
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
              borderColor: (theme) => theme.palette.divider,
            }}
            endIcon={
              <FuseSvgIcon size={20} color='action'>
                heroicons-solid:chevron-down
              </FuseSvgIcon>
            }
          >
            {_.find(projects, ['_id', selectedProject._id])?.name}
          </Button>
          <Menu
            id='project-menu'
            anchorEl={selectedProject.menuEl}
            open={Boolean(selectedProject.menuEl)}
            onClose={handleCloseProjectMenu}
          >
            {projects &&
              projects.map((project) => (
                <MenuItem
                  key={project._id}
                  onClick={(ev) => {
                    handleChangeProject(project._id);
                  }}
                >
                  {project.name}
                </MenuItem>
              ))}
          </Menu>
        </div>
      )}
    </div>
  );
}

export default DashboardAppHeader;
