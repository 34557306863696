import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import FusePageSimple from "@fuse/core/FusePageSimple";
import TestRunProcessHeader from "./TestRunProcessHeader";
import withReducer from "app/store/withReducer";
import reducer from "../store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TestRunProcessContent from "./TestRunProcessContent";
import { useDispatch } from "react-redux";
import { getSuites } from "../../testcase/store/suitesSlice";
import { useSelector } from "react-redux";
import {
  getTestStatus,
  selectTestStatus,
  setTestsLoading,
} from "../../testcase/store/testsSlice";
import { TestCaseDialog } from "../../testcase/header/TestCaseDialog";
import { getTestcaseTypes } from "../../testcase/store/testSlice";
import { getSuite, setCurrentSuite } from "../../testcase/store/suiteSlice";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
  },
  "& .FusePageSimple-toolbar": {},
  "& .FusePageSimple-content": {},
  "& .FusePageSimple-sidebarHeader": {},
  "& .FusePageSimple-sidebarContent": {},
}));

const TestRunProcessApp = (props) => {
  const { projectKey, testRunKey, suiteId } = useParams();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("suites");
  const navigate = useNavigate();
  const testStatus = useSelector(selectTestStatus);

  useEffect(() => {
    dispatch(setTestsLoading(true));
    dispatch(getTestcaseTypes());
    if (!testStatus.length) {
      dispatch(getTestStatus());
    }

    dispatch(getSuites({ projectID: "", isTree: false, testRunKey })).then(
      (res) => {
        setLoading(false);
      }
    );
    if (location.pathname.includes("/summary")) {
      setSelectedTab("summary");
    } else {
      setSelectedTab("suites");
    }

    //Stablish selected suite base on suiteIf in the url
    if (suiteId && suiteId != "all") {
      dispatch(getSuite(suiteId)).then((res) => {
        dispatch(setCurrentSuite(res.payload));
      });
    } else {
      dispatch(setCurrentSuite({ id: "all", name: "All Test cases" }));
    }
  }, []);

  const onClickTab = (tab) => {
    navigate(`/project/${projectKey}/testruns/${testRunKey}/${tab}`);
    setSelectedTab(tab);
  };

  return (
    <Root
      header={
        <TestRunProcessHeader
          onClickTab={onClickTab}
          selectedTab={selectedTab}
        />
      }
      content={
        <>
          <TestRunProcessContent loading={loading} selectedTab={selectedTab} />
          <TestCaseDialog />
        </>
      }
    />
  );
};

export default withReducer("testRunApp", reducer)(TestRunProcessApp);
