import { combineReducers } from '@reduxjs/toolkit';
import team from './teamSlice'
import users from '../../../system-settings/users/store/usersSlice'
import splitter from './splitterSlice'



const reducer = combineReducers({
    team,
    users,
    splitter
});

export default reducer;