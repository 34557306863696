import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProgressBar from "./ProgressBar";
import StatusWidget from "./status/StatusWidget";
import { useSelector } from "react-redux";
import {
  getExecutedTestCases,
  getTestRunStatusStats,
  resetRunProcess,
  selectTestRunStatusStats,
} from "../../store/testRunProcessSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ExecutedTestCasesWidget from "./ExecutedTestCasesWidget";

const TestRunProcessSummary = () => {
  const statusStats = useSelector(selectTestRunStatusStats);
  const dispatch = useDispatch();
  const { testRunKey } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getTestRunStatusStats(testRunKey));
    dispatch(getExecutedTestCases({ testRunKey })).then(() =>
      setLoading(false)
    );
    return () => {
      dispatch(resetRunProcess());
    };
  }, []);

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <div className="grid md:grid-cols-5 lg:grid-cols-6 gap-20 w-full px-32 py-20 lg:px-56">
      <ProgressBar animation={item} progress={statusStats?.progress} />

      {/* STATUS STATS  */}
      <StatusWidget variants={item} />

      <ExecutedTestCasesWidget loading={loading} />
    </div>
  );
};

export default TestRunProcessSummary;
