import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { useSelector } from "react-redux";
import { selectSuite } from "../store/suiteSlice";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { SuiteForm } from "./SuiteForm";

export const SuiteDialog = (props) => {
  const params = useParams();
  const { isOpenSuiteDialog, isModule, isNew, parentId } = useSelector(
    (state) => state.suitesApp.suite
  );
  const suite = useSelector(selectSuite);

  return (
    <Dialog
      classes={{
        paper: "w-1/4 m-24",
      }}
      open={isOpenSuiteDialog}
    >
      <AppBar position="static" color="secondary" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {suite ? "Edit" : "New"} {isModule ? "Module" : "Suite"}
          </Typography>
        </Toolbar>
      </AppBar>
      {isOpenSuiteDialog && (
        <SuiteForm
          projectId={params.projectId}
          suite={suite}
          isNew={isNew}
          isModule={isModule}
          parentId={parentId}
        ></SuiteForm>
      )}
    </Dialog>
  );
};
