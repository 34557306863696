import React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Skeleton, Typography } from "@mui/material";
import { parse } from "crypto-js/enc-base64";
import { useSelector } from "react-redux";
import { selectTestRunStatusStats } from "../../store/testRunProcessSlice";
import FuseUtils from "@fuse/utils/FuseUtils";
import { motion } from "framer-motion";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const ProgressBar = (props) => {
  const { progress, item } = props;
  const statusStats = useSelector(selectTestRunStatusStats);

  const container = {
    show: {
      transition: {
        staggerChildren: 1,
      },
    },
  };

  if (FuseUtils.isObjectEmpty(statusStats))
    return (
      <Skeleton
        variant="rounded"
        className="col-span-2 md:grid-cols-5 lg:col-span-6 h-20 rounded-8 mb-20"
      />
    );

  return (
    <motion.div
      className="col-span-2 md:grid-cols-5 lg:col-span-6"
      variants={container}
      initial="hidden"
      animate="show"
    >
      <Stack
        variants={item}
        component={motion.div}
        spacing={2}
        sx={{ flexGrow: 1 }}
      >
        <div className="flex flex-col gap-10  w-full">
          <BorderLinearProgress
            variant="determinate"
            value={parseInt(progress)}
          />
          <div className="flex px-10 ">
            <Typography className="flex text-16 w-full justify-start font-600">
              Progress
            </Typography>
            <Typography className="flex text-16 w-full justify-end font-600">
              {`${progress} %`}
            </Typography>
          </div>
        </div>
      </Stack>
    </motion.div>
  );
};

export default ProgressBar;
