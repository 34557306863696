
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';
import jwtService from 'src/app/auth/services/jwtService';


export const createUser = createAsyncThunk('systemSettings/user/createUser',
    async (userData, { dispatch, rejectWithValue }) => {
        try {
            const data = { ...userData, role_id: userData.role.id, job_title_id: userData.job_title.id }
            return await jwtService.POST(`${jwtServiceConfig.user}`, data, dispatch)
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    });

export const updateUser = createAsyncThunk('systemSettings/user/createUser', async ({ userId, userData }, { rejectWithValue }) => {

    try {
        const response = await new Promise((resolve, reject) => {
            axios
                .put(`${jwtServiceConfig.user}/${userId}`, userData)
                .then((response) => {
                    resolve(response.data.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
        return response;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }

});

export const getUser = createAsyncThunk('systemSettings/user/getUser', async (userId, { rejectWithValue }) => {
    try {
        const response = await new Promise((resolve, reject) => {
            axios
                .get(`${jwtServiceConfig.user}/${userId}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
        return response;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }

});

export const getJobTitles = createAsyncThunk('systemSettings/user/JobTitles', async () => {
    const response = await jwtService.getTypes('job_title');
    return response.data;
});

export const getSystemRoles = createAsyncThunk('systemSettings/user/SystemRoles', async () => {
    const response = await jwtService.getTypes('system_role');
    return response.data;
});

const userSlice = createSlice({
    name: 'systemSettingsApp',
    initialState: {
        user: {},
        jobTitles: [],
        systemRoles: []
    },
    reducers: {},
    extraReducers: {
        [createUser.fulfilled]: (state, action) => {
            state.users = action.payload
        },
        [getUser.fulfilled]: (state, action) => {
            state.user = action.payload;
        },
        [updateUser.fulfilled]: (state, action) => {
            state.user = action.payload;
        },
        [getJobTitles.fulfilled]: (state, action) => {
            state.jobTitles = action.payload
        },
        [getSystemRoles.fulfilled]: (state, action) => {
            state.systemRoles = action.payload
        }
    },

});


export const selectJobTitle = ({ systemSettingsApp }) => systemSettingsApp.user.jobTitles;
export const selectSystemRoles = ({ systemSettingsApp }) => systemSettingsApp.user.systemRoles;
export const selectUser = ({ systemSettingsApp }) => systemSettingsApp.user.user;
export default userSlice.reducer;