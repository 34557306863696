import { Typography } from "@mui/material";
import { FilterDrawerForm } from "app/shared-components/filter-drawer/FilterDrawerForm";
import React, { useEffect } from "react";
import { getTestStatus } from "src/app/main/testcase/store/testsSlice";

import {
  getAutomationStatus,
  getIterators,
  getPriorityType,
  getTestTags,
  selectSplitters,
} from "src/app/main/testcase/store/testSlice";
import { useLocalStorage } from "src/app/utils/customHook";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const SelectTestFilterContent = () => {
  const dispatch = useDispatch();
  const [selectedProject, setSelectedProject] =
    useLocalStorage("selected_project");

  const splitters = useSelector(selectSplitters);

  const onClick = (e) => {};

  useEffect(() => {
    dispatch(getTestStatus());
    dispatch(getAutomationStatus());
    dispatch(getPriorityType());
    dispatch(getTestTags(selectedProject.id));
    dispatch(getIterators(selectedProject.id));
  }, []);

  return (
    <>
      <div className="flex w-full px-20 pt-20">
        <Typography
          color="text.secondary"
          className=" grow text-2xl sm:text-3xl font-bold tracking-tigh"
        >
          Filters
        </Typography>
      </div>
      <FilterDrawerForm
        cancelButton={false}
        onClose={onClick}
        splitters={splitters}
      />
    </>
  );
};

export default SelectTestFilterContent;
