
import { createAsyncThunk, createSlice, createEntityAdapter, createSelector, } from '@reduxjs/toolkit';
import jwtService from 'src/app/auth/services/jwtService';
import axios from 'axios';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';

// First, create the thunk
export const getSuites = createAsyncThunk(
  'suitsApp/getSuits',
  async ({ projectID, filters, isTree }) => {
    const response = await jwtService.getSuites(projectID, filters, isTree);
    const data = response.result.map((item) => {
      return {
        ...item,
        label: item.name,
      }
    })
    return data;
  }
)
const suitedapter = createEntityAdapter({});


export const createSuite = createAsyncThunk(
  'suitsApp/createSuite',
  async (data, { rejectWithValue }) => {

    try {
      const response = await axios
        .post(jwtServiceConfig.suite, { ...data, children: [] })
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
)

export const deleteSuite = createAsyncThunk(
  'suitsApp/deleteSuite',
  async (data, { rejectWithValue }) => {

    try {
      const response = await axios
        .delete(`${jwtServiceConfig.suite}/${data}`)
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
)

export const updateSuite = createAsyncThunk(
  'suitsApp/updateSuite',
  async (data, { rejectWithValue }) => {
    try {
      const response = await new Promise((resolve, reject) => {
        axios
          .put(`${jwtServiceConfig.suite}/${data.id}`, data)
          .then((response) => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
)

export const getSuiteMetrics = createAsyncThunk(
  'suitsApp/getSuiteMetrics',
  async (suiteId) => {
    const response = await jwtService.getSuiteMetricsById(suiteId);
    return response;
  }
)

export const getSuite = createAsyncThunk(
  'suitsApp/getSuite',
  async (suiteId, { dispatch }) => {
    const response = await jwtService.GET(`/suite/${suiteId}`, dispatch)
    return response;
  }
)


const suiteSlice = createSlice({
  name: 'suiteApp',
  initialState: {
    suiteMetrics: undefined,
    suiteSidebarStatus: true,
    isOpenSuiteDialog: false,
    isModule: false,
    isNew: true,
    currentSuite: {},
    data: {}
  },
  reducers: {
    openSuiteDialog: (state, action) => {
      state.isOpenSuiteDialog = action.payload.open;
      state.projectId = action.payload.projectId;
      state.isModule = action.payload.isModule;
      state.dialogId = new Date().getTime();
      state.suiteId = action.payload.suiteId;
      state.isNew = action.payload.isNew;
      state.parentId = action.payload.parentId
    },
    openSuiteSidebar: (state, action) => {
      state.suiteSidebarStatus = action.payload;
    },
    closeSuiteDialog: (state, action) => {
      state.isOpenSuiteDialog = false;
    },
    setCurrentSuite: (state, action) => {
      state.currentSuite = action.payload;
    },
  },
  extraReducers: {
    [createSuite.fulfilled]: (state, action) => { },
    [updateSuite.fulfilled]: (state, action) => {
    },
    [createSuite.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getSuite.fulfilled]: (state, action) => { state.currentSuite = action.payload },
    [getSuiteMetrics.fulfilled]: (state, action) => { state.suiteMetrics = action.payload },
  },
});


export const selectSuites = ({ suitesApp }) => suitesApp.suites.suits;

export const selectSuiteId = ({ suitesApp }) => suitesApp.suite.suiteId;
export const selectCurrentSuite = ({ suitesApp }) => suitesApp.suite.currentSuite;
export const selectSuite = createSelector([selectSuiteId, selectSuites], (suiteId, suites = []) => {
  if (suites?.response?.status != 403) {
    return suites.find(item => item.id == suiteId);
  }


});

export const { openSuiteDialog, closeSuiteDialog, openSuiteSidebar, setCurrentSuite } = suiteSlice.actions;
export default suiteSlice.reducer;