import Typography from '@mui/material/Typography';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Button, Link } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export const SelectSuiteMessage = (props) => {

  const params = useParams()
  const navigate = useNavigate()
  const { importButton = true } = props;

  return (
    <div className="flex flex-col h-full flex-1 items-center justify-center p-24">
      <FuseSvgIcon className="icon-size-128 mb-16" color="disabled" size={24}>
        material-outline:text_snippet
      </FuseSvgIcon>
      <Typography className="mt-10 w-440 text-center text-lg font-medium tracking-tight" color="text.secondary">
        Select a suite to see the test cases.
      </Typography>
      {
        importButton && <Button onClick={() => navigate(`/tests/project/${params.projectId}/import`)} size="small" className='mt-10' variant="outlined" color="secondary" startIcon={<CloudUploadIcon />}>
          Import Test cases
        </Button>
      }

    </div>
  );
}
