import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import jwtService from '../../auth/services/jwtService';

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Please enter your password.')
    .min(8, 'Password must contain one uppercase letter, one lowercase letter, one number, one special character and 8 characters minimum')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[()[\]{}|\\`~!@#$%^&*_\-+=;:\'",<>./?])(?=.{8,})/,
      "Password must contain one uppercase letter, one lowercase letter, one number, one special character and 8 characters minimum"
    ),
  passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const defaultValues = {
  password: '',
  passwordConfirm: '',
};

function ResetPassword() {
  const [isLoading, setLoading] = useState(false);
  const [alertMessage, setMessage] = useState({});
  const { control, formState, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, setError } = formState;

  const navigate = useNavigate();

  function onSubmit({ password }) {
    setLoading(true);

    const queryString = window.location.search
    const params = new URLSearchParams(queryString)
    const token = params.get('reset_password_token')

    jwtService
      .resetUserPassword(password, token)
      .then((response) => {
        setLoading(false);
        navigate('/sign-in?reset_password=true')
      })
      .catch((error) => {
        setLoading(false);

        let alertMessage = 'Internal server error';
        if (error.response.status !== 500) {
          alertMessage = 'The link you followed has expired or has already been used'
        }
        setMessage({ type: 'error', message: alertMessage, title: 'Error' });

        error.forEach((error) => {
          setError(error.type, {
            type: 'manual',
            message: error.message,
          });
        });
      });
  }

  function renderAlertMessage({ type = 'info', title, message }) {
    return (
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity={type} onClose={() => {
          setMessage({})
          setError('email', { message: '' })
        }}>
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Stack>
    )
  }

  return (
    <div className='flex flex-col items-center flex-1 min-w-0 sm:flex-row md:items-start sm:justify-center md:justify-start'>
      <Paper className="w-full h-full px-16 py-8 sm:h-auto md:flex md:items-center md:justify-end sm:w-auto md:h-full md:w-1/2 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          {!isEmpty(alertMessage) && renderAlertMessage(alertMessage)}

          <div className="flex flex-row w-full align-middle vert justify-left">
            <div className="mt-3 ml-4 text-4xl font-extrabold leading-tight tracking-tight">Reset password</div>
          </div>

          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password"
                  type="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="passwordConfirm"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password (Confirm)"
                  type="password"
                  error={!!errors.passwordConfirm}
                  helperText={errors?.passwordConfirm?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <LoadingButton
              variant="contained"
              color="secondary"
              className="w-full mt-16 "
              aria-label="Reset password"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
              loading={isLoading}
            >
              Reset password
            </LoadingButton>
          </form>
        </div>
      </Paper>

      <Box
        className="relative items-center justify-center flex-auto hidden h-full p-64 overflow-hidden md:flex lg:px-112"
        sx={{ backgroundColor: '#00343d' }}
      >

        <Box
          component="svg"
          className="absolute -top-64 -right-64 opacity-20"
          sx={{ color: 'primary.light' }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
        </Box>

        <div className="relative z-10 w-full max-w-4xl">
          <div className="font-bold leading-none text-gray-100 text-7xl">
            <img src="assets/images/logo/tambora_login.png" />
          </div>
          <div className="mt-24 text-lg leading-6 tracking-tight text-gray-400">
          </div>
        </div>
      </Box>
    </div>
  );
}

export default ResetPassword;
