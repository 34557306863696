import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import jwtDecode from 'jwt-decode';
import jwtServiceConfig from './jwtServiceConfig';
import { openDialog } from 'app/store/fuse/dialogSlice';
import SystemErrorDialog from 'app/shared-components/SystemErrorDialog';
/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
    // createAuthRefreshInterceptor(axios, this.refreshSession())
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', 'Invalid credentials');
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  POST = (url, data, dispatch) => {
    return new Promise((resolve, reject) => {
      axios.post(url, data).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        // if (error.response?.status == 500) {
        dispatch(
          openDialog({
            children: (
              <SystemErrorDialog response={error.response}></SystemErrorDialog>
            ),
          })
        );
        // }
        reject(error);
      });;
    });
  }

  PUT = (url, data, dispatch) => {
    return new Promise((resolve, reject) => {
      axios.put(url, data).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        if (error.response?.status == 500) {
          dispatch(
            openDialog({
              children: (
                <SystemErrorDialog response={error.response}></SystemErrorDialog>
              ),
            })
          );
        }
        else {
          console.log("otro error")
        }
        reject(error);
      });;
    });
  }

  GET = (url, dispatch, pagination = {}, params = {}, responseType = 'json') => {
    return new Promise((resolve, reject) => {


      axios.get(url, {
        responseType: responseType,
        params: {
          ...params,
          ...pagination,
        }
      }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        if (error.response?.status == 500) {
          console.log('error.response?.status', error.response?.status)
          dispatch(
            openDialog({
              children: (
                <SystemErrorDialog response={error.response}></SystemErrorDialog>
              ),
            })
          );
        }
        else {
          console.log("otro error")
        }
        reject(error);
      });;
    });
  }

  DELETE = (url, dispatch) => {
    return new Promise((resolve, reject) => {
      axios.delete(url).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        if (error.response?.status == 500) {
          dispatch(
            openDialog({
              children: (
                <SystemErrorDialog response={error.response}></SystemErrorDialog>
              ),
            })
          );
        }
        else {
          console.log("otro error")
        }
        reject(error);
      });;
    });
  }

  handleAuthentication = () => {
    const access_token = this.getAccessToken();
    const user = this.getUser();
    if (!access_token) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      const userData = JSON.parse(user);
      this.setSession(access_token, userData);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.signUp, data).then((response) => {
        if (response.data.user) {
          const { access, user } = response.data;
          this.setSession(access, user);
          resolve(user);
          this.emit('onLogin', user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.sessions,
          {
            email,
            password
          }
        )
        .then((response) => {
          if (response.data.user) {

            const { access, user } = response.data;
            this.setSession(access, user);
            resolve(user);
            this.emit('onLogin', user);
          }
        })
        .catch(error => {
          reject(error);
        })
    });
  };

  activateUserAccount = (password, invitation_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.activate,
          {
            password,
            invitation_token
          }
        )
        .then((response) => {
          return resolve(response)
        })
        .catch(error => {
          return reject(error);
        })
    });
  }

  sendResetPassword = (email) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.password,
          {
            email
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    });
  }

  resetUserPassword = (password, reset_password_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.resetPassword,
          {
            password,
            reset_password_token
          }
        )
        .then((response) => {
          return resolve(response)
        })
        .catch(error => {
          return reject(error);
        })
    });
  }

  signInWithToken = () => {
    const access_token = this.getAccessToken();
    const user = this.getUser();

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (user) {
          const userData = JSON.parse(user);
          this.setSession(access_token, userData);
          resolve(userData);
        } else {
          this.logout();
          reject(new Error('Failed to login with token.'));
        }
      }, 300);
    })
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (access_token, user) => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      localStorage.setItem('jwt_user', JSON.stringify(user));
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      localStorage.removeItem('jwt_user');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    localStorage.removeItem('selected_project');
    this.setSession(null);
    this.emit('onLogout', 'Logged out');
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
  getUser = () => {
    return window.localStorage.getItem('jwt_user');
  };

  refreshSession = () => {
    const user = this.getUser();
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.refreshToken).then((response) => {
        if (response.data.access) {
          const userData = JSON.parse(user);
          this.setSession(response.data.access, userData);
          resolve(userData);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  saveSuite = (name, description, parent_id, project_id, children = []) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.suite, {
          name,
          description,
          parent_id,
          project_id,
          children,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getSuites = (project_id, tree_view) => {
    return new Promise((resolve, reject) => {
      axios
        .get(jwtServiceConfig.suite, {
          params: {
            project_id,
            tree_view
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTests = (suite_id, filters, pagination, project_id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(jwtServiceConfig.tests, {
          params: {
            project_id,
            suite_id,
            ...filters,
            ...pagination,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTestStatus = (typegroup_code) => {
    return new Promise((resolve, reject) => {
      axios
        .get(jwtServiceConfig.type, {
          params: {
            typegroup_code,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getSuiteMetrics = (suite_id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${jwtServiceConfig.suite}/${suite_id}/test_status_metrics`, {
          params: {
            suite_id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  addTest = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.tests, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  changeTestData = (data, id) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${jwtServiceConfig.tests}/${id}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getProjectIterators = (project_id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${jwtServiceConfig.projects}/${project_id}/splitter`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getSuiteMetricsById = (suite_id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${jwtServiceConfig.suite}/${suite_id}/test_status_metrics`,
          {
            params: {
              suite_id,
            }
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  deleteTestCase = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${jwtServiceConfig.tests}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // ####################################################################
  // ############################# DASBOARD #############################
  // ####################################################################

  getDashboardProjects = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${jwtServiceConfig.dashboard}/projects`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getSummaryStats = (project_id = null) => {
    let url = '/project-stats';
    if (project_id) url += `?project_id=${project_id}`;

    return new Promise((resolve, reject) => {
      axios
        .get(`${jwtServiceConfig.dashboard}${url}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTypes = (type = '') => {
    let url = '';
    if (type) url = `?typegroup_code=${type}`;

    return new Promise((resolve, reject) => {
      axios
        .get(`${jwtServiceConfig.type}${url}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTestcaseMetrics = (project_id = null) => {
    let url = '/testcase-status';
    if (project_id) url += `?project_id=${project_id}`;

    return new Promise((resolve, reject) => {
      axios
        .get(`${jwtServiceConfig.dashboard}${url}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getCreatedTests = (queryParams = {}) => {
    const paramsString = new URLSearchParams(queryParams).toString();
    const baseUrl = `${jwtServiceConfig.dashboard}/created-tests`;
    const url = paramsString ? `${baseUrl}?${paramsString}` : baseUrl;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  };
}

const instance = new JwtService();

export default instance;
