import React from "react";
import {
  Autocomplete,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  addMappingProject,
  getJiraProjects,
  getMappedProjects,
  selectIntegrations,
  selectJiraProjects,
} from "../../store/jiraSlice";
import { useDispatch } from "react-redux";
import { selectProjects } from "src/app/main/projects/store/projectsSlice";
import { motion } from "framer-motion";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ResetTvRounded } from "@mui/icons-material";
import { showMessage } from "app/shared-components/ShowMessage";
import ConfirmDialog from "app/shared-components/ConfirmDialog";
import { openDialog } from "app/store/fuse/dialogSlice";
import { LoadingButton } from "@mui/lab";

const AddMappingCard = (props) => {
  const dispatch = useDispatch();
  const integrations = useSelector(selectIntegrations);
  const jiraProjects = useSelector(selectJiraProjects);
  const tamboraProjects = useSelector(selectProjects);
  const [disabled, setDisabled] = useState(true);
  const [ramdon, setRamdon] = useState(Math.floor(Math.random() * 100));
  const [jiraProjectKey, setJiraProjectKey] = useState(
    Math.floor(Math.random() * 100)
  );
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const jiraAccountChange = (event, value) => {
    if (value != null) {
      dispatch(getJiraProjects(value.id));
      setDisabled(false);
    } else {
      reset({ jira_project: "" });
      setJiraProjectKey(Math.floor(Math.random() * 100));
      setDisabled(true);
    }
    return value;
  };

  const addMapping = (value) => {
    setLoading(true);
    let data = {
      integration_id: value.jira_account.id,
      project_id: value.project.id,
      jira_project: {
        id: value.jira_project.id,
        name: value.jira_project.name,
        key: value.jira_project.key,
      },
    };

    dispatch(addMappingProject(data)).then((response) => {
      if (response.payload.status == "error") {
        setLoading(false);
        dispatch(
          openDialog({
            children: (
              <ConfirmDialog
                status="error"
                cancelButtonLabel="Ok"
                message={response.payload.message}
              />
            ),
          })
        );
      } else {
        dispatch(getMappedProjects()).then(() => {
          setRamdon(Math.floor(Math.random() * 100));
          setJiraProjectKey(Math.floor(Math.random() * 100));
          reset();
          setLoading(false);
          dispatch(
            showMessage({
              message: response.payload.message,
              variant: "success",
            })
          );
        });
      }
    });
  };

  return (
    <Card component={motion.div} variants={props.item}>
      <div className="px-32 py-24">
        <Typography className="text-2xl mb-20 font-semibold leading-tight">
          Add Mapping
        </Typography>
        {/* <Typography
          className="mx-6 mb-20 mt-5 leading-6"
          color="text.secondary"
        >
          Include configurations that enable users to perform actions such as
          creating, connecting, and searching for Jira issues within specific
          projects from the Tambora project set.
        </Typography> */}
        <form
          noValidate
          className="flex flex-col gap-20"
          onSubmit={handleSubmit(addMapping)}
        >
          <Controller
            name="jira_account"
            className=" mb-10"
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                key={`jira-account-${ramdon}`}
                disablePortal
                size="small"
                id="combo-box-demo"
                options={integrations}
                onChange={(event, value) =>
                  onChange(jiraAccountChange(event, value))
                }
                // getOptionLabel={(option) => option.name}
                getOptionLabel={(item) => (item.name ? item.name : "")}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    error={!!errors.jira_account}
                    required
                    {...params}
                    helperText={errors?.jira_account?.message}
                    label="Select Jira Account"
                  />
                )}
              />
            )}
          />

          <Controller
            name="project"
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                key={`project-${ramdon}`}
                disablePortal
                onChange={(event, value) => {
                  onChange(value);
                }}
                size="small"
                id="combo-box-demo"
                className="w-full"
                getOptionLabel={(option) => option?.name}
                options={tamboraProjects.data}
                renderInput={(params) => (
                  <TextField
                    error={!!errors.project}
                    required
                    {...params}
                    label="Tambora projects"
                    helperText={errors?.project?.message}
                  />
                )}
              />
            )}
          />

          <Controller
            name="jira_project"
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                key={jiraProjectKey}
                disablePortal
                disabled={disabled}
                onChange={(event, value) => {
                  onChange(value);
                }}
                size="small"
                id="combo-box-demo"
                className="w-full"
                getOptionLabel={(option) => `${option.name} (${option.key})`}
                options={jiraProjects}
                renderInput={(params) => (
                  <TextField
                    error={!!errors.jira_project}
                    required
                    {...params}
                    label="Jira projects"
                    helperText={
                      !!errors.jira_project
                        ? errors.jira_project.message
                        : "Select a Jira account to enable this field."
                    }
                  />
                )}
              />
            )}
          />

          <LoadingButton
            loading={loading}
            variant="contained"
            type="submit"
            color="secondary"
          >
            Add Mapping
          </LoadingButton>
        </form>
      </div>
    </Card>
  );
};

export default AddMappingCard;
