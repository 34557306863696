import React from 'react'
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Button, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useNavigate, useParams } from 'react-router-dom';

const TestEmptyState = (props) => {

    const navigate = useNavigate()
    const params = useParams()
    const { showSubTitle = true } = props;
    return (
        <div className='flex w-full flex-col items-center mt-96 h-full'>
            <FuseSvgIcon className="mb-20 text-grey-400" size={100}>heroicons-outline:clipboard-check</FuseSvgIcon>
            <Typography className="mt-16 text-2xl font-semibold tracking-tight" color="text.secondary">
                No Tests Found
            </Typography>

            {
                showSubTitle && <>
                    <Typography className="mt-10 w-440 text-center text-lg font-medium tracking-tight" color="text.secondary">
                        Add a new test case click on Add test button to import them from CSV file.
                    </Typography>
                    <Button onClick={() => navigate(`/tests/project/${params.projectId}/import?suite_id=${params.suiteId}`)} size="small" className='mt-10' variant="outlined" color="secondary" startIcon={<CloudUploadIcon />}>
                        Import Test cases
                    </Button>
                </>
            }



        </div>
    )
}

export default TestEmptyState