
import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwtService from 'src/app/auth/services/jwtService';
import _ from "lodash";


// First, create the thunk
export const getTestsBySuiteId = createAsyncThunk(
  'testsApp/getTestsBySuiteId',
  async ({ suiteId, filters, pagination = {}, projectId = null }, { useSelector }) => {

    const response = await jwtService.getTests(suiteId, filters, pagination, projectId);
    const data = response;
    return data;
  }
)

export const getTestById = createAsyncThunk('testsApp/getTestById', async ({testId}) => await jwtService.getTestById(testId))

export const deleteTest = createAsyncThunk(
  'testsApp/deleteTest',
  async (id, { dispatch }) => {
    const response = await jwtService.DELETE(`/testcase/${id}`, dispatch)
    return response;
  }
)

export const bulkDeleteTest = createAsyncThunk(
  'testsApp/bulkDeleteTest',
  async (data, { dispatch }) => {
    const response = await jwtService.POST("/testcase/bulk-delete", data, dispatch)
    return response;
  }
)

export const cloneTestCases = createAsyncThunk(
  'testsApp/cloneseTestCases',
  async (data, { dispatch }) => {
    const response = await jwtService.POST("/testcase/bulk-clone", data, dispatch)
    return response;
  }
)

export const bulkEditTest = createAsyncThunk(
  'testsApp/bulkEditTest',
  async (data, { dispatch }) => {
    const response = await jwtService.POST("/testcase/bulk-update", data, dispatch)
    return response;
  }
)

export const getTestStatus = createAsyncThunk(
  'testsApp/getTestStatus',
  async () => {
    const response = await jwtService.getTestStatus('testcases_status');
    const data = response.data;
    return data;
  }
)


const testsSlice = createSlice({
  name: 'testsApp',
  initialState: {
    tests: [],
    testPagination: {},
    test: {},
    testDialogId: null,
    selectedTestIds: [],
    isOpenTestDialog: false,
    viewMode: true,
    loading: false,
    testStatus: [],
    isClone: false
  },
  reducers: {

    openTestDialog: (state, action) => {
      state.isOpenTestDialog = action.payload.open;
      state.testDialogId = action.payload.testId;
      state.isClone = action.payload.isClone;
      state.viewMode = action.payload.viewMode;
      state.test = action.payload.test
    },
    addTestCaseId: (state, action) => {
      state.selectedTestIds.push(action.payload)
    },
    removeTestCaseId: (state, action) => {
      _.remove(state.selectedTestIds, (item) => item.testCaseId === action.payload)
    },
    updatedSelectedTestIds: (state, action) => {
      state.selectedTestIds = action.payload
    },
    setTestsLoading: (state, action) => {
      state.loading = action.payload
    },
    closeTestDialog: (state, action) => {
      state.isOpenTestDialog = false;
      state.isClone = false;
    },
    updateStatusByTestId: (state, action) => {
      const { index, updatedTest } = action.payload;
      state.tests[index] = updatedTest;
      state.tests[index].status_id = updatedTest.status.id
    },
    refreshList: (state, action) => {
      const index = state.tests.findIndex(obj => obj.id == action.payload.id);
      if (index !== -1) {
        state.tests[index] = action.payload;
      } else
        state.tests.unshift(action.payload)

    },
    removeTestFromList: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.tests = _.filter(state.tests, (item) => !action.payload.includes(item.id));
      }
      else {
        _.remove(state.tests, (item) => item.id == action.payload)
      }
      state.selectedTestIds = [];
    }
  },
  extraReducers: {
    [getTestsBySuiteId.fulfilled]: (state, action) => {
      state.tests = action.payload.result
      state.testPagination = action.payload.paginate
    },
    [getTestStatus.fulfilled]: (state, action) => {
      state.testStatus = action.payload
    },
    // [getTestById.fulfilled]: (state, action) => {
    //   state.test = actions.payload.test
    // }
  },
});

export const selectTests = ({ suitesApp }) => suitesApp.tests.tests;
export const selectTestStatus = ({ suitesApp }) => suitesApp.tests.testStatus;
export const selectedTestIds = ({ suitesApp }) => suitesApp.tests.selectedTestIds;
export const selectPagination = ({ suitesApp }) => suitesApp.tests.pagination;
export const selectTestPagination = ({ suitesApp }) => suitesApp.tests.testPagination;
export const selectTestsLoading = ({ suitesApp }) => suitesApp.tests.loading;

export const selectDialogTestId = ({ suitesApp }) => suitesApp.tests.testDialogId;

export const selectedDialogTest = createSelector([selectDialogTestId, selectTests], (testId, testData = []) => {
  return testData.find(item => item.id == testId);
});

export const {
  openTestDialog,
  closeTestDialog,
  updateStatusByTestId,
  setTestsLoading,
  refreshList,
  addTestCaseId,
  updatedSelectedTestIds,
  removeTestFromList,
  removeTestCaseId } = testsSlice.actions;

export default testsSlice.reducer;

