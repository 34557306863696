import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import WYSIWYGEditor from "app/shared-components/WYSIWYGEditor";
import { Controller, useForm } from "react-hook-form";
import { IteratorLabel } from "./IteratorLabel";
import { IteratorMenuList } from "./IteratorMenuList";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import {
  closeTestDialog,
  getTestsBySuiteId,
  openTestDialog,
  refreshList,
} from "../../store/testsSlice";
import { useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  createTest,
  refreshTestTags,
  selectAutomationStatus,
  selectPriorityTypes,
  selectTestCaseTypes,
  selectTestTags,
  updateTest,
} from "../../store/testSlice";
import { showMessage } from "app/shared-components/ShowMessage";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { getSuiteMetrics } from "../../store/suiteSlice";
import { Autocomplete, IconButton, Tooltip, Typography } from "@mui/material";
import _ from "lodash";
import useRoleBasedAccess, { useLocalStorage } from "src/app/utils/customHook";
import { authRoles } from "src/app/auth";
import SystemDialog from "app/shared-components/SystemDialog";
import { openDialog } from "app/store/fuse/dialogSlice";
import { Box } from "@mui/system";
import PriorityIcon from "../../PriorityIcon";
import { AutocompleteTag } from "app/shared-components/automation-tags/AutocompleteTag";
import TamboraTreeSelect from "app/shared-components/TamboraTreeSelect";
import MoreFields from "./widgets/MoreFields";
import { chat_completion } from "app/shared-components/ai-chatpanel/store/assistantSlice";
import TamboraUtils from "src/app/utils/TamboraUtils";
import TestcaseTypeOptions from "./widgets/TestcaseTypeOptions";
import AiIcon from "app/shared-components/ai-chatpanel/AiIcon";

const schema = yup.object().shape({
  summary: yup
    .string()
    .trim()
    .required("You must enter a test title, summary or scenario")
    .max(250, "Summary cannot be over 250 characters"),
  suite: yup.object().nullable().required("You must enter an Suite."),
  time_taken: yup
    .number()
    .min(1, "The Time spent must be greather than 0.")
    .max(120, "Time spent cannot be over 120 minutes")
    .integer("The Time spent must be an integer.")
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    }),
  automation_status: yup
    .object()
    .nullable()
    .required("You must enter an Automation status"),
});

const getKeyFromArray = (array) => {

  if (array) {
    const keys = array.map(item => item.key);
    return keys;
  } else
    return []
}

export const TestForm = (props) => {
  const { iterators = [] } = useSelector((state) => state.suitesApp.test);
  const { testStatus } = useSelector((state) => state.suitesApp.tests);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suiteId, setSuiteId] = useState(props.suiteId);
  const automationStatus = useSelector(selectAutomationStatus);
  const testcaseTypes = useSelector(selectTestCaseTypes);
  const testTags = useSelector(selectTestTags);
  const priorityTypes = useSelector(selectPriorityTypes);
  const [disabled, setDisabled] = useState(false);
  const [count, setCount] = useState(0);
  const [splitterList, setSplitterList] = useState(iterators);
  const { hasRequiredRole } = useRoleBasedAccess(authRoles.external_tester, []);
  const suites = useSelector((state) => state.suitesApp.suites.suits);
  const [selectedProject, setSelectedProject] = useLocalStorage("selected_project");

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const textTemplateId = _.find(testcaseTypes, ["name", "Scenario"])?.id;
  const defaultValues = _.merge(
    {
      tags: [],
      template_id: textTemplateId,
      suite: suites.find((item) => item.id == suiteId),
      status_id: testStatus.find((item) => item.name == "Untested")?.id,
    },
    {
      automation_status: props.test?.id
        ? props.test.automation_status
        : _.find(automationStatus, ["name", "Not Automated"]),
    },
    props.test,
    props.test
      ? {
        splitters: props.test.splitters,
        linked_issues: getKeyFromArray(props.test?.linked_issues),
      }
      : null
  );

  useEffect(() => {
    if (props.test) {
      setCount(props.test?.splitters.length);
      enableDisableSplitters(props.test.splitters);
    }
  }, []);

  const {
    handleSubmit,
    formState,
    watch,
    setValue,
    getValues,
    control,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors, setError, dirtyFields } = formState;

  const testForm = watch();

  const saveTest = (value) => {
    if (dirtyFields.tags) dispatch(refreshTestTags(value.tags));
    setLoading(true);
    value.automation_status_id = value.automation_status.id;
    value.suite_id = value.suite.id;
    value.priority_id = value.priority ? value.priority.id : null;
    value.project_id = props.projectId;
    value.description = value.description?.htmlValue
      ? value.description.htmlValue
      : value.description;
    value.steps = value.steps?.htmlValue ? value.steps.htmlValue : value.steps;
    value.pre_conditions = value.pre_conditions?.htmlValue
      ? value.pre_conditions.htmlValue
      : value.pre_conditions;
    value.expected_results = value.expected_results?.htmlValue
      ? value.expected_results.htmlValue
      : value.expected_results;

    resetValues(value);
    props.isClone ? create(value) : props.test?.id ? update(value) : create(value);
  };

  const create = (value) => {
    if (watch("tamplate_id") == textTemplateId) {
      reset("description", "pre_conditions", "steps", "expected_results");
    } else {
    }
    dispatch(createTest(value)).then((res) => {
      afterSave(res);
    });
  };

  const resetValues = (value) => {
    if (
      testcaseTypes.find((item) => item.id == watch("template_id"))?.name !=
      "Steps"
    ) {
      value.steps = "";
      value.pre_conditions = "";
      value.expected_results = "";
    }
  };

  const update = (value) => {
    dispatch(updateTest(value)).then((res) => {
      afterSave(res);
    });
  };

  const afterSave = (res) => {
    if (res.payload.status == "error") {
      setLoading(false);
      return dispatch(
        openDialog({
          children: <SystemDialog message={res.payload.message} />,
        })
      );
    }
    dispatch(openTestDialog({ testId: null, open: checked }));
    dispatch(refreshList(res.payload.data));
    // dispatch(getTestsBySuiteId({ suiteId }));
    dispatch(getSuiteMetrics(suiteId));
    dispatch(
      showMessage({
        message: res.payload.message, //text or html
        variant: "success", //success error info warning null
      })
    );
    setLoading(false);
    if (!checked) reset();
  };

  const onSplitterChange = (labels) => {
    enableDisableSplitters(labels);
    setCount(labels.length);
    setValue("splitters", labels);
  };

  const enableDisableSplitters = (labels) => {
    const ids = labels.map((item) => item.id);
    const disabled = labels.length >= 2;
    const newArray = splitterList.map((item) => {
      const disabledStatus = ids.includes(item.id) ? false : disabled;
      return { ...item, disabled: disabledStatus };
    });
    setSplitterList(newArray);
  };

  const onCloseDialog = () => {
    dispatch(closeTestDialog());
    reset();
  };

  function onClick() { }

  const deleteSplitterLabel = (originalArray, label) => {
    const filteredArray = originalArray.filter((item) => {
      const filteredLabels = item.labels.filter((_label) => _label !== label);
      item.labels = filteredLabels;
      return filteredLabels.length > 0;
    });
    setCount(filteredArray.length);
    enableDisableSplitters(filteredArray);
    return filteredArray;
  };

  const onClickAIButton = () => {
    setLoading(true);
    const message = getValues("summary");
    const template_type = testcaseTypes.find(
      (item) => item.id == getValues("template_id")
    ).name;

    dispatch(chat_completion({ message, template_type })).then((res) => {
      const message = res.payload.data;
      if (template_type == "BDD") {
        const htmlOutput = TamboraUtils.parseJsonToHtml(message);
        setValue("summary", message.scenario);
        setValue("description", htmlOutput);
      } else {
        setValue("summary", message.summary);
        const htmlOutput = TamboraUtils.parseTextToHtml(message.description);
        setValue("description", htmlOutput);
      }
      setLoading(false);
    });
  };

  return (
    <form
      noValidate
      name="testCaseForm"
      className="flex h-full flex-col overflow-y-auto"
      onSubmit={handleSubmit(saveTest)}
    >
      {/* CONTENT */}

      <div className="flex w-full h-full flex-row gap-10 px-36 pt-36">
        <div className="w-full">
          <div className="flex gap-10 items-start">
            <div className="w-full flex flex-col">
              <TestcaseTypeOptions control={control} />
              <Controller
                name="summary"
                control={control}
                render={({ field }) => (
                  <TextField
                    inputProps={{
                      style: { lineHeight: "1.3" },
                    }}
                    {...field}
                    className="mt-8 mb-16"
                    label="Title, Summary, Scenario"
                    error={!!errors.summary}
                    helperText={errors?.summary?.message}
                    id="summary"
                    multiline
                    rows={3}
                    required
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              {testcaseTypes.find((item) => item.id == watch("template_id"))
                ?.name != "Steps" && (
                  <Controller
                    className="mt-8 mb-16"
                    render={({ field }) => (
                      <WYSIWYGEditor
                        customControls={true}
                        // onChange={field.onChange}
                        height="h-288"
                        disabled="false"
                        value={field.value}
                        {...field}
                      />
                    )}
                    name="description"
                    control={control}
                  />
                )}
            </div>

            {/* AI ASSISTANT */}
            {testcaseTypes.find((item) => item.id == watch("template_id"))
              ?.name != "Steps" && (
                <Tooltip title="Test Case Assistant">
                  <span>
                    <IconButton
                      disabled={
                        getValues("summary") == undefined ||
                        getValues("summary")?.length < 5
                      }
                      onClick={onClickAIButton}
                      className="w-40 h-40 mt-68"
                    >
                      <AiIcon
                        disabled={
                          getValues("summary") == undefined ||
                          getValues("summary")?.length < 5
                        }
                        loading={loading}
                      ></AiIcon>
                    </IconButton>
                  </span>
                </Tooltip>
              )}
          </div>

          {/* SUMMARY */}
          {testcaseTypes.find((item) => item.id == watch("template_id"))
            ?.name == "Steps" && (
              <>
                {/* DESCRIPTIN AND PRECONDITIONS */}
                <div className="flex w-full gap-20 ">
                  <div className="w-full flex flex-col">
                    <Typography
                      variant="subtitle2"
                      className="mb-5 text-grey-700"
                    >
                      Description
                    </Typography>
                    <Controller
                      className="mt-8 mb-16"
                      render={({ field }) => (
                        <WYSIWYGEditor
                          value={field.value}
                          disabled="false"
                          defaultValue={props.test?.description || ""}
                          {...field}
                        />
                      )}
                      name="description"
                      control={control}
                    />
                  </div>
                  <div className=" w-full flex flex-col">
                    <Typography
                      variant="subtitle2"
                      className="mb-5 text-grey-700"
                    >
                      Pre-Conditions
                    </Typography>
                    <Controller
                      className="mt-8 mb-16"
                      render={({ field }) => (
                        <WYSIWYGEditor
                          disabled="false"
                          defaultValue={props.test?.pre_conditions || ""}
                          {...field}
                        />
                      )}
                      name="pre_conditions"
                      control={control}
                    />
                  </div>
                </div>

                {/* STEPS AND EXPECTED RESULTS */}
                <div className="flex w-full gap-20 mt-20">
                  <div className="w-full flex flex-col">
                    <Typography
                      variant="subtitle2"
                      className="mb-5 text-grey-700"
                    >
                      Steps
                    </Typography>
                    <Controller
                      className="mt-8 mb-16"
                      render={({ field }) => (
                        <WYSIWYGEditor
                          disabled="false"
                          defaultValue={props.test?.steps || ""}
                          {...field}
                        />
                      )}
                      name="steps"
                      control={control}
                    />
                  </div>
                  <div className=" w-full flex flex-col">
                    <Typography
                      variant="subtitle2"
                      className="mb-5 text-grey-700"
                    >
                      Expected Result
                    </Typography>
                    <Controller
                      className="mt-8 mb-16"
                      render={({ field }) => (
                        <WYSIWYGEditor
                          disabled="false"
                          defaultValue={props.test?.expected_results || ""}
                          {...field}
                        />
                      )}
                      name="expected_results"
                      control={control}
                    />
                  </div>
                </div>
              </>
            )}

          <Typography variant="subtitle2" className="mt-20">
            Splitter Group ({count}/2)
          </Typography>
          <div className="flex flex-col items-start w-full gap-4 flex-wrap mt-16 mb-16">
            <Controller
              name="splitters"
              className="mt-8 mb-16"
              control={control}
              defaultValue={[]}
              render={({ field: { onChange, value } }) => {
                if (!value) {
                  return null;
                }
                return value.map((splitter) => (
                  <div key={splitter.id} className="ml-10 flex">
                    <Typography className="mr-10" variant="caption">
                      {splitter.name}:
                    </Typography>
                    <div className="flex gap-2">
                      {splitter.labels.map((label) => (
                        <IteratorLabel
                          className="mr-5"
                          key={label}
                          label={label}
                          onDelete={() =>
                            onChange(deleteSplitterLabel(value, label))
                          }
                        />
                      ))}
                    </div>
                  </div>
                ));
              }}
            />
          </div>

          <div className="flex flex-row items-center max-w-360 flex-wrap mb-10">
            <IteratorMenuList
              testForm={testForm}
              disabled={disabled}
              onClick={onClick}
              iteratorList={splitterList}
              onChange={(label, id) => onSplitterChange(label, id)}
            />
          </div>
        </div>

        <div className=" w-384 flex flex-col gap-20 mb-20 p-16 border dark:bg-transparent bg-gray-50 rounded-12">
          {/* SUITE */}
          <Controller
            name="suite"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TamboraTreeSelect
                options={suites}
                value={value}
                onChange={(event, value) => {
                  onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    error={!!errors.suite}
                    required
                    {...params}
                    label="Suite"
                    helperText={errors?.suite?.message}
                  />
                )}
              ></TamboraTreeSelect>
            )}
          />

          {/* AUTOMATION STATUS */}
          <Controller
            name="automation_status"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                size="small"
                options={automationStatus}
                disablePortal
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  onChange(value);
                }}
                getOptionLabel={(item) => (item.name ? item.name : "")}
                value={value}
                className=""
                id="combo-box-demo"
                renderInput={(params) => (
                  <TextField
                    error={!!errors.automation_status}
                    required
                    helperText={errors?.automation_status?.message}
                    {...params}
                    label="Automation Status"
                  />
                )}
              />
            )}
          />

          {/* PRIORITY TYPE FIELD */}
          <Controller
            name="priority"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                size="small"
                options={priorityTypes}
                disablePortal
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  onChange(value);
                }}
                getOptionLabel={(item) => (item.name ? item.name : "")}
                value={value}
                className=""
                id="combo-box-demo"
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <PriorityIcon icon={option.icon} color={option.color} />
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Priority" />
                )}
              />
            )}
          />

          {/* LINKED JIRA ISSUE */}
          {selectedProject.linked_jira_project && <Controller
            name="linked_issues"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteTag
                value={getKeyFromArray(props.test?.linked_issues)}
                options={props.test?.linked_issues.map((item) => item.key) || []}
                label="Linked Jira Issues"
                onChange={(event, value) => {
                  onChange(value)
                }}
                helpText="Enter the Jira key"
              />
            )}

          />}


          {/* TAGS */}
          <Controller
            name="tags"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteTag
                value={value}
                options={testTags}
                onChange={(event, value) => onChange(value)}
              />
            )}
          />

          <MoreFields
            setValue={setValue}
            formState={formState}
            control={control}
          />
        </div>
      </div>

      {/* <Divider orientation="vertical" flexItem /> */}

      {/* Foot */}
      <div className="flex flex-wrap px-36 py-20 gap-4 z-[999] bg-gray-50  dark:bg-[#111827] ">
        {!props.test?.id && (
          <FormControlLabel
            className="flex-1"
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label="Create a copy"
          />
        )}

        {hasRequiredRole ? (
          <>
            <Button
              disabled={loading}
              className="ml-auto"
              onClick={onCloseDialog}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              color="secondary"
              loading={loading}
              variant="contained"
            >
              Save
            </LoadingButton>
          </>
        ) : (
          <Button
            disabled={loading}
            className="ml-auto"
            onClick={onCloseDialog}
          >
            Close
          </Button>
        )}
      </div>
    </form>
  );
};
