import { createSlice } from '@reduxjs/toolkit';

const stateSlice = createSlice({
    name: 'AIChatPanel/state',
    initialState: false,
    reducers: {
        toggleAIChatPanel: (state, action) => !state,
        openAIChatPanel: (state, action) => true,
        closeAIChatPanel: (state, action) => false,
    },
    extraReducers: {},
});

export const { toggleAIChatPanel, openAIChatPanel, closeAIChatPanel } = stateSlice.actions;

export const selectAIChatPanelState = ({ AssistantApp }) => AssistantApp.state;

export default stateSlice.reducer;
