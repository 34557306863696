import { Paper, Typography } from "@mui/material";
import React from "react";

const StatusCard = ({ label, serie, color, percentage }) => {
  return (
    <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
      <div className="flex items-start justify-between mx-12 mt-12">
        <Typography
          className={`text-lg font-medium text-${color}-800  tracking-tight leading-6 truncate`}
        >
          {label}
        </Typography>
      </div>
      <div className="flex items-end  m-12 gap-20">
        <Typography className="text-5xl font-bold tracking-tighter leading-tight">
          {serie}
        </Typography>
        <Typography
          className={`flex ml-4 text-2xl pb-12  text-${color}-800 leading-none whitespace-nowrap`}
        >
          {`${percentage} %`}
        </Typography>
      </div>
    </Paper>
  );
};

export default StatusCard;
