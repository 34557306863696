import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import ActivateAccountConfig from '../main/activate-account/ActivateAccountConfig';
import ResetPasswordConfig from '../main/reset-password/ResetPasswordConfig';
import ForgotPasswordConfig from '../main/forgot-password/ForgotPasswordConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import Error404Page from '../main/404/Error404Page';
import ExampleConfig from '../main/example/ExampleConfig';
import DashboardConfig from '../main/dashboard/DashboardConfig';
import ProjectsConfig from '../main/projects/ProjectAppConfig';
import TestCaseConfig from '../main/testcase/testcase-config';
import SystemSettingsConfig from '../main/system-settings/SystemSettigsAppConfig';
import TestRunAppConfig from '../main/testrun/TestRunAppConfig';
import DocumentAppConfig from '../main/document/DocumentAppConfig';

const routeConfigs = [
  DashboardConfig,
  ProjectsConfig,
  TestCaseConfig,
  TestRunAppConfig,
  DocumentAppConfig,
  ExampleConfig,
  SignOutConfig,
  SignInConfig,
  SignUpConfig,
  ForgotPasswordConfig,
  ActivateAccountConfig,
  ResetPasswordConfig,
  SystemSettingsConfig
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    element: <Navigate to="/projects" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: '/dashboard',
    element: <Navigate to="/dashboard" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: '/bugs',
    element: <Navigate to="/projects/under-construction" />
  },
  {
    path: '/test-run',
    element: <Navigate to="/projects/under-construction" />
  },
  {
    path: '/system-settings',
    element: <Navigate to="/system-settings" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '*',
    element: <Navigate to="404" />,
  },
];

export default routes;
