import React from "react";

import AssignTests from "./AssignTests";
import BasicInformation from "./BasicInformation";
import SelectTestDialog from "./select-tests/SelectTestDialog";
import { useSelector } from "react-redux";
import { selectAssignTestModalState } from "../store/testRunSlice";
import { useFormContext } from "react-hook-form";

const TestRunContent = () => {
  const methods = useFormContext();

  const {
    formState: { errors },
    control,
    setValue,
    getValues,
  } = methods;

  const openedDialog = useSelector(selectAssignTestModalState);
  return (
    <div className="mx-32 my-24 flex gap-20 ">
      <BasicInformation />
      <AssignTests />

      {openedDialog && <SelectTestDialog />}
    </div>
  );
};

export default TestRunContent;
