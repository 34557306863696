

const formatDate = (timestamp) => {

    const backendDate = timestamp;

    // Create a Date object from the backend date string
    const date = new Date(backendDate);

    // Define options for date and time formatting
    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit' };

    // Format the date and time separately
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

    // Combine date and time in the desired format
    const formattedDateTime = `${formattedDate} ${formattedTime.toLowerCase()}`;

    return formattedDateTime;

}

export const dateFormatter = formatDate;