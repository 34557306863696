import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Alert,
  Autocomplete,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/system";
import PriorityIcon from "../../PriorityIcon";
import {
  selectAutomationStatus,
  selectPriorityTypes,
  selectTestTags,
} from "../../store/testSlice";
import { LoadingButton } from "@mui/lab";
import {
  bulkEditTest,
  closeTestDialog,
  getTestsBySuiteId,
  selectedTestIds,
  updatedSelectedTestIds,
} from "../../store/testsSlice";
import { useState } from "react";
import { selectSuites } from "../../store/suiteSlice";
import { useParams } from "react-router-dom";
import { showMessage } from "app/shared-components/ShowMessage";
import { AutocompleteTag } from "app/shared-components/automation-tags/AutocompleteTag";
import TamboraTreeSelect from "app/shared-components/TamboraTreeSelect";

export default function BulkEditTestForm() {
  const automationStatus = useSelector(selectAutomationStatus);
  const dispatch = useDispatch();
  const priorityTypes = useSelector(selectPriorityTypes);
  const [loading, setLoading] = useState(false);
  const selectedTestsIds = useSelector(selectedTestIds);
  const { suiteId, projectId } = useParams();
  const testTags = useSelector(selectTestTags);
  const suites = useSelector(selectSuites);

  const {
    handleSubmit,
    control,
    formState: { isDirty, dirtyFields },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      test_status: null,
      priority: null,
      tags: [],
      suite: {},
      automation_status: null,
    },
  });

  const saveBulk = (value) => {
    setLoading(true);
    const data = {
      testcase_ids: selectedTestsIds,
      project_id: projectId,
      status_id: value.test_status?.id,
      automation_status_id: value.automation_status?.id,
      priority_id: value.priority?.id,
      suite_id: value.suite.id,
      created_by: value.created_by?.id,
      tags: value.tags,
    };
    dispatch(bulkEditTest(data)).then((res) => {
      let data = {
        pagination: { page: 1, size: 20 },
      };
      data = suiteId == "all" ? { ...data, projectId } : { ...data, suiteId };

      dispatch(getTestsBySuiteId({ ...data }));

      dispatch(
        showMessage({
          message: res.payload.message,
          variant: "success",
        })
      );
      dispatch(updatedSelectedTestIds([]));
      dispatch(closeTestDialog());
      setLoading(false);
    });
  };

  return (
    <form
      noValidate
      className=" h-full"
      name="bulkEditTestForm"
      onSubmit={handleSubmit(saveBulk)}
    >
      <div className="flex flex-col p-36 gap-20 h-full">
        <Typography className="mx-6 mt-5 leading-6" color="text.secondary">
          Alrighty, let's give those test cases a makeover! Choose the fields
          you'd like to freshen up for all selected tests.
        </Typography>
        <Alert severity="info">
          Hey there! With the{" "}
          <span className=" font-bold">{selectedTestsIds.length}</span> test
          cases selected, we'll replace their current data with the new
          information you're about to enter. Just a heads up, this change can't
          be reversed, so make sure you're all set before proceeding!
        </Alert>

        {/* <div className="flex gap-10 w-full"> */}
        {/* SUITES */}
        <Controller
          name="suite"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TamboraTreeSelect
              label="Destination Suite"
              options={suites}
              value={value}
              onChange={(event, value) => onChange(value)}
              renderInput={(params) => <TextField {...params} label="Suite" />}
            ></TamboraTreeSelect>
          )}
        />

        <div className="flex gap-20 w-full">
          {/* AUTOMATION STATUS */}
          <Controller
            name="automation_status"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                size="small"
                options={automationStatus}
                disablePortal
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  onChange(value);
                }}
                getOptionLabel={(item) => (item.name ? item.name : "")}
                value={value}
                className=" mt-10 w-full"
                id="combo-box-demo"
                renderInput={(params) => (
                  <TextField {...params} label="Automation Status" />
                )}
              />
            )}
          />
          {/* <Controller
            name="test_status"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                size="small"
                options={testStatus}
                disablePortal
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  onChange(value);
                }}
                getOptionLabel={(item) => (item.name ? item.name : "")}
                value={value}
                className=" mt-10 w-full"
                id="combo-box-demo"
                renderInput={(params) => (
                  <TextField {...params} label="Test Status" />
                )}
              />
            )}
          /> */}

          {/* PRIORITY TYPE FIELD */}
          <Controller
            name="priority"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                size="small"
                options={priorityTypes}
                disablePortal
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  onChange(value);
                }}
                getOptionLabel={(item) => (item.name ? item.name : "")}
                value={value}
                className=" mt-10 w-full"
                id="combo-box-demo"
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <PriorityIcon icon={option.icon} color={option.color} />
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Priority" />
                )}
              />
            )}
          />
        </div>
        <div className="flex gap-20 w-full">
          {/* TAGS */}
          <Controller
            name="tags"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteTag
                className="w-full mt-10"
                value={value}
                options={testTags}
                onChange={(event, value) => onChange(value)}
              />
            )}
          />
        </div>
      </div>

      {/* Foot */}
      <div className="flex flex-wrap px-36 py-20 gap-4 z-[999] bg-gray-50 dark:bg-[#111827] sticky bottom-0">
        <Button
          disabled={loading}
          className="ml-auto"
          onClick={() => dispatch(closeTestDialog())}
        >
          Cancel
        </Button>
        <LoadingButton
          disabled={!isDirty}
          type="submit"
          color="secondary"
          loading={loading}
          variant="contained"
        >
          Save All
        </LoadingButton>
      </div>
    </form>
  );
}
