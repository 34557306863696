export const buildTreeMap = (treeData) => {
  const suitesMap = {}
  treeData.forEach(suite => {
    suitesMap[suite.id] = suite
  });
  return suitesMap;
}

export const getSuiteParents = (nodeId, suitesMap, data = {}) => {
  if (data[nodeId]) return data[nodeId];

  const currentPath = suitesMap[nodeId];
  if (!currentPath || currentPath.parent === 0) return []

  const parentNode = suitesMap[currentPath.parent].id;
  const parents = [parentNode, ...getSuiteParents(parentNode, suitesMap, data)];

  data[nodeId] = parents;
  return parents;
}