import {
  Card,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  useFormControl,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import EditIcon from "@mui/icons-material/Edit";
import splitterSlice, {
  destroySplitter,
  refreshSplitters,
  selectSplitters,
} from "../../store/splitterSlice";
import ConfirmDialog from "app/shared-components/ConfirmDialog";
import { useDispatch } from "react-redux";
import {
  closeDialog,
  openDialog,
  setDialogLoading,
} from "app/store/fuse/dialogSlice";
import { useFormContext } from "react-hook-form";

const SplitterTable = (props) => {
  const splitters = useSelector(selectSplitters);
  const dispatch = useDispatch();
  const { reset, getValues, watch } = useFormContext();
  const selectedSplitterId = watch("id");
  const deleteComfirmation = (splitterId) => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Are you sure?"
            confirmButton={true}
            confirmButtonLabel="Delete"
            confirmAction={() => deleteSplitter(splitterId)}
            message="Do you really want delete? this process cannot be undone."
            status="info"
          />
        ),
      })
    );
  };

  const deleteSplitter = (splitterId) => {
    dispatch(setDialogLoading(true));
    dispatch(destroySplitter(splitterId)).then((res) => {
      if (res.payload?.status == "success") {
        dispatch(refreshSplitters({ id: splitterId, destroy: true }));
        dispatch(closeDialog());
        dispatch(setDialogLoading(false));
        if (selectedSplitterId == splitterId) {
          reset({ name: "" });
        }
      }
    });
  };

  const editSplitter = (splitterId) => {
    let splitter = splitters.find((item) => item.id == splitterId);
    splitter = {
      ...splitter,
      labels: splitter.labels.join(","),
      icon: splitter.icon.replace("material-solid:", ""),
    };
    reset(splitter);
  };

  return (
    <div className="flex flex-col ">
      <TableContainer sx={{ maxHeight: 700 }} className="grow overflow-x-auto">
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          stickyHeader
          aria-labelledby="tableTitle"
        >
          <TableHead>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>Name</TableCell>
              <TableCell>Options</TableCell>
              <TableCell>Icon</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {splitters.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.name}
                  </TableCell>
                  <TableCell>{row?.labels.join(",")}</TableCell>
                  <TableCell>
                    <Tooltip title={row.icon.replace("material-solid:", "")}>
                      <FuseSvgIcon
                        className="text-48 mr-10"
                        //   size={form.size}
                        color="action"
                      >
                        {row.icon}
                      </FuseSvgIcon>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => editSplitter(row.id)}
                      aria-label="edit"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => deleteComfirmation(row.id)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const SplitterList = (props) => {
  return (
    <Card className="  px-32 min-h-[200px]" component={motion.div}>
      <Typography className="text-2xl pt-20 font-semibold mb-20 leading-tight">
        Splitters
      </Typography>
      {/* <div className="mt-20"> */}
      <SplitterTable />
      {/* </div> */}
    </Card>
  );
};

export default SplitterList;
