import { useState, useEffect } from 'react';
import { selectUser } from 'app/store/userSlice';
import { useSelector } from 'react-redux';
import { isNullishCoalesce } from 'typescript';
// Custom hook to handle role-based access control
function useRoleBasedAccess(requiredRoles, requiredPermissions = []) {
    const [userRole, setUserRole] = useState(null);
    const [userPermissions, setUserPermissions] = useState([]);
    const user = useSelector(selectUser)



    // Simulated API call to fetch user role and permissions
    useEffect(() => {
        // Make an API call to fetch the user's role and permissions
        // Update setUserRole and setUserPermissions with the fetched data
        // For example:
        // const user = fetchUserData(); // Fetch user data from the backend
        // setUserRole(user.role);
        // setUserPermissions(user.permissions);
        setUserRole(user.role)
    }, []);

    // // Check if the user has the required role
    // const hasRequiredRole = userRole === requiredRole;

    const hasRequiredRole = requiredRoles.includes(userRole)

    // Check if the user has all the required permissions
    const hasRequiredPermissions = requiredPermissions.every(permission =>
        userPermissions.includes(permission)
    );

    return { hasRequiredRole, hasRequiredPermissions };
}

export const useSortBy = (props) => {
    const [order, setOrder] = useState(props);
    useEffect(() => {
        order.sortBy = order.id
        if (order.direction == 'desc')
            order.sortBy = `-${order.id}`
        setOrder(order)
    }, []);


    const updateValue = (_order) => {
        if (order.id === _order.id && order.direction === "desc") {
            _order.direction = "asc";
        }
        _order.sortBy = (_order.direction == 'desc') ? `-${_order.id}` : _order.id
        setOrder(_order)
    };

    return [order, updateValue];

}

export const useLocalStorage = (key, initialValue = {}) => {
    const [value, setValue] = useState(() => {
        const storedValue = localStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : initialValue;
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    const setLocalStorage = (item) => {
        localStorage.setItem(key, JSON.stringify(item))
        setValue(JSON.stringify(item))
    }

    return [value, setLocalStorage];
};

export default useRoleBasedAccess;