import TestCaseLayout from './TestCaseLayout';
import { TestCaseContent } from './content/TestCaseContent';
import { SelectSuiteMessage } from './content/SelectSuiteMessage';
import { Navigate, useParams } from 'react-router-dom';
import ImportLayout from './import/ImportLayout';
import TestCaseView from './TestCaseView';

const RedirectToTestCase = () => {
  const { projectId, suiteId } = useParams();
  return <Navigate to={`/tests/project/${projectId}/suites/${suiteId}`} />;
};

const TestCaseConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'tests/project/:projectId/suites',
      children: [
        {
          path: '',
          element: <TestCaseLayout />,
          children: [
            // { path: 'all', element: <TestCaseContent /> },
            { path: ':suiteId', element: <TestCaseContent /> },
          ],
        },
      ],
    },
    { path: 'tests/project/:projectId/import', element: <ImportLayout /> },
    {
      path: 'tests/project/:projectId/suite/:suiteId',
      element: <RedirectToTestCase />,
    },
    {
      path: 'project/:projectKey/suits/:suiteId/tests/:testId',
      element: <TestCaseView />,
    },
  ],
};

export default TestCaseConfig;
