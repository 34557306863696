import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StatusBadge } from "app/shared-components/StatusBadge";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { selectTestCaseTypes } from "./store/testSlice";
import PriorityIcon from "./PriorityIcon";
import TestTags from "./header/test-form/form-preview/TestTags";
import { SplitterSection } from "./header/test-form/form-preview/SplitterSection";
import { getSelectedTest } from "./store/testSlice";
import { useParams } from "react-router-dom";
import { getTestById } from "./store/testSlice";

const StyledTypography = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root .editor p": {
    marginTop: 14,
    marginBotton: 14,
  },
  "&.MuiTypography-root .editor ul": {
    listStyleType: "disc",
    paddingLeft: 25,
  },
  "&.MuiTypography-root .editor ol": {
    listStyleType: "list-decimal",
    paddingLeft: 25,
  },
}));

export const TestView = () => {
  const test = useSelector(getSelectedTest);

  return (
    <div className="flex w-full flex-col gap-10">
      <div className="mb-24">
        <Typography className="mb-10" variant="h6">
          {test?.summary}
        </Typography>
        <div className="flex flex-row gap-4">
          {test?.priority && (
            <PriorityIcon
              title={test.priority?.name}
              icon={test.priority?.icon}
              color={test.priority?.color}
            />
          )}

          <StatusBadge status={test?.automation_status}></StatusBadge>
        </div>
      </div>

      {/* TAGS SESSION */}
      {test?.tags.length > 0 && (
        <div className="mb-24">
          <TestTags tags={test?.tags} />
        </div>
      )}
      <div className="mb-24">
        <Typography className="  font-semibold mb-5 text-15">
          Description
        </Typography>
        <StyledTypography>
          <div
            className="editor max-h-512  overflow-scroll"
            dangerouslySetInnerHTML={{ __html: test?.description }}
          />
        </StyledTypography>
      </div>
      <div className="flex w-full flex-col grow">
        {test?.splitters.length > 0 && (
          <SplitterSection splitters={test?.splitters} />
        )}
      </div>
    </div>
  );
};
