import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { memo, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import CountUp from 'react-countup';
import { selectOpenBugs } from '../../../store/dashboardSlice';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from '@mui/material';

function TestcaseWidget() {

    const { total_open_bugs, link, linked_jira_project } = useSelector(selectOpenBugs);

    return (
        <>
            <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
                <div className="flex items-center justify-between px-8 pt-12">
                    {/* <IconButton className=' relative top-1'>
                        <OpenInNewIcon fontSize="small" />
                    </IconButton> */}
                </div>
                <div className="text-center mt-8 flex-row">

                    <Typography className="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-red-500">
                        <CountUp start={0} end={total_open_bugs} />

                    </Typography>

                    {linked_jira_project ? <Link href={link} target="_blank"  >
                        <Typography className="text-lg pb-20 font-medium hover:text-red-100 text-red-600 dark:text-red-500">
                            Open Bugs {" "}
                            <OpenInNewIcon sx={{ fontSize: 15 }} />
                        </Typography>
                    </Link> : <Typography className="text-lg pb-20 font-medium text-red-600 dark:text-red-500">
                        Open Bugs
                    </Typography>}



                </div>

            </Paper>

        </>


    );
}

export default memo(TestcaseWidget);
