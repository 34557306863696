import {
  Button,
  Card,
  DialogActions,
  Divider,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { motion } from "framer-motion";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { styled } from "@mui/material/styles";
import { Container } from "postcss";
import { Box } from "@mui/system";
import TreeSelect from "mui-tree-select";
import TamboraTreeSelect from "app/shared-components/TamboraTreeSelect";
import { useDispatch, useSelector } from "react-redux";
import { importTests } from "../store/importSlice";
import ImportErrors from "./ImportErrors";
import ImportDialog from "./ImportDialog";
import { closeDialog, openDialog } from "app/store/fuse/dialogSlice";
import { useRef } from "react";
import { Reference } from "react-popper";
import { useParams } from "react-router-dom";
import { selectSuites } from "../store/suiteSlice";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function ImportContent() {
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const suiteId = queryParams.get("suite_id");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSuite, setSelectedSuite] = useState({ id: suiteId });
  const [rowCount, setRowCount] = useState(0);
  const params = useParams();
  const suites = useSelector(selectSuites);
  const dispatch = useDispatch();
  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 },
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();

      reader.onload = (e) => {
        const contents = e.target.result;
        const lines = contents.split("\n");
        setRowCount(lines.length);
      };

      reader.readAsText(file);
    }
  };

  const onSuiteChange = (_, suite) => {
    if (suite == null) return setSelectedSuite(null);
    setSelectedSuite(suite.value);
  };

  const importTestCases = () => {
    dispatch(
      openDialog({
        children: (
          <ImportDialog
            status="inProgress"
            rowCount={rowCount}
            suiteName={selectedSuite.name}
          />
        ),
      })
    );

    dispatch(
      importTests({ suite_id: selectedSuite.id, file: selectedFile })
    ).then((result) => {
      if (result.status == 500) return;
      if (result.payload.status == "error") {
        dispatch(
          openDialog({
            children: (
              <ImportDialog
                status="error"
                errors={result.payload.data}
                rowCount={rowCount}
                suite={selectedSuite.name}
              />
            ),
          })
        );
      } else {
        dispatch(
          openDialog({
            children: (
              <ImportDialog
                status="completed"
                projectId={params.projectId}
                rowCount={rowCount}
                suite={selectedSuite}
              />
            ),
          })
        );
      }
    });
  };

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="flex w-full justify-items-center justify-center items-center mt-32"
    >
      <Card component={motion.div} variants={item} className="w-xl  p-28">
        <Typography className="text-2xl  font-semibold leading-tight">
          Upload File
        </Typography>
        <Typography className="mx-6 mt-5 leading-6" color="text.secondary">
          At TestCase Importer, we make it easy for anyone to import and manage
          test cases. In this section, you can import your test cases using a
          CSV file.
        </Typography>

        <Divider className="my-20"></Divider>
        <Box className="mb-28">
          <Typography className="text-lg font-semibold leading-tight  ">
            1. Download CSV sample
          </Typography>
          <Typography
            className="mx-6 leading-6 truncate ml-20"
            color="text.secondary"
          >
            You can download a sample csv file with instructions.
          </Typography>
          <Link
            role="button"
            color="secondary"
            className="hover:pointer ml-20"
            href="/assets/import_sample.csv"
          >
            Download sample
          </Link>
        </Box>
        <Box className="mb-28">
          <Typography className="  text-lg font-semibold leading-tight">
            2. Select Suite
          </Typography>
          <Typography
            className="mx-6 leading-6 truncate ml-20"
            color="text.secondary"
          >
            Select the Suite where you want to import the test cases.
          </Typography>
          <div className="ml-20 mt-10 w-1/2">
            <TamboraTreeSelect
              options={suites}
              value={suites.find((item) => item.id == suiteId)}
              onChange={(event, value) => onSuiteChange(event, value)}
              renderInput={(params) => (
                <TextField required {...params} label="Selet Suite" />
              )}
            ></TamboraTreeSelect>
          </div>
        </Box>
        <Box className="mb-28">
          <Typography className="  text-lg font-semibold leading-tight">
            3. Fill and Upload the CSV File
          </Typography>
          <Typography
            className="mx-6 leading-6 truncate ml-20"
            color="text.secondary"
          >
            Open the downloaded CSV sample file and start filling in your test.
            case details.
            <Link
              role="button"
              target="_blank"
              color="secondary"
              className="hover:pointer"
              href="https://www.tambora.koombea.io/docs/Introductions/Import%20Test%20cases/Importing#instructions-to-fill-the-csv-file-correctly"
            >
              {" "}
              Learn more about process CSV file.
            </Link>
          </Typography>

          <div className="flex flex-row gap-10 items-center mt-5">
            <Button
              className="ml-20"
              component="label"
              color="secondary"
              size="small"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput onChange={handleFileSelect} type="file" />
            </Button>
            <Typography variant="body1" gutterBottom>
              {selectedFile?.name}
            </Typography>
          </div>
        </Box>
        <Typography className="  text-lg font-semibold leading-tight">
          4. Import the test cases
        </Typography>
        <Button
          className="ml-20 mt-10"
          component="label"
          color="info"
          variant="contained"
          onClick={importTestCases}
          disabled={selectedSuite == null || selectedFile == null}
        >
          Import Test Cases
        </Button>
        {/* <div className="mt-20">
          {importErrors.length > 0 && <ImportErrors />}
        </div> */}
      </Card>
    </motion.div>
  );
}
